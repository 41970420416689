import { ContactsOutlined, Pause } from '@material-ui/icons';
import * as V1Toke from 'actions/v1/token';
import * as V1Fract from 'actions/v1/Fractional';
import { encodedata } from 'hooks/useWallet';
export const ipfsImageHashGet = async (payload, Image, address) => {
  console.log("adasdasdasdasdas create ipfs payload", payload);
  var formData = new FormData();
  if (Image) { formData.append("Image", Image); }
  if (payload.name) { formData.append("name", encodedata(payload.name)); }
  if (payload.description) { formData.append("desc", encodedata(payload.description)); }
  if (address) { formData.append("Creator", encodedata(address)); }
  if (payload.NFTCategory) { formData.append("NFTCategory", encodedata(payload.NFTCategory)); }
  if (payload.royalties) { formData.append("royalties", encodedata(payload.royalties)); }
  if (payload.properties) { formData.append("properties", encodedata(payload.properties)); }
  if (payload.mail) { formData.append('mail', encodedata(payload.mail)) }
  if (payload.ContactNumber) { formData.append('ContactNumber', encodedata(payload.ContactNumber)) }
  if (payload.CharityDetails) { formData.append('CharityDetails', encodedata(JSON.stringify(payload.CharityDetails))) }
  var Resp = await V1Toke.v1_ipfsImageHashGet(formData)
  return Resp
}

export const NFTDetails2DB = async (payload, Image, User, RandomName, NonceHash, SignatureHash) => {
  console.log("nftdb name ,add name", payload)
  var formData = new FormData();
  if (Image) { formData.append('Image', Image); }
  if (payload.IpfsImage) { formData.append('ipfsimage', encodedata(payload.IpfsImage)); }
  if (payload.Ipfs) { formData.append('ipfsmeta', encodedata(payload.Ipfs)); }
  if (payload.metajson) { formData.append('metajson', encodedata(payload.metajson)); }
  if (payload.name) { formData.append('Name', encodedata(payload.name)); }
  if ((payload.TokenID)) { formData.append('TokenID', encodedata(payload.TokenID)); }
  if (payload.description) { formData.append('Description', encodedata(payload.description)); }
  if (payload.TokenPrice) { formData.append('Price', encodedata(payload.TokenPrice)); }
  if (payload.royalties) { formData.append('Royalities', encodedata(payload.royalties)); }
  if (payload.NFTCategory) { formData.append('Category_label', encodedata(payload.NFTCategory)); }
  if (payload.properties) { formData.append('Properties', encodedata(payload.properties)); }
  if (User) { formData.append('Owner', encodedata(User)); }
  if (User) { formData.append('Creator', encodedata(User)) }
  if (payload.quantity) { formData.append('Quantity', encodedata(payload.quantity)) }
  if (payload.quantity) { formData.append('Balance', encodedata(payload.quantity)) }
  if (payload.contractaddress) { formData.append('ContractAddress', encodedata(payload.contractaddress)) }
  if (payload.Status) { formData.append('Status', encodedata(payload.Status)) }
  if (payload.HashValue) { formData.append('HashValue', encodedata(payload.HashValue)) }
  if (payload.type) { formData.append('Type', encodedata(payload.type)) }
  if (payload.MinimumBid) { formData.append('MinimumBid', encodedata(payload.MinimumBid)) }
  if (payload.AuctionEnd) { formData.append('auctionend', encodedata(payload.AuctionEnd)) }
  if (payload.AuctionStart) { formData.append('auctionstart', encodedata(payload.AuctionStart)) }
  if (payload.UnlockContent) { formData.append('UnLockcontent', encodedata(payload.UnlockContent)) }
  if (payload.TokenGating) { formData.append('TokenGating', encodedata(payload.TokenGating)) }
  if (payload.Coinname) { formData.append('coinname', encodedata(payload.Coinname)) }
  if (payload.PutonSale) { formData.append('PutOnSale', encodedata(payload.PutonSale)) }
  if (payload.SaleType) { formData.append('PutOnSaleType', encodedata(payload.SaleType)) }
  if (payload.BuyType) { formData.append('BuyType', encodedata(payload.BuyType)) }
  if (payload.ImageName) { formData.append('image', encodedata(payload.ImageName)) }
  if (payload.additionalImage) { formData.append('additionalImage', encodedata(payload.additionalImage)) }
  if (payload.Date) { formData.append('Date', encodedata(payload.Date)) }
  if (payload.Location) { formData.append('Location', encodedata(payload.Location)) }
  if (payload.Section) { formData.append('Section', encodedata(payload.Section)) }
  if (payload.NetworkName) { formData.append('NetworkName', encodedata(payload.NetworkName)) }
  if (RandomName) { formData.append('RandomName', encodedata(RandomName)) }
  if (NonceHash) { formData.append('NonceHash', encodedata(NonceHash)) }
  if (SignatureHash) { formData.append('SignatureHash', encodedata(SignatureHash)) }

  if (payload.Charity) { formData.append('Charity', encodedata(payload.Charity)) }
  if (payload.CharityDetails) { formData.append('CharityDetails', encodedata(JSON.stringify(payload.CharityDetails))) }
  if (payload.Fiat) { formData.append('Fiat', encodedata(payload.Fiat)) }

  if (payload.Breed) { formData.append('Breed', encodedata(payload.Breed)) }
  if (payload.weight) { formData.append('weight', encodedata(payload.weight)) }
  if (payload.chipno) { formData.append('chipno', encodedata(payload.chipno)) }
  if (payload.height) { formData.append('height', encodedata(payload.height)) }
  if (payload.genders) { formData.append('genders', encodedata(payload.genders)) }
  if (payload.vaccine) { formData.append('vaccine', encodedata(payload.vaccine)) }
  if (payload.mail) { formData.append('mail', encodedata(payload.mail)) }
  if (payload.ContactNumber) { formData.append('ContactNumber', encodedata(payload.ContactNumber)) }
  if (payload.ID) { formData.append('ID', encodedata(payload.ID)) }
  if (payload.DateChoose) { formData.append('DateChoose', encodedata(payload.DateChoose)) }
  if (payload.Terms) { formData.append('Terms', encodedata(payload.Terms)) }
  if (payload.Privacy) { formData.append('Privacy', encodedata(payload.Privacy)) }
  var Resp = await V1Toke.v1_NFTDetails2DB(formData)
  return Resp
}

export const MyItemsData = async (Payload) => {
  var Resp = await V1Toke.v1_MyItemsData(Payload)
  Resp = { Success: Resp.data.Resp.Success, Tab: Resp.data.Tab, records: Resp.data.Resp.records }
  // console.log("hbekbhfrjwkbhkwb",Resp)
  return Resp
}

export const NFTInfo = async (Payload) => {
  // console.log("djgfjdfjdbvjdfhb",Payload);
  if (Payload.locationpath != "collection") {
    // console.log("dkjsdlzzzzzzzkvjsdlk",Payload);
    var Resp = await V1Toke.v1_NFTInfo(Payload)
    var Info = Resp.data
    var NFTInfo = null
    // console.log("NFTInfodetails",Resp.data,Payload)
    if (Info.Success) {
      if (Info.records.length > 0) {
        NFTInfo = Info.records[0]
        NFTInfo.Mybid = (NFTInfo.AllBids.filter((Bids) => Bids.tokenBidAddress === Payload.curAddr))
      }
      else {
        NFTInfo = { NFTOwners: [] }
      }
    }
    return NFTInfo
  }
  else {
    // console.log("sdfjdkjklsjks",Payload);
    var Resp = await V1Toke.v1_NFTInfo1(Payload)
    var Info = Resp.data
    var NFTInfo = null
    // console.log("NFTInfodetails",Resp.data,Payload)
    if (Info.Success) {
      if (Info.records.length > 0) {
        NFTInfo = Info.records[0]
        NFTInfo.Mybid = (NFTInfo.AllBids.filter((Bids) => Bids.tokenBidAddress === Payload.curAddr))
      }
      else {
        NFTInfo = { NFTOwners: [] }
      }
    }
    return NFTInfo
  }
}

export const UpdateNFTOwners = async (Payload) => {
  console.log("jkhkhkjhkjhh", Payload);
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Resale"
    }, Action: Payload.Action, updateData: Payload.updateData, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTDetails: Payload.NFTDetail, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.HashDb
  }

  // console.log("token id  hhhh",Payload)

  // newle added for lazymint 
  // console.log("token id ",Payload.updateData.tokenID)
  if (Payload.updateData.tokenID) {
    // alert("corretc")
    console.log("dnvdskjnvjdkfnvdkf", Payload.updateData.tokenID);
    var Resp = await V1Toke.updateTokenID(Payload)
  }
  else {
    // alert("wrong")
    console.log("not comming here",);
    var Resp = await V1Toke.v1_UpdateNFTOwners(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}

export const UpdateNFTOwnersBulk = async (Payload) => {
  console.log("UpdateNFTOwnersBulk", Payload);
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload?.NFTDetail?.type,
      "burnToken": 0,
      "previousPrice": Payload?.NFTDetail?.tokenPrice,
      "tokenID": Payload?.NFTDetail?.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload?.NFTDetail?.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "GasAmount": Payload.GasAmount,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Create"
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.Hash, Points: Payload.Pointss
  }

  // console.log("token id  hhhh",Payload)

  // newle added for lazymint 
  // console.log("token id ",Payload.updateData.tokenID)
  // if(Payload.updateData.tokenID){
  //   // alert("corretc")
  //   // console.log("dnvdskjnvjdkfnvdkf",Payload.updateData.tokenID);
  //   var Resp = await V1Toke.updateTokenIDBulk(Payload)
  // }
  // else
  if (Payload.updateData.tokenID) {
    // alert("wrong")
    // console.log("not comming here",);
    var Resp = await V1Toke.updateTokenIDBulk(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}

export const UpdateNFTOwnersBulkOwner = async (Payload) => {
  
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload?.NFTDetail?.type,
      "burnToken": 0,
      "previousPrice": Payload?.NFTDetail?.tokenPrice,
      "tokenID": Payload?.NFTDetail?.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload?.NFTDetail?.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "GasAmount": Payload.GasAmount,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Create"
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.Hash
  }

  // console.log("token id  hhhh",Payload)

  // newle added for lazymint 
  // console.log("token id ",Payload.updateData.tokenID)
  // if(Payload.updateData.tokenID){
  //   // alert("corretc")
  //   // console.log("dnvdskjnvjdkfnvdkf",Payload.updateData.tokenID);
  //   var Resp = await V1Toke.updateTokenIDBulk(Payload)
  // }
  // else
  if (Payload.updateData.tokenID) {
    // alert("wrong")
    // console.log("not comming here",);
    var Resp = await V1Toke.updateTokenIDBulkOwner(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}


export const imageImger = async (Payload) => {
  
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "ScannerTokenID": Payload.ScannerTokenID
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens
  }

  
  if (Payload.updateData.tokenID) {
 
    var Resp = await V1Toke.BulkImgGenNext(Payload)

  }


  // console.log("NFTInfodetails",Resp.data)
  return Resp.data
}

export const imageImgerBulkTransfer = async (Payload) => {
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "ScannerTokenID": Payload.ScannerTokenID
    }, Action: Payload.Action, updateData: Payload.updateData, NFTDetailsOwner: Payload.NFTDetail, NFTDetails: Payload.NFTTokens, ExcelFile: Payload.ExcelFile, Balance: Payload.Balance
  }

  if (Payload.updateData.tokenID) {
    console.log("asdasdsadasdds", Payload.updateData.tokenID);
    var Resp = await V1Toke.VerifyAndAdminTransfer(Payload)
  }
  return Resp.data
}

export const BidAction2DB = async (Payload) => {
  var Payload = {
    Payload: {
      "status": "pending",
      "deleted": 1,
      "NoOfToken": Payload.Quantity,
      "completed": 0,
      "cancelled": 0,
      "pending": Payload.Quantity,
      "tokenBidAmt": Payload.BidAmt,
      "tokenBidAddress": Payload.Bidder,
      "tokenID": Payload.tokenID,
      "coinname": Payload.coinName,
      "QR_tokenID": Payload.QR_tokenID
    },
    Act: {
      "tokeName": Payload.tokeName,
      "hash": Payload.Hash,
      "ConAddr": Payload.ConAddr,
      "Coin": Payload.coinName
    }
  }
  var Resp = await V1Toke.v1_BidAction2DB(Payload)
  
  return Resp.data
}

export const EventListNft = async (Payload) => {
  var Resp = await V1Toke.v1_EventListNft(Payload)
  Resp = { Success: Resp.data.Resp.Success, Tab: Resp.data.Tab, records: Resp.data.Resp.records }
  return Resp
}

export const FractionalIPFS = async (payload, Image, address) => {
  var formData = new FormData();
  if (Image) { formData.append("Image", Image); }
  if (payload.name) { formData.append("name", encodedata(payload.name)); }
  if (payload.description) { formData.append("desc", encodedata(payload.description)); }
  if (address) { formData.append("Creator", encodedata(address)); }
  if (payload.NFTCategory) { formData.append("NFTCategory", encodedata(payload.NFTCategory)); }
  if (payload.royalties) { formData.append("royalties", encodedata(payload.royalties)); }
  if (payload.properties) { formData.append("properties", encodedata(payload.properties)); }
  var Resp = await V1Fract.v1_ipfsImageHashGet(formData)
  return Resp
}

export const Fractional2DB = async (payload, Image, User, RandomName, NonceHash, SignatureHash) => {
  var formData = new FormData();
  if (Image) { formData.append('Image', Image); }
  if (payload.IpfsImage) { formData.append('ipfsimage', encodedata(payload.IpfsImage)); }
  if (payload.Ipfs) { formData.append('ipfsmeta', encodedata(payload.Ipfs)); }
  if (payload.metajson) { formData.append('metajson', encodedata(payload.metajson)); }
  if (payload.name) { formData.append('Name', encodedata(payload.name)); }
  if ((payload.TokenID)) { formData.append('TokenID', encodedata(payload.TokenID)); }
  if (payload.description) { formData.append('Description', encodedata(payload.description)); }
  if (payload.TokenPrice) { formData.append('Price', encodedata(payload.TokenPrice)); }
  if (payload.royalties) { formData.append('Royalities', encodedata(payload.royalties)); }
  if (payload.NFTCategory) { formData.append('Category_label', encodedata(payload.NFTCategory)); }
  if (payload.properties) { formData.append('Properties', encodedata(payload.properties)); }
  if (User) { formData.append('Owner', encodedata(User)); }
  if (User) { formData.append('Creator', encodedata(User)) }
  if (payload.quantity) { formData.append('Quantity', encodedata(payload.quantity)) }
  if (payload.quantity) { formData.append('Balance', encodedata(payload.quantity)) }
  if (payload.contractaddress) { formData.append('ContractAddress', encodedata(payload.contractaddress)) }
  if (payload.Status) { formData.append('Status', encodedata(payload.Status)) }
  if (payload.HashValue) { formData.append('HashValue', encodedata(payload.HashValue)) }
  if (payload.type) { formData.append('Type', encodedata(payload.type)) }
  if (payload.MinimumBid) { formData.append('MinimumBid', encodedata(payload.MinimumBid)) }
  if (payload.AuctionEnd) { formData.append('auctionend', encodedata(payload.AuctionEnd)) }
  if (payload.AuctionStart) { formData.append('auctionstart', encodedata(payload.AuctionStart)) }
  if (payload.UnlockContent) { formData.append('UnLockcontent', encodedata(payload.UnlockContent)) }
  if (payload.TokenGating) { formData.append('TokenGating', encodedata(payload.TokenGating)) }
  if (payload.Coinname) { formData.append('coinname', encodedata(payload.Coinname)) }
  if (payload.PutonSale) { formData.append('PutOnSale', encodedata(payload.PutonSale)) }
  if (payload.SaleType) { formData.append('PutOnSaleType', encodedata(payload.SaleType)) }
  if (payload.BuyType) { formData.append('BuyType', encodedata(payload.BuyType)) }
  if (payload.ImageName) { formData.append('image', encodedata(payload.ImageName)) }
  if (payload.additionalImage) { formData.append('additionalImage', encodedata(payload.additionalImage)) }
  if (payload.Date) { formData.append('Date', encodedata(payload.Date)) }
  if (payload.Location) { formData.append('Location', encodedata(payload.Location)) }
  if (payload.Section) { formData.append('Section', encodedata(payload.Section)) }
  if (payload.NetworkName) { formData.append('NetworkName', encodedata(payload.NetworkName)) }
  if (RandomName) { formData.append('RandomName', encodedata(RandomName)) }
  if (NonceHash) { formData.append('NonceHash', encodedata(NonceHash)) }
  if (SignatureHash) { formData.append('SignatureHash', encodedata(SignatureHash)) }
  if (payload.ERC20) { formData.append('ERC20', encodedata(payload.ERC20)) }
  if (payload.ERCTrade) { formData.append('ERCTrade', encodedata(payload.ERCTrade)) }
  if (payload.Fiat) { formData.append('Fiat', encodedata(payload.Fiat)) }
  var Resp = await V1Fract.v1_NFTDetails2DB(formData)
  return Resp
}


export const FractionalNFTInfo = async (Payload) => {
  var Resp = await V1Fract.v1_NFTInfo(Payload)
  var Info = Resp.data
  var NFTInfo = null
  if (Info.Success) {
    if (Info.records.length > 0) {
      NFTInfo = Info.records[0]
      NFTInfo.Mybid = (NFTInfo.AllBids.filter((Bids) => Bids.tokenBidAddress === Payload.curAddr))
    }
    else {
      NFTInfo = { NFTOwners: [] }
    }
  }
  return NFTInfo
}

export const UpdateNFTFractions = async (Payload) => {
  var Payload = {
    Purchase: {
      "tokenPrice": 0,
      "coinname": "",
      "deleted": 1,
      "balance": Number(Payload.Owns),
      "quantity": Number(Payload.Owns),
      "contractAddress": Payload.ConAddr,
      "type": Payload.NFTDetail.type,
      "burnToken": 0,
      "previousPrice": Payload.NFTDetail.tokenPrice,
      "tokenID": Payload.NFTDetail.tokenID,
      "tokenOwner": Payload.NewOwner,
      "tokenCreator": Payload.NFTDetail.tokenCreator,
      "status": Payload.Status,
      "PutOnSale": "false",
      "hashValue": Payload.Hash,
      "LazyStatus": Payload.LazyStatus,
      "SelectedNetwork": Payload.SelectedNetwork,
      "BulkMint": Payload.BulkMint,
      "Bulkmint": Payload.Bulkmint,
      "OldOwner": Payload.OldOwner,
      "tokenName": Payload.tokenName,
      "Collection": Payload.Collection,
      "QR_tokenID": Payload.QR_tokenID,
      "FaitStatus": Payload.Fiat,
      "Charity": Payload.Charity,
      "SaleStatus": "Resale",
      "ERC20_Address": Payload.ERC20_Address,
      "ERCTrade": Payload.ERC20_Address
    }, Action: Payload.Action, updateData: Payload.updateData, ServiceFee: Payload.ServiceFee, FiatBuy: Payload.FiatBuy, NFTDetails: Payload.NFTDetail, NFTPrice: Payload.NFTPrice, Calculation: Payload.Calculation, NFTPriceWithSerice: Payload.NFTPriceWithSerice, Hash: Payload.HashDb
  }


  var Resp = await V1Fract.v1_UpdateNFTOwners(Payload)

  return Resp.data
}
