import React from 'react'
import isEmpty from 'lib/isEmpty'
import config from 'lib/config'
import moment from "moment";
import { NFTnameValidation, CollectionValidate, SolBoundNameValidate, DomainNameValidate, TittleValidate } from 'actions/v1/token'
import $ from 'jquery'
import NumisEmpty from 'lib/NumIsEmpty'
import Web3 from "web3"

export const NFTValidation = async (data, Onsale, Saletype, BuyType) => {
    console.log("NFTValidationss is comming data", data);
    var web3 = new Web3()

    var validationerr = {}
    var DBnamecheck = null
    let dateRegex = /^\d{2}([./-])\d{2}\1\d{4}$/;
    // /^([1][12]|[0]?[1-9])[\/-]([3][01]|[12]\d|[0]?[1-9])[\/-](\d{4}|\d{2})$/;  
    var arr = ['name', 'NFTCategory', 'royalties', 'NetworkName', 'Standard']
    for (const property in data) {
        // console.log("qwererewrewrwerwe", property, data);
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        if (data.NFTCategory == 'Ticket') {

            // console.log("cechk prop dta", data,dateRegex.test(data.Date));
            if (dateRegex.test(data.Date) == false) {
                // console.log("adsadasdsadasdzxczxnvmczn");
                validationerr.Date = 'Please Enter date in this format(DD-MM-YYYY)'
            }
            if (data.Date === '') {
                validationerr.Date = '(DD-MM-YYYY) cannot be Empty'
            }
            if (data.Location === '') {
                validationerr.Location = "Location(Address) cannot be Empty"
            }
            if (data.Section === '') {
                validationerr.Section = "Section cannot be Empty"
            }
            if (data?.TokenGating === '' && data.TokenGat === true) {
                validationerr.TokenGating = "TokenGating cannot be Empty"
            }

        }
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'royalties') {
            if (data[property] <= 0 || data[property] > 20)
                validationerr.royalties = "Royalty Must be between 0 to 20"
            else if (data[property].includes('.'))
                validationerr.royalties = "Royalty Must be a whole Number"
        }
        if (Onsale)
            if ((Saletype === 'Fixed' && property === 'TokenPrice') || (Saletype === 'Auction' && property === 'MinimumBid'))
                if (data[property] < 0.0001 || data[property] >= 1e12)
                    validationerr[property] = `${property} Must be between 0.0001 to 10^12`
        if ((BuyType === 'Fiat' && property === 'TokenPrice'))
            if (data[property] < 1 || data[property] >= 1e12)
                validationerr[property] = `${property} Must be between 2$ to 10^10`
        if (Saletype !== true)
            if (Saletype === 'Fixed' && data.TokenPrice === undefined)
                validationerr.TokenPrice = "Price cannot be Empty"
        if (BuyType === 'Fiat' && data.TokenPrice === undefined)
            validationerr.TokenPrice = "Price cannot be Empty"
        if (Saletype === 'Auction' && data.MinimumBid === undefined)
            validationerr.MinimumBid = "Minimumbid cannot be Empty"
        if (Saletype === 'Auction' && (data.AuctionStart === undefined || data.AuctionEnd === undefined))
            validationerr.Auctiondates = "Start & End date cannot be Empty"
        if (data.Charity) {
            const emptyKeys = data.CharityDetails.reduce((prev, cur) =>
                [...prev, ...Object.keys(cur).filter((key) => !cur[key])], [])
                .filter((v, i, a) => a.indexOf(v) === i)
                .join(', ');

            console.log("EmptyKeysss", emptyKeys);
            if (emptyKeys) {
                if (emptyKeys == "Royality") {
                    validationerr.CharityRoyalty = "Royalty is Reqired For Every user"
                }
                if (emptyKeys == "Address") {
                    validationerr.CharityAddress = "Address is Reqired For Every user"
                }
                if (emptyKeys == "Share") {
                    validationerr.CharityShare = "Share is Reqired"
                }
            }
            var Address = data.CharityDetails.map((item) => { return web3.utils.isAddress(item.Address) })
            if (!Address) {
                validationerr.CharityAddress = "Enter Valid Address"
            }
            console.log("Royality", Royality);
            var Royality = data.CharityDetails.map((item) => { return item.Royality }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0) + Number(data.royalties)
            console.log("Royality", Royality);
            if (Royality) {
                if (Royality <= 0 || Royality > 20)
                    validationerr.CharityRoyalty = "Your Split Royalty Must be 0 to 20 %"
                else if (String(Royality).includes('.'))
                    validationerr.CharityRoyalty = "Royalty Must be a whole Number"
            }
            var IncomeShare = data.CharityDetails.map((item) => { return item.Share }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
            console.log("hcjhxbcjahdash", IncomeShare);
            if (IncomeShare) {
                if (IncomeShare <= 1 || IncomeShare > 80)
                    validationerr.CharityShare = "Your Income Share Must be 1 to 80 %"
                else if (String(IncomeShare).includes('.'))
                    validationerr.CharityShare = "Income Share Percentage Must be a whole Number"
            }
        }
    }
    // console.log("validationcheck", validationerr, Saletype)
    if (isEmpty(validationerr)) {
        DBnamecheck = await NFTnameValidation(data.name)
        // console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}

export const FractionValidation = async (data, Onsale, Saletype, BuyType) => {
    console.log("NFTValidationss is comming data", data);
    var web3 = new Web3()
    var validationerr = {}
    var DBnamecheck = null
    let dateRegex = /^\d{2}([./-])\d{2}\1\d{4}$/;
    var arr = ['name', 'NFTCategory', 'royalties', 'NetworkName', 'Standard']
    for (const property in data) {
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'royalties') {
            if (data[property] <= 0 || data[property] > 20)
                validationerr.royalties = "Royalty Must be between 0 to 20"
            else if (data[property].includes('.'))
                validationerr.royalties = "Royalty Must be a whole Number"
        }
        if (Onsale)
            if ((Saletype === 'Fixed' && property === 'TokenPrice') || (Saletype === 'Auction' && property === 'MinimumBid'))
                if (data[property] < 0.0001 || data[property] >= 1e12)
                    validationerr[property] = `${property} Must be between 0.0001 to 10^12`
        if ((BuyType === 'Fiat' && property === 'TokenPrice'))
            if (data[property] < 1 || data[property] >= 1e12)
                validationerr[property] = `${property} Must be between 2$ to 10^10`
        if (Saletype !== true)
            if (Saletype === 'Fixed' && data.TokenPrice === undefined)
                validationerr.TokenPrice = "Price cannot be Empty"
        if (BuyType === 'Fiat' && data.TokenPrice === undefined)
            validationerr.TokenPrice = "Price cannot be Empty"
        if (Saletype === 'Auction' && data.MinimumBid === undefined)
            validationerr.MinimumBid = "Minimumbid cannot be Empty"
        if (Saletype === 'Auction' && (data.AuctionStart === undefined || data.AuctionEnd === undefined))
            validationerr.Auctiondates = "Start & End date cannot be Empty"
    }
    if (isEmpty(validationerr)) {
        DBnamecheck = await NFTnameValidation(data.name)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}

export const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

export const isDate = (email) => {
    var re = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    return re.test(email);
}


export const isCustomURL = (cusurl) => {
    let nameRegex = /^[:]+(\s{0,1}[a-zA-Z-, ])*$/;
    var istrue = ((config.nameFormat).test(cusurl)) ||
        ((nameRegex).test(cusurl)) ||
        ((cusurl).includes('.')) ||
        ((cusurl).includes(' ')) ||
        ((cusurl).match(/^(?:.*[|,/,\\])/))
    return isEmpty(istrue)
}

export const UserValidation = (Formdata) => {
    var valError = {}
    valError.Email = ((Formdata.Email) ? isEmail(Formdata.Email) : true) ? true : "Enter Valid Email"
    valError.CustomUrl = ((Formdata.CustomUrl) ? isCustomURL(Formdata.CustomUrl) : true) ? true : "CustomUrl Cannot have Smiley, ' . ' , ' / ' or Spaces "
    valError.Name = ((Formdata.Name) ? !((config.nameFormat).test(Formdata.Name)) : true) ? true : "Smiley's are Not allowed"
    if (valError.Email === true && valError.CustomUrl === true && valError.Name === true)
        return true
    else
        return valError
}

export const ImageValidation = (file, Page) => {
    // console.log("FILE AND PAGE VALIDATION IN CREATE AND BULK", file, Page);
    if (Page === 'Edit')
        var validExtensions = ["png", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
    else
        var validExtensions = ["png", "webp", "jpeg", "PNG", "mp4", "jpg", "JPEG", "JPG"];
    var fileName = file.name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1)
        return { Success: false, Message: "Only these file types are accepted : " + validExtensions.join(", ") };
    if (30000000 < file.size)
        return { Success: false, Message: "File size exceeds 30 MB" };
    else
        return { Success: true }
}

export const PopupValidation = (Arg) => {
    console.log("popup validation params", Arg)
    var Resp = null
    if (Arg.NFT && Arg.NFT.auctionstart && new Date(
        Arg.NFT.auctionend
    ).getTime() > Date.now()) {
        if (Arg.TokenPrice < Arg.NFT.minimumBid || Arg.TokenPrice <= 0) {
            Resp = { Success: false, Message: "Bid Amount must be above Minimum Bid" + " " + Arg.NFT.minimumBid }
            return Resp
        }
    }
    if (((!Arg.TokenPrice) || (Arg.TokenPrice < 0.0001) || (Arg.TokenPrice > 1e12)) && !Arg.Fiat)
        Resp = { Success: false, Message: "Enter valid price between 0.0001 to 1e12" }
    if (Arg.type === 1155) {
        if ((!Arg.NoofToken) || (Arg.NoofToken < 1) || (Arg.NoofToken > Arg.MaxToken))
            Resp = { Success: false, Message: "Enter valid Quantity between 1 to available" }
    }
    if (((!Arg.TokenPrice) || (Arg.TokenPrice < 2) || (Arg.TokenPrice >= 1e10)) && Arg.Fiat)
        Resp = { Success: false, Message: "Enter valid price between 2$ to 10^12" }
    if (!Resp)
        Resp = { Success: true }
    return Resp
}



export const BulkValidation = async (data, Onsale, Saletype) => {
    console.log("NFTValidationss is comming data", data);
    var validationerr = {}
    var DBnamecheck = null
    var arr = ['NFTCategory', 'CollectionName', 'NetworkName', "NFTSubCategory"]
    for (const property in data) {
        // console.log("qwererewrewrwerwe", property,data?.File?.length,property === 'File' && data?.File?.length > 3,property  && data?.File?.length );
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        // console.log("sdsdafjsdksdsdmnljflsdkj",property,data.CollectionName);
        if (property === 'CollectionName' && data?.CollectionName?.match(/\ /)) {
            validationerr[property] = `${property} enter without space (you can use underscore)`
        }
        // if (property === 'File' && data?.File?.length < 2 && data.NFTCategory !== 'Ticket') {
        //     validationerr[property] = `${property} folder must contain more than 2 images`
        // }
        // if(property === 'CollectionName' && data.CollectionName == null || data.CollectionName == "") 
        //     validationerr[property] = `${property} is Requried`
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Profile' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Coverimg' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Excel' && data[property] === null)
            validationerr[property] = `${property} is Required`


    }
    // console.log("validationcheck", validationerr, Saletype)
    if (isEmpty(validationerr)) {
        DBnamecheck = await CollectionValidate(data.CollectionName)
        // console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}

export const ImageValidationBulk = (file, Page) => {
    console.log("FILE AND PAGE VALIDATION IN CREATE AND BULK", file, Page);
    if (Page === 'Edit')
        var validExtensions = ["png", "gif", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
    else
        var validExtensions = ["png", "gif", "webp", "jpeg", "PNG", "jpg", "JPEG", "JPG"];
    var fileName = file.name;
    console.log("aweqweqweqweqw", fileName);
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1)
        return { Success: false, Message: "Only these file types are accepted : " + validExtensions.join(", ") };
    if (30000000 < file.size)
        return { Success: false, Message: "File size exceeds 30 MB" };
    else
        return { Success: true }
}

export const ImageValidationExcel = (file, Page) => {
    // console.log("FILE AND PAGE VALIDATION IN CREATE AND BULK", file, Page);
    if (Page === 'Edit')
        var validExtensions = ["xlsx"];
    else
        var validExtensions = ["xlsx"];
    var fileName = file.name;
    var fileNameExt = fileName.substr(fileName.lastIndexOf(".") + 1);
    if ($.inArray(fileNameExt, validExtensions) == -1)
        return { Success: false, Message: "Only these file types are accepted : " + validExtensions.join(", ") };
    if (30000000 < file.size)
        return { Success: false, Message: "File size exceeds 30 MB" };
    else
        return { Success: true }
}

//soul Bound Validation

export const SoulBoundValidation = async (data, Onsale, Saletype) => {
    console.log("NFTValidationss is comming data", data);
    var validationerr = {}
    var DBnamecheck = null
    var arr = ['CollectionName', 'NetworkName', 'Category', 'NFTSubCategory']
    for (const property in data) {
        // console.log("qwererewrewrwerwe", property,data?.File?.length,property === 'File' && data?.File?.length > 3,property  && data?.File?.length );
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
        // console.log("sdsdafjsdksdsdmnljflsdkj",property,data.CollectionName);
        if (property === 'CollectionName' && data?.CollectionName?.match(/\ /)) {
            validationerr[property] = `${property} enter without space (you can use underscore)`
        }
        if (property === 'File' && data?.File?.length < 2) {
            validationerr[property] = `${property} folder must contain more images`
        }
        // if(property === 'File' && data?.File?.length < 200) {
        //     validationerr[property] = `${property} folder must contain 200 images`
        // }
        // if(property === 'CollectionName' && data.CollectionName == null || data.CollectionName == "") 
        //     validationerr[property] = `${property} is Requried`
        if (property === 'File' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Profile' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Coverimg' && data[property] === null)
            validationerr[property] = `${property} is Required`
        if (property === 'Excel' && data[property] === null)
            validationerr[property] = `${property} is Required`


    }
    if (isEmpty(validationerr)) {
        DBnamecheck = await SolBoundNameValidate(data.CollectionName)
        // console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.name = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}

export const EventsValidation = async (data) => {
    console.log("EventsValidation is comming data", data);
    var validationerr = {}
    var DBnamecheck = null
    var arr = ['curraddress', 'domainname']
    var regex1 = /[^a-z]/g;
    var regex2 = /^(?:[A-Za-z]+)(?:[A-Za-z0-9 _]*)$/;
    for (const property in data) {
        console.log("sdsdafjsdksdsdmnljflsdkj", property, data.domainname);
        if (property === 'domainname' && data?.domainname?.match(/\ /)) {
            validationerr[property] = `${property} enter without space`
        }
        if (property === 'domainname' && data?.domainname?.match(regex1)) {
            validationerr[property] = `${property} enter without any special charaters,numbers and capital letters`
        }
        if (property === 'domainname' && data[property] === "")
            validationerr[property] = `${property} is Required`
        if (property === 'template' && data[property] === "")
            validationerr[property] = `${property} is Required`
        if (property === 'country' && data[property] === "")
            validationerr[property] = `${property} is Required`
        if (data.template !== "Fractional/Loyalty Page" && property === 'NoOfTreasure' && data[property] === 0)
            validationerr[property] = `${property} Must be greater than zero`
        if (data.template !== "Fractional/Loyalty Page" && property === 'mysteryNft' && data[property] === 0)
            validationerr[property] = `${property} Must be greater than zero`
        if (property === 'campaignname' && data[property] === "")
            validationerr[property] = `${property} is Required`
    }
    // if (data.CreateStatus == "Create") {
    if (isEmpty(validationerr)) {
        DBnamecheck = await TittleValidate(data.campaignname, data.campaigncode, data.CreateStatus)
        console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.campaignname = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    if (isEmpty(validationerr)) {
        DBnamecheck = await DomainNameValidate(data.domainname, data.campaigncode, data.CreateStatus)
        console.log("jkbjbbfjnejknka", DBnamecheck)
        if (!(DBnamecheck.Success)) {
            validationerr.domainname = DBnamecheck.Message
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
        else {
            validationerr.Success = DBnamecheck.Success
            return validationerr
        }
    }
    else {
        validationerr.Success = false
        return validationerr
    }
    // } else {
    //     if(!isEmpty(validationerr)){
    //         validationerr.Success = false
    //         return validationerr
    //     }else{
    //         validationerr.Success = true
    //         return validationerr 
    //     }

    // }
}

export const VotingValidation = async (data) => {
    console.log("VotingValidation is comming data", data);
    var validationerr = {}
    var arr = ['VotingCat', 'ShortName']
    for (const property in data) {
        if (arr.includes(property) && isEmpty(data[property])) {
            validationerr[property] = `${property} validation failed`
        }
    }
    if (data.VotingCat == "Selling Vote" && (data.SellAmount == 0)) {
        validationerr.SellAmount = "Sell Amount must be greater than zero"
    }
    if (data.CreateStatus == "Create" && data.Description == "") {
        validationerr.Description = "Description Validation Failed"
    }
    if (data.CreateStatus == "Update" && data.VoteMsg == "") {
        validationerr.Description = "Description Validation Failed"
    }
    if (data.promote == 0) {
        validationerr.VotingPercent = "Voting percentage must be greater than zero"
    }

    if (isEmpty(validationerr)) {
        validationerr.Success = true
        return validationerr
    }
    else {
        validationerr.Success = false
        return validationerr
    }
}