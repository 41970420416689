import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  ImageValidation,
  EventsValidation,
} from "actions/controller/validation";
import { LMBalanceOfFraction, LMTokenTrasBack20, ContactMintFraction, LMTokenTransferBack } from 'actions/v1/token';
import { useNFTBalCheckToken } from 'hooks/useMethods';
import { useDeployerCon, useTokenFractionApprove, useTransferReceiptFraction } from "hooks/useReceipts"
import config from "lib/config";
import isEmpty from "lib/isEmpty";
import {
  getAllCounts,
  UserpanelSub,
  EventSave,
  CheckNfts,
  CampaignImageUpload
} from "../actions/v1/token";
import {
  EditProfiledata,
  GetProfiledata,
} from "actions/controller/userControl";
import { DomainNameValidate } from "actions/v1/token";
import { toast, Slide, Zoom, Flip, Bounce } from "react-toastify";
import {
  DataGrid,
  gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { connectWallet } from '../hooks/useWallet';
// import { CKEditor } from "ckeditor4-react";
import { network } from "./network"
import ReactHTMLParser from "react-html-parser";
import Select from "react-select";
import { EventListNft } from "actions/controller/tokenControl";
import polygon_violet_lighttheme from "../assets/images/lazymintingnew/polygon_violet_lighttheme.png";
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import {
  ExistingEvent,
  Eventpublish,
  DeleteEvent,
  GetEventsData,
  CampaignTopBox,
} from "../actions/v1/token";
import Preview from "./Preview";
import moment from "moment";
import Web3 from 'web3';
import dummy from "../assets/images/faces/sublogo.png";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
toast.configure();
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);

  return (
    <Pagination
      color="primary"
      shape="rounded"
      page={page + 1}
      count={pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
    />
  );
}

function customCheckbox(theme) {
  return {
    "& .MuiCheckbox-root svg": {
      width: 16,
      height: 16,
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
        }`,
      borderRadius: 2,
    },
    "& .MuiCheckbox-root svg path": {
      display: "none",
    },
    "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
      backgroundColor: "#e50914",
      borderColor: "#e50914",
    },
    "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
      position: "absolute",
      display: "table",
      border: "2px solid #fff",
      borderTop: 0,
      borderLeft: 0,
      transform: "rotate(45deg) translate(-50%,-50%)",
      opacity: 1,
      transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
      content: '""',
      top: "50%",
      left: "39%",
      width: 5.71428571,
      height: 9.14285714,
    },
    "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
      width: 8,
      height: 8,
      backgroundColor: "#62626214",
      transform: "none",
      top: "39%",
      border: 0,
    },
  };
}

const Events = (props) => {
 // const quill = new Quill('#editor');
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const dashboardRoutes = [];
  const dispatch = useDispatch();
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const history = useHistory();
  const [alltrue, setAlltrue] = useState(false)
  const InitialForm = {
    domainname: "",
    curraddress: Wallet_Details.UserAccountAddr,
    signemail: localStorage.loggedinaddress,
    campaignname: "",
    campaigncode: Date.now(),
    redirect: "",
    template: "",
    categoryTittle: "",
    companylogo: "",
    country: "",
    bannerTittle: "",
    bannerdiscription: "",
    bannerImage: "",
    middlebannertittle: "",
    middlebannerdescription: "",
    middlebannerimage: "",
    middlebannerdetails: "",
    EventDeatils: "",
    backgroundcolor: "#ff0000",
    middlebannerdescriptionTittle: "",
    NoOfNfts: [],
    ChoosenMysterynft: [],
    DupilcateFilter: [],
    Choosennft: [],
    mysteryplace: 0,
    NoOfTreasure: 0,
    mysteryNft: 0,
    Choosennft: [],
    Publish: "false",
    CreateStatus: "Create",
    TopWinnerTittle: "",
    NetworkName: "Wow",
    NameField: "Name",
    NameFieldStatus: false,
    DobField: "Dob",
    DobFieldStatus: false,
    Weight: "Weight",
    WeightStatus: false,
    Height: "Height",
    HeightStatus: false,
    VaccineField: "Vaccine",
    VaccineStatus: false,
    Mail: "Mail",
    MailStatus: false,
    Discription: "Discription",
    DiscriptionStatus: false,
    GenderDropDown: "Gender",
    GenderStatus: false,
    BreadDropDown: "Bread",
    BreadStatus: false,
    Chip: "Chip",
    ChipStatus: false,
    ContactNum: "Contact",
    ContactNumStatus: false,
    FileField: "File",
    FileStatus: false,
    FileDiscriptionField: "File",
    FileDiscriptionStatus: false,
    CheckBox1: "",
    CheckBox1Status: false,
    CheckBox2: "",
    CheckBox2Status: false,
    Dropdown1Category: [],
    Dropdown2Category: [],
    Dropdown3Category: [],
    PDUPoints: ""
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // fileInputRef.current.click();
    // if (fileInputRef.current) {
      fileInputRef?.current?.click();
  // } else {
  //     console.error('File input ref is null');
  // }
  };

  const [Formdata, setFormdata] = useState(InitialForm);

  const [ShowModal, setShowModal] = useState(false);
  const [counts, Setcounts] = useState([]);
  const [SubList, SetSubList] = useState([]);
  const [CompanyLogoName, SetCompanyLogoName] = useState(null);
  const [CompanyLogo, SetCompanyLogo] = useState(null);
  const [CompanyLogoPreview, SetCompanyLogoPreview] = useState();
  const [bannerImageName, SetbannerImageName] = useState(null);
  const [bannerImage, SetbannerImage] = useState(null);
  const [bannerImagePreview, SetbannerImagePreview] = useState();
  const [middlebannerimageName, SetmiddlebannerName] = useState(null);
  const [middlebanner, Setmiddlebanner] = useState(null);
  const [middlebannerPreview, SetmiddlebannerPreview] = useState();
  const [BannerDiscription, SetBannerDiscription] = useState("");
  const [middlebannerdescription, setmiddlebannerdescription] = useState("");
  const [middlebannerdetails, setmiddlebannerdetails] = useState("");
  const [EventDeatils, setEventDeatils] = useState("");
  const [Standard, setselectStandard] = useState("");
  const [ValidationError, setValidationError] = useState(null);
  const [TabName, setTabName] = useState("created");
  const [Loader, setLoader] = useState(false);
  const [Showed, setshowedd] = useState("true");
  const [Array, SetArray] = useState([]);
  const [Array1, SetArray1] = useState([]);
  const [TokenIds, SetTokenIds] = useState();
  const [MyItemNFTs, setMyItemNFTs] = useState({
    created: { page: 1, list: [], onmore: true },
  });
  const [DuplicateNfts, setDuplicateNfts] = useState([{}]);
  const [Indexesss, setIndexess] = useState([]);
  const [Indexesss1, setIndexess1] = useState([]);
  const [Position, setPosition] = useState([]);
  const [JorneyPosition, setJorneyPosition] = useState([]);
  const [NFTCheckedList, setNFTCheckedList] = useState();
  const [Templike, setTemplike] = useState(false);
  const [Nftoption, setNftoptions] = useState("Treasure");
  const [PreviewContentShow, SetPreviewContentShow] = useState("false");
  const [EventDetails, SetEventDetails] = useState([]);
  const [TopBoxData, SetTopBoxData] = useState([]);
  const [ReloadData, SetReloadData] = useState();
  /////sonia
  const [Status1, setStatus1] = useState("init")
  const [nftCount, SetnftCount] = useState();
  const [ShowCustom, SetShowCustom] = useState(false)
  const [networkoption, setnetworkoption] = useState([]);
  const [inputListJourney, setInputListJourney] = useState([
    {
      Journey: "Journey",
      Name: "Journey",
      Description: "Description",
      NofNFT: "0",
      // NftPoint: "0",
      MysteryNFT: "",
      TreasureNft: [],
      MystryNftSelect: [],
      ArrangedNft: [],
      ImageName: "",
      ImageBuffer: {}
    },
  ]);

  const configedit = {
    readonly: false, // All options are documented at https://xdsoft.net/jodit/doc/
    height: 400,
    uploader: {
      insertImageAsBase64URI: true, // Use base64 for image uploads (replace with backend upload URL if needed)
    },
    toolbarSticky: false,
    toolbar: true,
  };


  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => ({
      ...styles,
      color: "#fff",
      background: isFocused
        ? "#000"
        : isSelected
          ? "#000"
          : isHovered
            ? "red"
            : "#000",

      zIndex: 1,
      cursor: "pointer",
      fontSize: "13px",
    }),

    option: (styles, { isFocused, isSelected, isHovered }) => {
      // const color = chroma(data.color);

      return {
        ...styles,
        backgroundColor: isHovered
          ? "#000"
          : isSelected
            ? "#000"
            : isFocused
              ? "#00"
              : "",
        color: isHovered
          ? "#e50914"
          : isSelected
            ? "#fff"
            : isFocused
              ? "#e50914"
              : "",
        cursor: "pointer",
        fontSize: "13px",
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 20px",
      backgroundColor: "#000 ",
      border: "1px solid grey",
      borderRadius: "10px",
      fontSize: "13px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "44px",
      borderRadius: "10px",
      backgroundColor: "#000",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#fff",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      color: "#fff",
    }),
  };

  const [optionsmodal, setoptionsmodal] = useState([
    { value: "Collectible NFTs Template", label: "Collectible NFTs Template" },
    { value: "Design Template", label: "Design Template" },
    { value: "Fractional/Loyalty Page", label: "Fractional/Loyalty Page" },
  ]);

  const rows =
    EventDetails.length > 0 &&
    EventDetails.map((item, index) => {
      console.log("dfasdadasdas", item);
      return {
        id: index + 1,
        Name: item.campaignname,
        domainname: item.domainname + `.lazyminter.com`,
        campaigncode: item.campaigncode,
        Template: item.template,
        Status: item.Publish == "true" ? "Live" : "Draft",
        Action: item,
        Update: item,
        Date: moment(item.Date).format("D MMMM YYYY"),
        Delete: item,
      };
    });

  const columns = [
    { field: "id", headerName: "ID", width: 10 },
    {
      field: "Name",
      headerName: "Name",
      width: 170,
      editable: false,
      sortable: false,
    },
    {
      field: "domainname",
      headerName: "Domain",
      width: 240,
      editable: false,
      sortable: false,
    },
    {
      field: "campaigncode",
      headerName: "Campaign Code",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "Template",
      headerName: "Template",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "Status",
      headerName: "Status",
      width: 60,
      editable: false,
      sortable: false,
    },
    {
      field: "Date",
      headerName: "Created Date",
      width: 150,
      editable: false,
      sortable: false,
    },
    {
      field: "Action",
      headerName: "Action",
      width: 90,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <button
          className="btn table_btn"
          onClick={() => PublishEventTabel(params)}
        >
          {params.row.Action.Publish == "true" ? "Draft" : "publish"}
        </button>
      ),
    },
    {
      field: "Update",
      headerName: "Action",
      width: 90,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <button
          className="btn table_btn"
          onClick={() => UpdateEventTabel(params)}
        >
          {"Update"}
        </button>
      ),
    },

    {
      field: "Delete",
      headerName: "Delete",
      width: 100,
      editable: false,
      sortable: false,
      renderCell: (params) => (
        <button
          className="btn table_btn"
          onClick={() => DeleteEventTabel(params)}
        >
          Delete
        </button>
      ),
    },
  ];

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    border: 0,
    color:
      theme.palette.mode === "light"
        ? "rgba(0,0,0,.85)"
        : "rgba(255,255,255,0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    WebkitFontSmoothing: "auto",
    letterSpacing: "normal",
    "& .MuiDataGrid-columnsContainer": {
      backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
    },
    "& .MuiDataGrid-iconSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
      borderRight: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
    },
    "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
      borderBottom: `1px solid ${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"
        }`,
    },
    "& .MuiDataGrid-cell": {
      color:
        theme.palette.mode === "light"
          ? "rgba(0,0,0,.85)"
          : "rgba(255,255,255,0.65)",
    },
    "& .MuiPaginationItem-root": {
      borderRadius: 0,
    },
    "& .MuiDataGrid-footerContainer": {
      display: "flex",
      justifyContent: "center",
    },

    ...customCheckbox(theme),
  }));

  console.log("adnjisanda", TokenIds,Formdata.NetworkName);

  useEffect(() => {
    getCounts();
    SubcriberList();
    MyItemTab("created");
    console.log("asdasdasd",Formdata.NetworkName);
  }, [Wallet_Details.UserAccountAddr,Formdata.NetworkName]);

  useEffect(()=>{
    if (Wallet_Details && Wallet_Details.networkoption.length > 0) {
      // console.log("skdvnskdnvjksdnvksdn",Wallet_Details.networkoption);
      setnetworkoption(Wallet_Details.networkoption)
    }
  },[Wallet_Details.UserAccountAddr])
  useEffect(() => {
    GetEventDetails();
  }, [Wallet_Details.UserAccountAddr, ReloadData, Wallet_Details.StateChange]);

  async function getCounts() {
    if (Wallet_Details.UserAccountAddr) {
      var data = {
        curradd: Wallet_Details.UserAccountAddr,
      };
      var counts = await getAllCounts(data);

      if (counts) {
        Setcounts(counts.data.data);
      }
      console.log("sdfbgsfdhb", counts.data.data);
    }
  }

  const SubcriberList = async () => {
    var Addresss = {
      Address: Wallet_Details.UserAccountAddr,
    };
    var SubscriberList = await UserpanelSub(Addresss);
    console.log("qweqweqweSubscriberList", SubscriberList);
    if (SubscriberList?.data?.data?.Message == "success") {
      SetSubList(SubscriberList?.data?.data?.userValue);
    }
  };

  const HandleCompanyLogo = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var fileName = file.name;
      SetCompanyLogoName(fileName);
      var Resp = ImageValidation(file, "Edit");
      if (Resp.Success) {
        SetCompanyLogo(file);
        SetCompanyLogoPreview(URL.createObjectURL(file));
      } else {
        toast.error("Error in File Upload Please ReUpload");
      }
    }
  };

  const HandleBannerImage = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var fileName = file.name;
      SetbannerImageName(fileName);
      var Resp = ImageValidation(file, "Edit");
      if (Resp.Success) {
        SetbannerImage(file);
        SetbannerImagePreview(URL.createObjectURL(file));
      } else {
        toast.error("Error in File Upload Please ReUpload");
      }
    }
  };

  const HandleMiddleImage = (e) => {
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      var fileName = file.name;
      SetmiddlebannerName(fileName);
      var Resp = ImageValidation(file, "Edit");
      if (Resp.Success) {
        Setmiddlebanner(file);
        SetmiddlebannerPreview(URL.createObjectURL(file));
      } else {
        toast.error("Error in File Upload Please ReUpload");
      }
    }
  };

  const OnChangehandle = (e) => {
    e.preventDefault();
    console.log("event.tareget.e Eventsss", e.target);
    const { id, value } = e.target;

    let Form = Formdata;
    Form = { ...Form, ...{ [id]: value } };
    console.log("cnsidjvnksdnsdk", id, value);
    setFormdata(Form);
  };

  const EditorBannerDiscription = (e) => {
    var description_text = e;
    SetBannerDiscription(description_text);
  };

  const Editormiddlebannerdescription = (e) => {
    var description_text = e;
    setmiddlebannerdescription(description_text);
  };

  const Editormiddlebannerdetails = (e) => {
    var description_text = e;
    setmiddlebannerdetails(description_text);
  };

  const EditorEventDeatils = (e) => {
    var description_text = e;
    setEventDeatils(description_text);
  };

  const selectStandard = async (e) => {
    setselectStandard(e.value);
    Formdata.template = e.value;
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        TemplateName: e.value,
      },
    });
  };

  const SaveEvent = async () => {
    console.log(
      "SaveEvent",
      middlebannerdescription,
      BannerDiscription,
      middlebannerdetails
    );
    Formdata.bannerImage = bannerImage;
    Formdata.middlebannerimage = middlebanner;
    Formdata.companylogo = CompanyLogo;
    Formdata.bannerdiscription = BannerDiscription;
    Formdata.middlebannerdetails = middlebannerdetails;
    Formdata.EventDeatils = EventDeatils;
    Formdata.middlebannerdescription = middlebannerdescription;
    Formdata.NoOfNfts = Wallet_Details.FinalNfts;
    Formdata.ChoosenMysterynft = Wallet_Details.Mystry;
    Formdata.DupilcateFilter = Wallet_Details.DupilcateFilter;
    Formdata.Choosennft = Wallet_Details.Treasure;
    Formdata.template = Standard;
    Formdata.Publish = "false";
    Formdata.CreateStatus = Wallet_Details.Update;
    console.log(
      "asdsadewq",
      inputList,
      inputList2,
      inputList3,
      Formdata.template,
      inputListJourney
    );
    if (Formdata.template == "Design Template") {
      Formdata.Dropdown1Category = inputList;
      Formdata.Dropdown2Category = inputList2;
      Formdata.Dropdown3Category = inputList3;
    }
    if (Formdata.template == "Fractional/Loyalty Page") {
      Formdata.Dropdown1Category = inputListJourney;
      Formdata.Dropdown2Category = inputListLevels;
    }
    console.log("aweqweqwewqeq", Formdata);
   
    if (Wallet_Details.UserAccountAddr !== "") {
      const id2 = toast.loading("Validating Details", {
        transition: Slide,
        theme: "dark",
      });
      var DomainNameValidate = await EventsValidation(Formdata);
      console.log("DomainNameValidate", DomainNameValidate);
      setTimeout(async () => {
        if (DomainNameValidate.Success) {
          toast.update(id2, {
            render: "Validation Success",
            type: "success",
            isLoading: false,
            autoClose: 2000,
          });
          setValidationError(DomainNameValidate);
          if(Formdata.template !== "Fractional/Loyalty Page"){
            console.log("asdasdasdasdas");
          var SaveInDB = await EventSave(Formdata);
          console.log("SaveInDB", SaveInDB);
          if (SaveInDB.data.Success) {
            toast.success("Created");
            window.$("#addcampaingModal_close").click();
            SetReloadData("Change");
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                StateChange: "two",
              },
            });
            //window.location.reload()
          } else {
            toast.error(SaveInDB.data.Message);
          }
        }else{
          BalCheck()
        }
        } else {
          toast.update(id2, {
            render: "Validation Failed",
            type: "error",
            isLoading: false,
            autoClose: 2500,
          });
          console.log("DomainNameValidate validaterrrrrr", DomainNameValidate);
          setValidationError(DomainNameValidate);
        }
      }, 1000);
    } else {
      toast.warning("please refreash the page and try it again");
    }
  // }else{
  //   BalCheck()
  // }
  };

  const MyItemTab = async (tab, addr, filter, Pageno) => {
    console.log(
      "tab name",
      tab,
      tab && !MyItemNFTs[tab],
      MyItemNFTs[tab],
      MyItemNFTs,
      !MyItemNFTs[tab]
    );

    if (tab && !MyItemNFTs[tab]) {
      console.log("asjdfmaslkd");
      setLoader(true);
      MyItemNFTs[tab] = { page: 1, list: [], onmore: true };
      setMyItemNFTs(MyItemNFTs);
      setDuplicateNfts(MyItemNFTs);
    }
    var curraddress = Wallet_Details.UserAccountAddr;
    var Payload = {
      curraddress: curraddress,
      Tab: tab ? tab : "created",
      Action: "Click",
      Limit: 16,
      Page: Pageno || 1,
      filter: filter,
      Network : Formdata.NetworkName == undefined ? "Wow" : Formdata.NetworkName
    };
    console.log("Event Playload NFT", Payload,Formdata.NetworkName);
    if (tab === "activity" || tab === "following" || tab === "followers") {
    } else {
      console.log("payload to created tab", Payload);
      var myNFT = await EventListNft(Payload);
      console.log("create tab data", myNFT);
      if (myNFT.Success) {
        if (Pageno && Pageno !== MyItemNFTs[myNFT.Tab].page) {
          MyItemNFTs[myNFT.Tab].page = Pageno;
          MyItemNFTs[myNFT.Tab].list = MyItemNFTs[myNFT.Tab].list.concat(
            myNFT.records
          );
          MyItemNFTs[myNFT.Tab].onmore = myNFT.records.length > 0;
          // console.log("wdfwfewfewfwe", MyItemNFTs);
          setMyItemNFTs([]);
          setMyItemNFTs(MyItemNFTs);
          setDuplicateNfts(MyItemNFTs);
        } else {
          if (MyItemNFTs[myNFT.Tab].page === 1) {
            MyItemNFTs[myNFT.Tab].list = myNFT.records;
            // console.log("qweeretrertrtr", MyItemNFTs);
            setMyItemNFTs([]);
            setMyItemNFTs(MyItemNFTs);
            setDuplicateNfts(MyItemNFTs);
            setTimeout(() => {
              setShowModal(false);
            }, 1000);
          }
        }

        // console.log("efwejfweifwi", Payload.Tab);
        setTabName(Payload.Tab);
      } else {
        setTabName(Payload.Tab);
      }
    }
    setTimeout(() => {
      setLoader(false);
    }, 1000);
  };

  const ChooseNftIds = async (Ids, e, Content) => {
    console.log("Ids", e, Ids, DuplicateNfts, Content, Formdata);

    if (e == false) {
      Array.push(Ids);
      // DuplicateNfts[TabName].list[Content]["check"] = true;
      // MyItemNFTs[TabName].list[Content]["check"] = true;
      // let Spread = { ...DuplicateNfts[Content].check, ...Ids.check };
      console.log("sdknfjkskd", Ids._id);
      setMyItemNFTs(MyItemNFTs);
      setDuplicateNfts(DuplicateNfts);
      Indexesss.push(Ids._id);
      Ids["Mystry"] = "Treasure";
      console.log("afvsdvsdvsdvsdfv", Indexesss, Array);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          MyItemNFTs: Indexesss,
          Treasure: Array,
        },
      });

      setTemplike(true);
    } else if (e == true) {
      Array.length > 0 &&
        Array.splice(
          Array.findIndex((a) => a._id === Ids._id),
          1
        );
      // DuplicateNfts[TabName].list[Content]["check"] = false;
      // MyItemNFTs[TabName].list[Content]["check"] = false;
      console.log("ajdasmndasdjasdas", Array);
      setMyItemNFTs(MyItemNFTs);
      setDuplicateNfts(DuplicateNfts);
      setTemplike(false);
      Indexesss.length > 0 &&
        Indexesss.splice(
          Indexesss.findIndex((a) => a === Ids._id),
          1
        );
      console.log("sdfsdfearqewrwe", Indexesss);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          MyItemNFTs: Indexesss,
          Treasure: Array,
        },
      });
    }
    setNFTCheckedList(e);

    console.log("adknfkajsada", Array);
  };

  const ChooseNftIdsMystry = async (Ids, e, Content) => {
    console.log("Ids", e, Ids, DuplicateNfts, Content);

    if (e == false) {
      Array1.push(Ids);
      // DuplicateNfts[TabName].list[Content]["check"] = true;
      // MyItemNFTs[TabName].list[Content]["check"] = true;
      // let Spread = { ...DuplicateNfts[Content].check, ...Ids.check };
      console.log("sdknfjkskd", Ids._id);
      setMyItemNFTs(MyItemNFTs);
      setDuplicateNfts(DuplicateNfts);
      Indexesss1.push(Ids._id);
      Ids["Mystry"] = "Mystry";
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          MyItemNFTsMystry: Indexesss1,
          Mystry: Array1,
        },
      });
    } else if (e == true) {
      Array1.length > 0 &&
        Array1.splice(
          Array1.findIndex((a) => a._id === Ids._id),
          1
        );
      // DuplicateNfts[TabName].list[Content]["check"] = false;
      // MyItemNFTs[TabName].list[Content]["check"] = false;
      console.log("ajdasmndasdjasdas", Array);
      setMyItemNFTs(MyItemNFTs);
      setDuplicateNfts(DuplicateNfts);
      Indexesss1.length > 0 &&
        Indexesss1.splice(
          Indexesss1.findIndex((a) => a === Ids._id),
          1
        );
      console.log("sdfsdfearqewrwe", Indexesss1);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          MyItemNFTsMystry: Indexesss1,
          Mystry: Array1,
        },
      });
    }
    setNFTCheckedList(e);

    console.log("adknfkajsada", Array);
  };

  const MyItemCard = (itemaa) => {
    {
      console.log("sadasdasda", itemaa);
    }
    return (
      <>
        {/* {console.log("wfrwfwrfrfrefrwe", TabName)} */}

        {MyItemNFTs &&
          MyItemNFTs["created"] &&
          MyItemNFTs["created"].list.length > 0 ? (
          <div className="row masonry m-0 ma_no_gap card_main_iner mys_card">
            {MyItemNFTs["created"].list.map((NFTDetails, index) => {
              {
                console.log("jkjjkjkjkjk", NFTDetails);
              }
              return (
                <>
                  {console.log(
                    "NftoptionNftoption",
                    Nftoption,
                    Wallet_Details
                  )}
                  {Wallet_Details.TabsChoose == "Treasure" ? (
                    <>
                      {NFTDetails.tokenCategory !== "Ticket" && (
                        <>
                          {!Wallet_Details?.DupilcateFilter?.some(
                            (it) => it == NFTDetails._id
                          ) && (
                              <div className="item col-xl-3 col-12 col-sm-6 col-md-6 col-lg-3 mb-2 card_whole">
                                <div
                                  className="card_inner_item"
                                  onClick={(e) =>
                                    ChooseNftIds(
                                      NFTDetails,
                                      Wallet_Details?.MyItemNFTs?.some(
                                        (it) => it == NFTDetails._id
                                      ),
                                      index
                                    )
                                  }
                                >
                                  <div className="trendingban">
                                    {NFTDetails.image != "" &&
                                      (NFTDetails.image.split(".").pop() == "mp4" ? (
                                        <video
                                          id="my-video"
                                          class="img-fluid"
                                          autoPlay
                                          playsInline
                                          loop
                                          muted
                                          preload="auto"
                                          controls
                                          disablePictureInPicture
                                          controlsList="nodownload"
                                        >
                                          <source
                                            src={`${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`}
                                            type="video/mp4"
                                          />
                                        </video>
                                      ) : (
                                        <img
                                          src={`${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`}
                                          alt="Collections"
                                          className="img-fluid"
                                        />
                                      ))}
                                  </div>
                                  <div className="img_desc_nft pt-2 cardItem card_price">
                                    <Link to={"/"}>
                                      <img
                                        src={`${config.Back_URL}/Users/${NFTDetails.tokenOwnerInfo.PrimaryAddr}/profileImg/${NFTDetails.tokenOwnerInfo.image}`}
                                        alt="User"
                                        className="img-fluid profiel_img_pos"
                                        title={`Owner : ${NFTDetails.tokenOwnerInfo.name
                                          ? NFTDetails.tokenOwnerInfo.name
                                          : NFTDetails.tokenOwnerInfo.curraddress
                                          }`}
                                      />
                                      {/* <img
                                                                src={require("../../assets/images/star.png")}
                                                                alt="Collections"
                                                                className="img-fluid star_img_blue"
                                                            /> */}
                                    </Link>
                                    <div className="d-block mt-2 card_details">
                                      <h2 className="tokename_title">
                                        {NFTDetails.tokenName}
                                      </h2>
                                    </div>
                                  </div>
                                  <div className="usercontroliconstock">
                                    <div className="price main_sec">
                                      <div className="price mr-auto">
                                        <p>
                                          {NFTDetails.tokenOwnerInfo.curraddress ===
                                            Wallet_Details.UserAccountAddr
                                            ? NFTDetails.tokenowners_current
                                              .balance === 0
                                              ? "You Created This"
                                              : "You Own This"
                                            : new Date(
                                              NFTDetails.auctionend
                                            ).getTime() > Date.now()
                                              ? `${NFTDetails.minimumBid} ${Wallet_Details.networkConfiguration.tokenSymbol}`
                                              : ""}
                                        </p>
                                        {console.log(
                                          "Adsadasdasdasa",
                                          Indexesss,
                                          Wallet_Details?.MyItemNFTs?.some(
                                            (it) => it == NFTDetails._id
                                          )
                                        )}
                                        {/* {Array.length == 0 ? */}
                                        {/* {DuplicateNfts.map((item) => {
                                                                {console.log("asdadasda",item);}
                                                                return ( */}
                                      </div>
                                      <div>
                                        <div className="round_check lightcheck">
                                          <form>
                                            <input
                                              class="form-check-input"
                                              type="checkbox"
                                              checked={Wallet_Details?.MyItemNFTs?.some(
                                                (it) => it == NFTDetails._id
                                              )}
                                            //{NFTDetails?.check}
                                            />
                                          </form>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {console.log(
                        "Sdfdfwfwew",
                        Wallet_Details?.MyItemNFTs?.some(
                          (it) => it == NFTDetails._id
                        )
                      )}
                      {NFTDetails.tokenCategory !== "Ticket" &&
                        Wallet_Details?.MyItemNFTs.length > 0 &&
                        !Wallet_Details?.MyItemNFTs?.some(
                          (it) => it == NFTDetails._id
                        ) && (
                          <>
                            {!Wallet_Details?.DupilcateFilter?.some(
                              (it) => it == NFTDetails._id
                            ) && (

                                <div className="item col-xl-3 col-12 col-sm-6 col-md-6 col-lg-3 mb-2 card_whole">
                                  <div
                                    className="card_inner_item"
                                    onClick={(e) =>
                                      ChooseNftIdsMystry(
                                        NFTDetails,
                                        Wallet_Details?.MyItemNFTsMystry?.some(
                                          (it) => it == NFTDetails._id
                                        ),
                                        index
                                      )
                                    }
                                  >
                                    <div className="trendingban">
                                      {NFTDetails.image != "" &&
                                        (NFTDetails.image.split(".").pop() ==
                                          "mp4" ? (
                                          <video
                                            id="my-video"
                                            class="img-fluid"
                                            autoPlay
                                            playsInline
                                            loop
                                            muted
                                            preload="auto"
                                            controls
                                            disablePictureInPicture
                                            controlsList="nodownload"
                                          >
                                            <source
                                              src={`${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`}
                                              type="video/mp4"
                                            />
                                          </video>
                                        ) : (
                                          <img
                                            src={`${config.Back_URL}/nftImg/${NFTDetails.tokenCreator}/${NFTDetails.image}`}
                                            alt="Collections"
                                            className="img-fluid"
                                          />
                                        ))}
                                    </div>
                                    <div className="img_desc_nft pt-2 cardItem card_price">
                                      <Link to={"/"}>
                                        <img
                                          src={`${config.Back_URL}/Users/${NFTDetails.tokenOwnerInfo.PrimaryAddr}/profileImg/${NFTDetails.tokenOwnerInfo.image}`}
                                          alt="User"
                                          className="img-fluid profiel_img_pos"
                                          title={`Owner : ${NFTDetails.tokenOwnerInfo.name
                                            ? NFTDetails.tokenOwnerInfo.name
                                            : NFTDetails.tokenOwnerInfo.curraddress
                                            }`}
                                        />
                                        {/* <img
                                                                src={require("../../assets/images/star.png")}
                                                                alt="Collections"
                                                                className="img-fluid star_img_blue"
                                                            /> */}
                                      </Link>
                                      <div className="d-block mt-2 card_details">
                                        <h2 className="tokename_title">
                                          {NFTDetails.tokenName}
                                        </h2>
                                      </div>
                                    </div>
                                    <div className="usercontroliconstock">
                                      <div className="price main_sec">
                                        <div className="price mr-auto">
                                          <p>
                                            {NFTDetails.tokenOwnerInfo.curraddress ===
                                              Wallet_Details.UserAccountAddr
                                              ? NFTDetails.tokenowners_current
                                                .balance === 0
                                                ? "You Created This"
                                                : "You Own This"
                                              : new Date(
                                                NFTDetails.auctionend
                                              ).getTime() > Date.now()
                                                ? `${NFTDetails.minimumBid} ${Wallet_Details.networkConfiguration.tokenSymbol}`
                                                : ""}
                                          </p>
                                          {console.log(
                                            "Adsadasdasdasa",
                                            Indexesss,
                                            Wallet_Details?.MyItemNFTs?.some(
                                              (it) => it == NFTDetails._id
                                            )
                                          )}
                                          {/* {Array.length == 0 ? */}
                                          {/* {DuplicateNfts.map((item) => {
                                                                {console.log("asdadasda",item);}
                                                                return ( */}
                                        </div>
                                        <div>
                                          <div className="round_check lightcheck">
                                            <form>
                                              <input
                                                class="form-check-input"
                                                type="checkbox"
                                                checked={Wallet_Details?.MyItemNFTsMystry?.some(
                                                  (it) => it == NFTDetails._id
                                                )}
                                              //{NFTDetails?.check}
                                              />
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              )}
                          </>
                        )}
                    </>
                  )}
                </>
              );
            })}
            {/* <Card /> */}
          </div>
        ) : (
          <BrowseMarketPlace />
        )}
      </>
    );
  };

  const SaveNft = () => {
    console.log("Wallet_Details.count", Wallet_Details);
    //  if(Formdata.template!=="Fractional/Loyalty Page")  {
      // if(localStorage.getItem("RemoveTrea") == "yes"){
      //   Wallet_Details.Treasure = []
      //   localStorage.setItem("RemoveTrea","no")
      // }
    if (Wallet_Details.TabsChoose == "Treasure") {
      if (Wallet_Details.TemplateName !== "Fractional/Loyalty Page") {
        if (Number(Wallet_Details.Treasure.length) == Number(Formdata.NoOfTreasure)) {
          setshowedd("true");
          setNFTCheckedList(Array);
        } else {
          toast.warning(`choose ${Formdata.NoOfTreasure}  treasure NFTs`);
        }
      } else {
        if (inputListJourney?.length > 0) {
          // var Error = ""
          // var Quant = ""
          // for (let i = 0; i < inputListJourney?.length; i++) {
          //   Error = inputListJourney[i].TreasureNft !== inputListJourney[i].NofNFT ? "Error" : ""
          //   Quant = inputListJourney[i].NofNFT
          // }
          // console.log("ErrorErrorError", Error);
          if (Wallet_Details.Treasure.length !== Number(inputListJourney[Wallet_Details?.count]?.NofNFT)) {
            toast.warning(`choose ${inputListJourney[Wallet_Details.count].NofNFT}  treasure NFTs`);
          } else {
            setshowedd("true");
            setNFTCheckedList(Array);
          }
        }
      }
    } else {
      if (Wallet_Details.TemplateName !== "Fractional/Loyalty Page") {
        if (Wallet_Details.Mystry.length == Formdata.mysteryNft) {
          setshowedd("true");
          setNFTCheckedList(Array);
        } else {
          toast.warning(`choose ${Formdata.mysteryNft}  Mystery NFTs`);
        }
      } else {
        if (inputListJourney?.length > 0) {
          // var Error = ""
          // var Quant = ""
          // for (let i = 0; i < inputListJourney?.length; i++) {
          //   Error = inputListJourney[i].MystryNftSelect.length !== inputListJourney[i].MysteryNFT ? "Error" : ""
          //   Quant = inputListJourney[i].MysteryNFT
          // }
          // console.log("ErrorErrorError", Error);
          // if (Error) {
          //   toast.warning(`choose ${Quant}  treasure NFTs`);
          // } else {
          //   setshowedd("true");
          //   setNFTCheckedList(Array);
          //   MystryNftSelect
          // }
          if (Wallet_Details.Mystry.length !== Number(inputListJourney[Wallet_Details.count].MysteryNFT)) {
            toast.warning(`choose ${inputListJourney[Wallet_Details.count].MysteryNFT}  Mystery NFTs`);
          } else {
            setshowedd("true");
            setNFTCheckedList(Array);
          }
        }
      }
    }
    // }
    //     else{
    //         if (Wallet_Details.Treasure.length == Formdata.NoOfTreasure) {
    //             setshowedd("true")
    //             setNFTCheckedList(Array)
    //         }
    //         if (Wallet_Details.Mystry.length == Formdata.mysteryNft) {
    //             setshowedd("true")
    //             setNFTCheckedList(Array)
    //         }
    //     }
  };

  const Ticketss = () => {
    return (
      <>
        {console.log("wfrwfwrfrfrefrwe", TabName, MyItemNFTs)}

        {MyItemNFTs &&
          MyItemNFTs["Ticket"] &&
          MyItemNFTs["Ticket"]?.list?.length > 0 ? (
          <div className="row tickets tick_card">
            {MyItemNFTs["Ticket"]?.list?.map((props, index) => {
              console.log("fhiewhfijwehfijrwe", props);
              return (
                <>
                  {Wallet_Details.TabsChoose == "Treasure" ? (
                    <>
                      {props.tokenCategory == "Ticket" && (
                        <>
                          <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-12">
                            <div
                              className="style_prevu_ki1t yellowheight mb-4"
                              id="ticketscards"
                              onClick={(e) =>
                                ChooseNftIds(
                                  props,
                                  Wallet_Details?.MyItemNFTs?.some(
                                    (it) => it == props._id
                                  ),
                                  index
                                )
                              }
                            >
                              <div className="trendingban trendingbans">
                                {props &&
                                  props.additionalImage &&
                                  (props.additionalImage.split(".").pop() ==
                                    "mp4" ? (
                                    <video
                                      id="my-video"
                                      className="img-fluid"
                                      autoPlay
                                      playsInline
                                      loop
                                      muted
                                      preload="auto"
                                      controls
                                      disablePictureInPicture
                                      controlsList="nodownload"
                                    >
                                      <source
                                        src={
                                          props.item.additionalImage !== ""
                                            ? `${config.Back_URL}/nftImg/${props.tokenCreator}/${props.additionalImage}`
                                            : `${config.IPFS_IMG}/${props.ipfsimage}`
                                        }
                                        type="video/mp4"
                                      />
                                    </video>
                                  ) : (
                                    <img
                                      src={
                                        props.additionalImage !== ""
                                          ? `${config.Back_URL}/nftImg/${props.tokenCreator}/${props.additionalImage}`
                                          : `${config.IPFS_IMG}/${props.ipfsimage}`
                                      }
                                      alt="Collections"
                                      className="img-fluid"
                                    />
                                  ))}
                              </div>
                              <div className="content">
                                <div className="header">
                                  <div className="left-main">
                                    <label className="market-label">
                                      Limited Edition
                                    </label>
                                    <h1 className="titlegenereal">
                                      {props?.tokenName?.slice(0, 10)}
                                    </h1>
                                  </div>
                                  <div className="time-stamp-container">
                                    <div className="left">
                                      <div className="left__date-month-wrapper">
                                        <div className="left__date-month-wrapper-inner">
                                          {/* <label>Nov</label> */}
                                          <h2>
                                            {props.startDateToken}-
                                            {props.endDateToken}
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="right">
                                      <div className="left__date-month-wrapper">
                                        <div className="left__date-month-wrapper-inner">
                                          <label>Section</label>
                                          <h2>{props.Section.slice(0, 3)}</h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="line"></div>
                                <div className="price-container">
                                  <div className="left w-50">
                                    <div className="bottom">
                                      <span className="primary-price">
                                        <span>
                                          {props.tokenowners_current
                                            .tokenPrice == ""
                                            ? ""
                                            : `Price : ${props.tokenowners_current.tokenPrice}`}{" "}
                                          {props.tokenowners_current
                                            .tokenPrice == "" ||
                                            props.tokenowners_current
                                              .tokenPrice == null
                                            ? ""
                                            : props.tokenowners_current
                                              .coinname}
                                        </span>
                                      </span>
                                      <br />
                                      <span className="secondary-price">
                                        {console.log(
                                          "dasdasdasdasdasd11111",
                                          props.tokenowners_current.balance
                                        )}
                                        <span>
                                          In Stock :{" "}
                                          {props.tokenowners_current.balance}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="right w-50">
                                    <div className="top">Location</div>
                                    <div className="bottom loc">
                                      <span className="primary-price">
                                        <span>
                                          {props.Location.slice(0, 80)}
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-button-container">
                                  <div className="icon">
                                    {props.tokenowners_current.coinname ==
                                      "ETH" ? (
                                      <img
                                        src={ETH}
                                        width={35}
                                        height={35}
                                        className="img-fluid"
                                      />
                                    ) : (
                                      <img
                                        src={polygon_violet_lighttheme}
                                        width={35}
                                        height={35}
                                        className="img-fluid"
                                      />
                                    )}
                                  </div>
                                  <div className="round_check lightcheck">
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      checked={Wallet_Details?.MyItemNFTs?.some(
                                        (it) => it == props._id
                                      )}
                                    //{NFTDetails?.check}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {props.tokenCategory == "Ticket" &&
                        Wallet_Details?.MyItemNFTs.length > 0 &&
                        !Wallet_Details?.MyItemNFTs?.some(
                          (it) => it == props._id
                        ) && (
                          <>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12 col-12">
                              <div
                                className="style_prevu_ki1t yellowheight mb-4"
                                id="ticketscards"
                                onClick={(e) =>
                                  ChooseNftIdsMystry(
                                    props,
                                    Wallet_Details?.MyItemNFTsMystry?.some(
                                      (it) => it == props._id
                                    ),
                                    index
                                  )
                                }
                              >
                                <div className="trendingban trendingbans">
                                  {props &&
                                    props.additionalImage &&
                                    (props.additionalImage.split(".").pop() ==
                                      "mp4" ? (
                                      <video
                                        id="my-video"
                                        className="img-fluid"
                                        autoPlay
                                        playsInline
                                        loop
                                        muted
                                        preload="auto"
                                        controls
                                        disablePictureInPicture
                                        controlsList="nodownload"
                                      >
                                        <source
                                          src={
                                            props.item.additionalImage !== ""
                                              ? `${config.Back_URL}/nftImg/${props.tokenCreator}/${props.additionalImage}`
                                              : `${config.IPFS_IMG}/${props.ipfsimage}`
                                          }
                                          type="video/mp4"
                                        />
                                      </video>
                                    ) : (
                                      <img
                                        src={
                                          props.additionalImage !== ""
                                            ? `${config.Back_URL}/nftImg/${props.tokenCreator}/${props.additionalImage}`
                                            : `${config.IPFS_IMG}/${props.ipfsimage}`
                                        }
                                        alt="Collections"
                                        className="img-fluid"
                                      />
                                    ))}
                                </div>
                                <div className="content">
                                  <div className="header">
                                    <div className="left-main">
                                      <label className="market-label">
                                        Limited Edition
                                      </label>
                                      <h1 className="titlegenereal">
                                        {props?.tokenName?.slice(0, 10)}
                                      </h1>
                                    </div>
                                    <div className="time-stamp-container">
                                      <div className="left">
                                        <div className="left__date-month-wrapper">
                                          <div className="left__date-month-wrapper-inner">
                                            {/* <label>Nov</label> */}
                                            <h2>
                                              {props.startDateToken}-
                                              {props.endDateToken}
                                            </h2>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="right">
                                        <div className="left__date-month-wrapper">
                                          <div className="left__date-month-wrapper-inner">
                                            <label>Section</label>
                                            <h2>{props.Section.slice(0, 3)}</h2>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="line"></div>
                                  <div className="price-container">
                                    <div className="left w-50">
                                      <div className="bottom">
                                        <span className="primary-price">
                                          <span>
                                            {props.tokenowners_current
                                              .tokenPrice == ""
                                              ? ""
                                              : `Price : ${props.tokenowners_current.tokenPrice}`}{" "}
                                            {props.tokenowners_current
                                              .tokenPrice == "" ||
                                              props.tokenowners_current
                                                .tokenPrice == null
                                              ? ""
                                              : props.tokenowners_current
                                                .coinname}
                                          </span>
                                        </span>
                                        <br />
                                        <span className="secondary-price">
                                          {console.log(
                                            "dasdasdasdasdasd11111",
                                            props.tokenowners_current.balance
                                          )}
                                          <span>
                                            In Stock :{" "}
                                            {props.tokenowners_current.balance}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                    <div className="right w-50">
                                      <div className="top">Location</div>
                                      <div className="bottom loc">
                                        <span className="primary-price">
                                          <span>
                                            {props.Location.slice(0, 80)}
                                          </span>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-button-container">
                                    <div className="icon">
                                      {props.tokenowners_current.coinname ==
                                        "ETH" ? (
                                        <img
                                          src={ETH}
                                          width={35}
                                          height={35}
                                          className="img-fluid"
                                        />
                                      ) : (
                                        <img
                                          src={polygon_violet_lighttheme}
                                          width={35}
                                          height={35}
                                          className="img-fluid"
                                        />
                                      )}
                                    </div>
                                    <div className="round_check lightcheck">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={Wallet_Details?.MyItemNFTsMystry?.some(
                                          (it) => it == props._id
                                        )}
                                      //{NFTDetails?.check}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    </>
                  )}
                </>
              );
            })}
            {/* <Card /> */}
          </div>
        ) : (
          <BrowseMarketPlace />
        )}
      </>
    );
  };

  //Component
  const BrowseMarketPlace = () => {
    return (
      <div className="text-center py-5">
        <p className="not_found_text">No items found</p>
        <p className="not_found_text_sub">Mint Nfts</p>
      </div>
    );
  };

  const NftValidateAndCheck = (Treasure) => {
    console.log(
      "xczxasdasdsaas",
      Formdata,
      Formdata.template,
      Formdata.NoOfTreasure,
      Formdata.mysteryNft,
      Treasure,
      Wallet_Details,
      Wallet_Details.TemplateName
    );
    if (Wallet_Details.TemplateName) {
      if (Wallet_Details.Update == "Create" || Wallet_Details.Reset == "Create") {
        if (Treasure == "Treasure") {
          console.log("comming in treasure if");
          if (Wallet_Details.TemplateName !== "Fractional/Loyalty Page") {
            if (Formdata.NoOfTreasure == 0 || Formdata.mysteryNft == 0) {
              toast.warning(
                "Treasure and Mystery Quantity Must be greater than 1"
              );
            } else {
              console.log("comming on treasure else");
              setshowedd("false");
              setNftoptions("Treasure");
              dispatch({
                type: Account_Connect,
                Account_Detail: {
                  TabsChoose: "Treasure",
                },
              });
            }
          } else {
            setshowedd("false");
            setNftoptions("Treasure");
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                TabsChoose: "Treasure",
              },
            });
          }
        } else {
          console.log(
            "adccasdadqweqe",
            Wallet_Details.Treasure.length,
            Formdata.NoOfTreasure
          );
          if (Wallet_Details.TemplateName !== "Fractional/Loyalty Page") {
            if (Formdata.NoOfTreasure !== 0 || Formdata.mysteryNft !== 0) {
              setshowedd("false");
              setNftoptions("Mystry");
              dispatch({
                type: Account_Connect,
                Account_Detail: {
                  TabsChoose: "Mystry",
                },
              });
            } else {
              toast.warning(
                "Treasure and Mystery Quantity Must be greater than 1"
              );
            }
          } else {
            setshowedd("false");
            setNftoptions("Mystry");
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                TabsChoose: "Mystry",
              },
            });
          }
        }
      } else {
        toast.warning("reset your Previous selected Nft");
      }
    } else {
      toast.warning("Please Select Template");
    }
  };

  const ChoosePlaces = () => {
    if (Wallet_Details.Update == "Create" || Wallet_Details.Reset == "Create") {
      if (Wallet_Details.TemplateName !== "Fractional/Loyalty Page") {
        if (Formdata.NoOfTreasure !== 0 && Formdata.mysteryNft !== 0) {
          if (Wallet_Details.Mystry.length == Formdata.mysteryNft) {
            console.log(
              "assdqweoioiwwe",
              Wallet_Details.MyItemNFTs,
              Wallet_Details.MyItemNFTsMystry,
              Wallet_Details.Treasure,
              Wallet_Details.Mystry
            );
            var lengthFind = Wallet_Details.Treasure.concat(
              Wallet_Details.Mystry
            );
            console.log("lengthFind", lengthFind);
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                SelectMystry: lengthFind,
                SelectedFinal: "true",
              },
            });
          } else {
            toast.warning(`Choose ${Formdata.mysteryNft} NFTs`);
          }
        } else {
          toast.warning("Treasure and Mystery Quantity Must be greater than 1");
        }
      } else {
        if (Number(inputListJourney[Wallet_Details.count].NofNFT) !== 0 && Number(inputListJourney[Wallet_Details.count].MysteryNFT) !== 0) {
          if (Wallet_Details.Mystry.length == Number(inputListJourney[Wallet_Details.count].MysteryNFT)) {
            console.log(
              "assdqweoioiwwe",
              Wallet_Details.MyItemNFTs,
              Wallet_Details.MyItemNFTsMystry,
              Wallet_Details.Treasure,
              Wallet_Details.Mystry
            );
            var lengthFind = Wallet_Details.Treasure.concat(
              Wallet_Details.Mystry
            );
            console.log("lengthFind", lengthFind);
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                SelectMystry: lengthFind,
                SelectedFinal: "true",
              },
            });
          } else {
            toast.warning(`Choose ${Number(inputListJourney[Wallet_Details.count].MysteryNFT)} NFTs`);
          }
        } else {
          toast.warning("Treasure and Mystery Quantity Must be greater than 1");
        }
      }
    } else {
      toast.warning("reset your Previous selected Nft");
    }
  };

  const IndexPositionsMystry = async (e, Content) => {
    console.log("Positiondsdsdd", e.target.checked, Content);
    if (e.target.checked == true) {
      Position.push(Content);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          SelectedPosition: Position,
        },
      });
    } else if (e.target.checked == false) {
      Position.length > 0 &&
        Position.splice(
          Position.findIndex((a) => a === Content),
          1
        );
      console.log("sdfsdfearqewrwe", Position);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          SelectedPosition: Position,
        },
      });
    }
    console.log("adknfkajsada", Position);
  };

  const SelectedFinal = async () => {
    console.log("asdqweqwqeopi", Wallet_Details.Mystry.length == Wallet_Details.SelectedPosition.length);
    if (
      Wallet_Details.Mystry.length == Wallet_Details.SelectedPosition.length
    ) {
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          SelectedFinal: "false",
        },
      });
      ReOrderingNfts();
    } else {
      toast.warning(`choose ${Wallet_Details.Mystry.length} position`);
    }
  };

  const ReOrderingNfts = async () => {
    console.log(
      "ReOrderingNfts Console",
      Wallet_Details.MyItemNFTs,
      Wallet_Details.MyItemNFTsMystry,
      Wallet_Details.Treasure,
      Wallet_Details.Mystry,
      Wallet_Details.SelectedPosition,
      Wallet_Details.SelectMystry
    );
    var postions = Wallet_Details.Treasure;
    var mapPositions = Wallet_Details.SelectedPosition.map((item, index) => {
      console.log(
        "sdnsdhnfkdsjfhnisd",
        item,
        Wallet_Details.SelectedPosition[item]
      );
      postions.splice(
        Wallet_Details.SelectedPosition[index],
        0,
        Wallet_Details.Mystry[index]
      );
    });
    if (Wallet_Details.TemplateName == "Fractional/Loyalty Page") {
      inputListJourney[Wallet_Details.count].TreasureNft = Wallet_Details.Treasure
      inputListJourney[Wallet_Details.count].MystryNftSelect = Wallet_Details.Mystry
      inputListJourney[Wallet_Details.count].ArrangedNft = postions
    }
    console.log("qwdsaadasdasda", postions);
    var Spread = [...Wallet_Details.MyItemNFTs,
    ...Wallet_Details.MyItemNFTsMystry]
    console.log("dsfdgvdvdfvdfvdf", Spread, Wallet_Details?.DupilcateFilter?.push(Spread));
    var spreadData = [...Wallet_Details?.DupilcateFilter, ...Spread]
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        FinalNfts: postions,
        DupilcateFilter: spreadData
      },
    });

  };

  const PreviewContent = async () => {
    Formdata.bannerImage = bannerImage;
    Formdata.middlebannerimage = middlebanner;
    Formdata.companylogo = CompanyLogo;
    Formdata.bannerdiscription = BannerDiscription;
    Formdata.middlebannerdetails = middlebannerdetails;
    Formdata.EventDeatils = EventDeatils;
    Formdata.middlebannerdescription = middlebannerdescription;
    Formdata.NoOfNfts = Wallet_Details.FinalNfts;
    Formdata.ChoosenMysterynft = Wallet_Details.Mystry;
    Formdata.Choosennft = Wallet_Details.Treasure;
    Formdata.template = Standard;
    if (Formdata.template !== "Collectible NFTs Template") {
      Formdata.Dropdown1Category = inputList;
      Formdata.Dropdown2Category = inputList2;
      Formdata.Dropdown3Category = inputList3;
    }
    if (Formdata.template == "Fractional/Loyalty Page") {
      Formdata.Dropdown1Category = inputListJourney;
      Formdata.Dropdown2Category = inputListLevels;
    }
    localStorage.setItem("StatusShow", "Hide");
    SetPreviewContentShow("true");
    // $("#addcampaingModal").modal("hide");
    window.$("#addcampaingModal_close").click();
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        PreviewContentShow: "true",
      },
    });
  };

  const PublishEvent = async () => {
    console.log(
      "SaveEvent",
      Formdata,
      CompanyLogo,
      bannerImage,
      BannerDiscription,
      middlebanner,
      middlebannerdetails,
      Wallet_Details
    );
    Formdata.bannerImage = bannerImage;
    Formdata.middlebannerimage = middlebanner;
    Formdata.companylogo = CompanyLogo;
    Formdata.bannerdiscription = BannerDiscription;
    Formdata.middlebannerdetails = middlebannerdetails;
    Formdata.EventDeatils = EventDeatils;
    Formdata.middlebannerdescription = middlebannerdescription;
    Formdata.NoOfNfts = Wallet_Details.FinalNfts;
    Formdata.ChoosenMysterynft = Wallet_Details.Mystry;
    Formdata.DupilcateFilter = Wallet_Details.DupilcateFilter;
    Formdata.Choosennft = Wallet_Details.Treasure;
    Formdata.template = Standard;
    Formdata.Publish = "true";
    // if (Formdata.template !== "Collectible NFTs Template") {
    //     Formdata.Dropdown1Category = inputList
    //     Formdata.Dropdown2Category = inputList2
    //     Formdata.Dropdown3Category = inputList3
    // }
    if (Formdata.template == "Design Template") {
      Formdata.Dropdown1Category = inputList;
      Formdata.Dropdown2Category = inputList2;
      Formdata.Dropdown3Category = inputList3;
    }
    if (Formdata.template == "Fractional/Loyalty Page") {
      Formdata.Dropdown1Category = inputListJourney;
      Formdata.Dropdown2Category = inputListLevels;
    }
    console.log("aweqweqwewqeq", Formdata);
    if (Wallet_Details.UserAccountAddr) {
      var sendData = {
        domainname: Formdata.domainname,
      };
      var SaveInDB = await ExistingEvent(sendData);
      console.log("SaveIxasdasdanDB", SaveInDB);
      if (SaveInDB.data.userValue == null) {
        const id2 = toast.loading("Validating Details", {
          transition: Slide,
          theme: "dark",
        });
        var DomainNameValidate = await EventsValidation(Formdata);
        console.log("DomainNameValidate", DomainNameValidate);
        setTimeout(async () => {
          if (DomainNameValidate.Success) {
            toast.update(id2, {
              render: "Validation Success",
              type: "success",
              isLoading: false,
              autoClose: 2000,
            });
            setValidationError(DomainNameValidate);
    if(Formdata.template !== "Fractional/Loyalty Page"){
            var SaveInDBSaved = await EventSave(Formdata);
            console.log("SaveInDB", SaveInDBSaved);
            if (SaveInDBSaved.data.Success) {
              toast.success("Created");
              window.$("#addcampaingModal_close").click();
              SetReloadData("Change");
              dispatch({
                type: Account_Connect,
                Account_Detail: {
                  StateChange: "three",
                },
              });
              // window.location.reload()
            } else {
              toast.error(SaveInDBSaved.data.Message);
            }
          }
          else{
            BalCheck()
          }
          } else {
            toast.update(id2, {
              render: "Validation Failed",
              type: "error",
              isLoading: false,
              autoClose: 2500,
            });
            console.log(
              "DomainNameValidate validaterrrrrr",
              DomainNameValidate
            );
            setValidationError(DomainNameValidate);
          }
        }, 1000);
      } else {
        var sendData = {
          domainname: Formdata.domainname,
        };
        var SaveInDBSaved = await Eventpublish(sendData);
        console.log("SaveInDBSaved", SaveInDBSaved);
        if (SaveInDBSaved) {
          toast.success("Published");
          window.$("#addcampaingModal_close").click();
          SetReloadData("Change");
          //window.location.reload()
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              StateChange: "four",
            },
          });
        } else {
          toast.error("error");
        }
      }
    } else {
      toast.warning("please refreash the page and try it again");
    }
  };

  const PublishEventTabel = async (item) => {
    console.log("PublishEventTabel", item.row.Action);
    if (Wallet_Details.UserAccountAddr) {
      var sendData = {
        domainname: item.row.Action.domainname,
        curraddress: Wallet_Details.UserAccountAddr,
      };
      var SaveInDBSaved = await Eventpublish(sendData);
      console.log("SaveInDBSaved", SaveInDBSaved);
      if (SaveInDBSaved.data.success == true) {
        toast.success(SaveInDBSaved.data.userValue);
        SetReloadData("Change");
        // window.location.reload()
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            StateChange: "five",
          },
        });
      } else {
        toast.error(SaveInDBSaved.data.userValue);
      }
      GetEventDetails()
    }
  };

  const DeleteEventTabel = async (item) => {
    console.log("DeleteEventssDeleteEventss", item);
    if (Wallet_Details.UserAccountAddr) {
      var SendValue = {
        domainname: item.row.Action.domainname,
        curraddress: Wallet_Details.UserAccountAddr,
        campaignname: item.row.Delete.campaignname,
        status: "create",
      };
      console.log("ajnsdifdnkjaqa", SendValue);
      var DeleteEventss = await DeleteEvent(SendValue);
      console.log("DeleteEventss", DeleteEventss);
      if (DeleteEventss.data.success) {
        toast.success("Event Deleted");
        SetReloadData("Change");
        // window.location.reload()
        GetEventDetails()
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            StateChange: "six",
          },
        });
      } else {
        toast.error("Error");
      }
    }
  };

  const GetEventDetails = async () => {
    if (Wallet_Details.UserAccountAddr) {
      var SendData = {
        curraddress: Wallet_Details.UserAccountAddr,
      };
      var Details = await GetEventsData(SendData);
      console.log("SendDataDetailsreturn", Details?.data?.Data);
      if (Details?.data?.Data?.length > 0) {
        SetEventDetails(Details?.data?.Data);
      }
    }
  };

  useEffect(() => {
    TopBox();
  }, [Wallet_Details.UserAccountAddr]);

  const TopBox = async () => {
    var sendData = {
      EventCreator: Wallet_Details.UserAccountAddr,
    };
    var Boxs = await CampaignTopBox(sendData);
    console.log("sdadeweqweq", Boxs);
    if (Boxs?.data?.sucess) {
      SetTopBoxData(Boxs?.data?.Data);
    }
  };

  const UpdateEventTabel = async (item) => {
    console.log("UpdateEventTabel", item.row.Action);
    setFormdata(item.row.Action);
    SetCompanyLogo(item.row.Action.companylogo);
    SetbannerImage(item.row.Action.bannerImage);
    Setmiddlebanner(item.row.Action.middlebannerimage);
    SetBannerDiscription(item.row.Action.bannerdiscription);
    setmiddlebannerdescription(item.row.Action.middlebannerdescription);
    setmiddlebannerdetails(item.row.Action.middlebannerdetails);
    setEventDeatils(item?.row?.Action.EventDeatils);
    setselectStandard(item.row.Action.template);
    setInputListJourney(Object.keys(item?.row?.Action?.Dropdown1Category).length === 0 ? [
      {
        Journey: "Journey",
        Name: "Journey",
        Description: "Description",
        NofNFT: "0",
        // NftPoint: "0",
        MysteryNFT: "",
        TreasureNft: [],
        MystryNftSelect: [],
        ArrangedNft: [],
        ImageName: "",
        ImageBuffer: {}
      },
    ] : item.row.Action.Dropdown1Category); 
    setInputListLevels(Object.keys(item.row.Action.Dropdown2Category).length === 0 ? [
      {
        Points: 0,
        Level: "",
      },
    ] :  item.row.Action.Dropdown2Category)
    setInputList(item.row.Action.Dropdown1Category);
    setInputList2(item.row.Action.Dropdown2Category);
    setInputList3(item.row.Action.Dropdown3Category);
    setoptionsmodal([
      { value: item.row.Action.template, label: item.row.Action.template },
    ]);
    console.log("asdasdasdasd", Formdata);
    window.$("#addcampaingModal").modal("show");
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        Update: "Update",
        Reset: "Update",
        FinalNfts: item.row.Action.NoOfNfts,
        TemplateName: item.row.Action.template,
      },
    });
  };

  const ReSetNft = async () => {
    console.log("Asdasdqeqweqw", Wallet_Details);
    if (Wallet_Details.UserAccountAddr) {
      var SendValue = {
        domainname: Formdata.domainname,
        curraddress: Wallet_Details.UserAccountAddr,
        campaignname: Formdata.campaignname,
        campaigncode: Formdata.campaigncode,
        Template: Wallet_Details.TemplateName,
        status: "update",
      };
      console.log("ajnsdifdnkjaqa", SendValue);
      var DeleteEventss = await DeleteEvent(SendValue);
      console.log("DeleteEventss", DeleteEventss);
      if (DeleteEventss.data.success) {
        toast.success("Event NFTs Reset");
        SetReloadData("Change");
        MyItemTab("created");
        // window.location.reload()
        Formdata.NoOfTreasure = 0;
        Formdata.mysteryNft = 0;
        Formdata.NoOfNfts = [];
        Formdata.ChoosenMysterynft = [];
        Formdata.DupilcateFilter= [];
        Formdata.Choosennft = [];
        Formdata.mysteryplace = 0;
        Formdata.Choosennft= [];
        SetArray([])
        SetArray1([])
        if (Formdata.template == "Fractional/Loyalty Page") {
          Formdata.Dropdown1Category = [];
          Formdata.Dropdown2Category = [];
          setInputListJourney([
            {
              Journey: "Journey",
              Name: "Journey",
              Description: "Description",
              NofNFT: "0",
              // NftPoint: "0",
              MysteryNFT: "0",
              TreasureNft: [],
              MystryNftSelect: [],
              ArrangedNft: [],
              ImageName: "",
              ImageBuffer: {}
            },
          ])
        }
        localStorage.setItem("RemoveTrea","yes")
        SetArray([])
        SetArray1([])
        Wallet_Details.Treasure = []
        // dispatch({
        //   type: Account_Connect,
        //   Account_Detail: {
        //     StateChange: "six",
        //     Reset: "Create",
        //     FinalNfts: [],
        //   },
        // });
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            Treasure:[{}],
            StateChange: "six",
            Reset: "Create",
            FinalNfts: [],
            DupilcateFilter: [],
            DupilcateFilterMystry:[],
            // Update: "Create",
            Reset: "Create",
            SelectedFinal: "true",
            TabsChoose: "Treasure",
            count: 0,
            MyItemNFTs: [],
            MyItemNFTsMystry: [],
            Mystry: [],
            SelectMystry: [],
            SelectedPosition: [],
          },
        });
      } else {
        toast.error("Error");
      }
    }
  };

  const CheckBoxSelect = (e) => {
    const { id, value } = e.target;
    console.log("checkBox", e.target.checked);
    let formData = { ...Formdata, ...{ [id]: e.target.checked } };
    console.log("CheckBox FormData", formData);
    setFormdata(formData);
  };

  const [inputList, setInputList] = useState([{ value: "", label: "" }]);
  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    list[index].label = value;
    //console.log("asndalksdmlas",list);
    setInputList(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    console.log("adsadadqwweqwe", index);
    const list = [...inputList];
    console.log("dakmfklsmflksd", list);
    const remove = list.filter((item, indexFilter) => !(indexFilter === index));
    console.log("zxmcdscsldfsfa", remove);
    // list.splice(index, 1);
    setInputList(remove);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { value: "" }]);
  };

  const [inputList2, setInputList2] = useState([{ value: "" }]);
  // handle input change
  const handleInputChange2 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList2];
    list[index][name] = value;
    list[index].label = value;
    setInputList2(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick2 = (index) => {
    console.log("adsadadqwweqwe", index);
    const list = [...inputList2];
    console.log("dakmfklsmflksd", list);
    const remove = list.filter((item, indexFilter) => !(indexFilter === index));
    console.log("zxmcdscsldfsfa", remove);
    // list.splice(index, 1);
    setInputList2(remove);
  };

  // handle click event of the Add button
  const handleAddClick2 = () => {
    setInputList2([...inputList2, { value: "" }]);
  };

  const [inputList3, setInputList3] = useState([{ value: "" }]);
  // handle input change
  const handleInputChange3 = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList3];
    list[index][name] = value;
    list[index].label = value;
    setInputList3(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick3 = (index) => {
    console.log("adsadadqwweqwe", index);
    const list = [...inputList3];
    console.log("dakmfklsmflksd", list);
    const remove = list.filter((item, indexFilter) => !(indexFilter === index));
    console.log("zxmcdscsldfsfa", remove);
    // list.splice(index, 1);
    setInputList3(remove);
  };

  // handle click event of the Add button
  const handleAddClick3 = () => {
    setInputList3([...inputList3, { value: "" }]);
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };


  // handle input change
  const handleInputChangeJourney = (e, index) => {
    const { name, value } = e.target;
    console.log("inputListJourney", inputListJourney);
    const list = [...inputListJourney];
    console.log("list..........", list);
    list[index][name] = value;
    list[index].label = value;
    console.log("listttt", list);
    setInputListJourney(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickJourney = (index) => {
    console.log("adsadadqwweqwe", index);
    const list = [...inputListJourney];
    console.log("dakmfklsmflksd", list);
    const remove = list.filter((item, indexFilter) => !(indexFilter === index));
    console.log("zxmcdscsldfsfa", remove);
    // list.splice(index, 1);
    setInputListJourney(remove);
  };

  // handle click event of the Add button
  const handleAddClickJourney = (len) => {
    console.log("asdvasdsvadv", inputListJourney);
    var currentTier =
      inputListJourney.length > 0
        ? inputListJourney[inputListJourney.length - 1]
        : 0;
    var { MysteryNFT, NofNFT } = currentTier;
    var { Treasure, Mystry } = Wallet_Details;
    console.log("dvsdfvsdfv", MysteryNFT, NofNFT, Treasure, Mystry);
    if(Wallet_Details.Reset == "Update"){
      return toast.warning("Please Reset Nfts First");
    }
    if (isEmpty(Treasure) || isEmpty(Mystry)) {
      toast.warning("please Choose Treasure and Mystery NFT");
    } else {
      if (
        Number(Mystry.length) === Number(MysteryNFT)
      ) {

        setInputListJourney([
          ...inputListJourney,
          {
            Journey: "",
            Name: "",
            Description: "",
            NofNFT: "",
            // NftPoint: "",
            MysteryNFT: "",
            TreasureNft: [],
            MystryNftSelect: [],
            ArrangedNft: [],
            ImageName: "",
            ImageBuffer: {}
          },
        ]);
        console.log("inputListJourney.length", len);
        SetnftCount(len)
        setNFTCheckedList();
        setDuplicateNfts(MyItemNFTs);
        setTemplike(true);
        SetArray([])
        SetArray1([])
        setPosition([])
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            MyItemNFTs: [],
            MyItemNFTsMystry: [],
            SelectMystry: [],
            SelectedPosition: [],
            SelectedFinal: "true",
            FinalNfts: [],
            DetailEvent: {},
            Treasure: [],
            Mystry: [],
            count: Number(Wallet_Details.count) + 1
          },
        });
      } else {
        toast.warning("Treasure and Mystery Quantity Must be equal");
      }
    }
  };

  const [inputListLevels, setInputListLevels] = useState([
    {
      Points: 0,
      Level: "",
    },
  ]);

  // handle input change
  const handleInputChangeLevels = (e, index) => {
    const { name, value } = e.target;
    console.log("inputListJourney", inputListLevels);
    const list = [...inputListLevels];
    console.log("list..........", list);
    list[index][name] = value;
    list[index].label = value;
    console.log("listttt", list);
    setInputListLevels(list);
  };

  // handle click event of the Remove button
  const handleRemoveClickLevel = (index) => {
    console.log("adsadadqwweqwe", index);
    const list = [...inputListLevels];
    console.log("dakmfklsmflksd", list);
    const remove = list.filter((item, indexFilter) => !(indexFilter === index));
    console.log("zxmcdscsldfsfa", remove);
    // list.splice(index, 1);
    setInputListLevels(remove);
  };

  // handle click event of the Add button
  const handleAddClickLevel = (len) => {
    setInputListLevels([
      ...inputListLevels,
      {
        Points: 0,
        Level: "",
      },
    ]);
  };
  console.log("adasdsdasdasdas", Wallet_Details);

  const JorneyImages = async (e, index) => {
    console.log("eeeeeeeeeeee", e, e.target, e.target.files, e.target && e.target.files);
    if (e.target && e.target.files) {
      var file = e.target.files[0];
      console.log("file......", file);
      var fileName = file.name;
      console.log("fileName......", file);

      var Resp = ImageValidation(file, "Edit");
      console.log("Resp......", Resp);

      if (Resp.Success) {
        const { name, value } = e.target;
        console.log("inputListJourney", inputListJourney);
        const list = [...inputListJourney];
        console.log("list..........", list);
        list[index].ImageName = fileName;
        list[index].ImageBuffer = file;
        console.log("listttt", list);
        setInputListLevels(list);
        var SendData = {
          curraddress: Wallet_Details.UserAccountAddr,
          Dropdown1Category: file,
          Imagename: fileName,
        }
        var UploadImage = await CampaignImageUpload(SendData)
        toast.success("File Upload Successfully");
      } else {
        toast.error("Error in File Upload Please ReUpload");
      }
    }
  };

  const IndexPositionsJorney = async (e, Content) => {
    console.log("Positiondsdsdd", e.target.checked, Content);
    if (e.target.checked == true) {
      JorneyPosition.push(Content);
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          JorneyPosition: JorneyPosition,
          FinalInputVal: JorneyPosition
        },
      });
    } else if (e.target.checked == false) {
      JorneyPosition.length > 0 &&
        JorneyPosition.splice(
          JorneyPosition.findIndex((a) => a === Content),
          1
        );
      dispatch({
        type: Account_Connect,
        Account_Detail: {
          JorneyPosition: JorneyPosition,
          FinalInputVal: JorneyPosition
        },
      });
      console.log("sdfsdfearqewrwe", JorneyPosition);

    }

  };

  const ReOrderingJorney = async () => {
    var postions = inputListJourney;
    var DataPosition = Wallet_Details.JorneyPosition
    if (Wallet_Details?.JorneyPosition?.length == inputListJourney.length) {
      console.log("Wallet_Details.JorneyPosition", Wallet_Details.JorneyPosition);
      var mapPositions = Wallet_Details.JorneyPosition.map((item, index) => {
        let temp = postions[item];
        DataPosition[index] = temp;
      });
      console.log("adasdasdqewe", DataPosition);
      setInputListJourney(DataPosition)
      toast.success("Positions ReOrdered")
      // dispatch({
      //   type: Account_Connect,
      //   Account_Detail: {
      //     FinalInputVal: postions,

      //   },
      // });
    } else {
      return toast.error("Please select all positions")
    }

  };

  const SetShowValidation = async () => {
    console.log("asdvasdsvadv", inputListJourney);
    var currentTier =
      inputListJourney.length > 0
        ? inputListJourney[inputListJourney.length - 1]
        : 0;
    var { MysteryNFT, NofNFT } = currentTier;
    var { Treasure, Mystry } = Wallet_Details;
    console.log("dvsdfvsdfv", MysteryNFT, NofNFT, Treasure, Mystry);
    if (isEmpty(Treasure) || isEmpty(Mystry)) {
      toast.warning("please Choose Treasure and Mystery NFT");
    } else {
      if (Number(Mystry.length) === Number(MysteryNFT)) {
        SetShowCustom(true)
      } else {
        toast.warning("Treasure and Mystery Quantity Must be equal");
      }
    }
  }

  const selectChange = async (e) => {

    // console.log("sdgsdrh", e.value, e.name);
    // console.log("fjfdngnfdgndfkg",put);
    Formdata.NetworkName = e.value == config.Network_ETH ? Number(config.ETH_ID) :  e.value == config.Network_CAM ? Number(config.CAM_ID) : Number(config.MATIC_ID)
    // Formdata.Coinname = e.value == "binance" ? "ETH" : "MATIC"
    if (e.name == "networkname") {
      if (e.name == "networkname" && e.value == config.Network_ETH) {
        console.log("comming on binance");
        var configdata = network.ETH
        Wallet_Details.networkConfiguration = network.ETH
        var accountDetails = await switchNetwork(configdata, Number(config.ETH_ID))
        //setconfig(configdata)
      }
      else if (e.name == "networkname" && e.value == config.Network_MAT) {
        var configdata = network.MATIC
        Wallet_Details.networkConfiguration = network.MATIC
        var accountDetails = await switchNetwork(configdata, Number(config.MATIC_ID))
       // setconfig(configdata)
      }
      else if (e.name == "networkname" && e.value == config.Network_CAM) {
        console.log("e.value == configImp.Network_CAM",e.value , config.Network_CAM);
        var configdata = network.CAM
        Wallet_Details.networkConfiguration = network.CAM
        var accountDetails = await switchNetwork(configdata, Number(config.Network_CAM))
        //setconfig(configdata)
      }

    }
    // else{
    //   toast.warning("please connect your wallet")
    //  }

  }

  async function switchNetwork(configdata, networkNUM) {
    var type = ""
    var networkConfiguration = {}
    const chainId = await Wallet_Details.Web3Pro.eth.getChainId();
    console.log("adsadasdasd", configdata.Chainid, chainId);
    console.log("dfadsaddasdadas", Wallet_Details, localStorage.walletConnectType);
    // if(configdata ==)
    if (localStorage.walletConnectType !== "Torus") {
      if (Number(configdata.Chainid) !== Number(chainId)) {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        if (configdata) {
          if (localStorage.walletConnectType && localStorage.walletConnectType != null && localStorage.walletConnectType == 'MetaMask') {

            type = "MetaMask"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'WalletConnect' && localStorage.walletConnectType != null) {
            type = "WalletConnect"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Venly' && localStorage.walletConnectType != null) {
            type = "Venly"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'Torus' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "Torus"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'TorusWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "TorusWallet"
          }
          else if (localStorage.walletConnectType && localStorage.walletConnectType == 'LMWallet' && localStorage.walletConnectType != null) {
            console.log("adasd comming on torus");
            type = "LMWallet"
            localStorage.setItem("ChainId", networkNUM)
          }
          // console.log("dthstrhrtjhsrt",type,config.Chainid);

          //     var accountDetails = await connectWallet(type,config.Chainid)

          // console.log("accountDetailscreateeeeeeeeeee",accountDetails);

          window.$('#connect_modal').modal('hide');
          console.log("connecttype............", type, configdata.Chainid, networkNUM, localStorage.walletConnectType)

          var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Tor")
          console.log("accountDetailsin create page......................", accountDetails)

          const chainId = await accountDetails?.web3?.eth?.getChainId();
          console.log("fghdtgj", configdata, network, chainId);

          if (configdata.Chainid === network.MATIC.Chainid) {
            networkConfiguration = network.MATIC
          }
          else if (configdata.Chainid === network.ETH.Chainid) {
            networkConfiguration = network.ETH
          }
          else if (configdata.Chainid === network.CAM.Chainid) {
            networkConfiguration = network.CAM
          }
          // console.log("asdasdazxczxsdasdadas",networkConfiguration);

          // }
          //setPriceoption()

          if (accountDetails != '') {
            dispatch({
              type: Account_Connect,
              Account_Detail: {
                UserAccountAddr: accountDetails?.accountAddress,
                UserAccountBal: accountDetails?.coinBalance,
                WalletConnected: "true",

                Wen_Bln: accountDetails?.tokenBalance,
                Accounts: accountDetails?.accountAddress,

                Web3Pro: accountDetails?.web3,
                providerss: accountDetails?.web3?._provider,
                networkConfiguration: networkConfiguration
              }
            })
          }
          // else{
          //   toast.warning("please connect your wallet")
          // }
        }
        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }



        //   var ReqData = {
        //     addr: String(accountDetails.accountAddress).toLowerCase()
        // }
        // var Resp = await AddressUserDetails_GetOrSave_Action(ReqData);
        // if (Resp && Resp.data && Resp.data.data && Resp.data.data.User) {
        //     return Resp.data.data.User
        // } else {
        //     return null
        // }
        return accountDetails

      }
      else {
        dispatch({
          type: Account_Connect,
          Account_Detail: {
            UserAccountAddr: Wallet_Details?.UserAccountAddr,
            UserAccountBal: Wallet_Details?.UserAccountBal,
            WalletConnected: "true",

            Wen_Bln: Wallet_Details?.Wen_Bln,
            Accounts: Wallet_Details?.Accounts,

            Web3Pro: Wallet_Details?.Web3Pro,
            providerss: Wallet_Details?.providerss,
            networkConfiguration: configdata
          }
        })
        return Wallet_Details.Web3Pro
      }
    } else {
      try {
        const id = toast.loading("Switching Network", { closeButton: true, theme: "dark" });
        var accountDetails = await connectWallet(localStorage.walletConnectType, configdata.Chainid, "Create", Wallet_Details, "Torus")
        // if (Number(networkNUM) == 97) {
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x61",
        //     displayName: "binance",
        //     chainNamespace: CHAIN_NAMESPACES.EIP155,
        //     tickerName: "BNB Smart Chain Testnet",
        //     ticker: "BNB",
        //     decimals: 18,
        //     rpcTarget: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        //     blockExplorer: "https://testnet.bscscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x61" });
        //   console.log("switched in create page uh", switched);
        // }else{
        //   console.log("Comming on last else for switchCahin", Wallet_Details.web3auth, Wallet_Details?.web3);
        //   var addingChain = await Wallet_Details.web3auth.addChain({
        //     chainId: "0x13881",
        //     displayName: "Mumbai",
        //     chainNamespace: CHAETHIN_NAMESPACES.EIP155,
        //     tickerName: "Mumbai",
        //     ticker: "MATIC",
        //     decimals: 18,
        //     rpcTarget: "https://matic-mumbai.chainstacklabs.com",
        //     blockExplorer: "https://mumbai.polygonscan.com/",
        //   });
        //   console.log("addingChain Web3Auth", addingChain);
        //   var switched = await Wallet_Details.web3auth.switchChain({ chainId: "0x13881" });
        //   console.log("switched in create page uh", switched);
        // }
        console.log("asdasdasdasdas", accountDetails);
        const chainId = await accountDetails?.web3?.eth?.getChainId();
        console.log("fghdtgj", configdata, network, chainId);

        if (configdata.Chainid === network.MATIC.Chainid) {
          networkConfiguration = network.MATIC
        }
        else if (configdata.Chainid === network.ETH.Chainid) {
          networkConfiguration = network.ETH
        }
        else if (configdata.Chainid === network.CAM.Chainid) {
          networkConfiguration = network.CAM
        }
        if (accountDetails != '') {
          dispatch({
            type: Account_Connect,
            Account_Detail: {
              UserAccountAddr: accountDetails?.accountAddress,
              UserAccountBal: accountDetails?.coinBalance,
              WalletConnected: "true",

              Wen_Bln: accountDetails?.tokenBalance,
              Accounts: accountDetails?.accountAddress,

              Web3Pro: accountDetails?.web3,
              providerss: accountDetails?.web3?._provider,
              networkConfiguration: networkConfiguration
            }
          })
        }
        var functiobn = await NefunctionTriger(Wallet_Details)

        if (networkConfiguration.currencySymbol) {
          toast.update(id, {
            render: "Network switched Succesfully",
            type: "success",
            isLoading: false,
            autoClose: 2500,
            closeButton: true,
            theme: "dark"
          });
        }
      } catch (e) {
        console.log("adqeqweqweqweqweqweqwe", e);
      }
    }



  }

  const NefunctionTriger = async (Wallet_Details1) => {
    console.log("Wallet_Details1", Wallet_Details1);
    const chainIdSwitched = await Wallet_Details1?.Web3Pro?.eth?.getChainId();
    var balance = await Wallet_Details1?.Web3Pro?.eth.getBalance(Wallet_Details1.UserAccountAddr);
    console.log("chainIdSwitched", chainIdSwitched, balance);
    return chainIdSwitched
  }

  const BalCheck = async () => {
    setStatus1("start")
        const id = toast.loading("Checking Balance & Validating", { closeButton: true });
        let chainid = Formdata.NetworkName
        let networkConfiguration = {}
        var web3 = new Web3()
        console.log("sdfsfdfsdfsdfs", Number(chainid), network.MATIC.Chainid);
        if (Number(chainid) === network.MATIC.Chainid) {
            console.log("netter eorrrrkkk maticz", network.MATIC);
            networkConfiguration = network.MATIC
        }
        else if (Number(chainid) === network.ETH.Chainid) {
            console.log("netter eorrrrkkk", network.ETH);
            networkConfiguration = network.ETH
        }
        else if (Number(chainid) === network.CAM.Chainid) {
            console.log("netter eorrrrkkk", network.CAM);
            networkConfiguration = network.CAM
        }
        Wallet_Details.networkConfiguration = networkConfiguration
        let Arg = {
            NFTConAddress: networkConfiguration.NativeToken,
            NFTOwner: Wallet_Details.UserAccountAddr,
            Status: true,
            BulK: "Bulkmint"
        }
        let Type = 721;
        console.log("else iffffff");
        let totalQuantityfina = 0;

        Formdata?.Dropdown1Category?.forEach(subArray => {
    subArray?.ArrangedNft?.forEach(obj => {
      totalQuantityfina += obj.tokenQuantity;
    });
});

console.log("Total Quantity:", totalQuantityfina);

        if (localStorage.walletConnectType !== "LMWallet") {
            var ContractNFTBal = await NFTHookFun(Arg, Wallet_Details)
            console.log("sdqeqwwqeqwqweqw", ContractNFTBal);
        } else {
            var key = null

            var Argument = [Wallet_Details.UserAccountAddr]
            var ContractNFTBal = await LMBalanceCheck(Arg, Type, Wallet_Details, Arg.NFTConAddress, Type == 721 ? "Single" : "Multiple", Arg.NFTConAddress, "balanceOf", Argument, key, Arg.NFTOwner)
        }

        console.log("ContractNFTBal", ContractNFTBal,Formdata)
        
        if (Number(ContractNFTBal) >= totalQuantityfina) {
            toast.update(id, { render: "Validation Success", type: "success", isLoading: false, autoClose: 2500, });
            const id1 = toast.loading("Approve Token", { closeButton: true });
            var Receipt
            let Argment = [Wallet_Details.networkConfiguration.TradeContract, web3.utils.toWei(String(totalQuantityfina))]
            if (localStorage.walletConnectType !== "LMWallet") {
                Receipt = await ContactHookTokenApp(Argment, Wallet_Details, "approve", Wallet_Details.networkConfiguration.NativeToken)
            } else {
                var Key = null
                Receipt = await LMWalletCall(Argment, Wallet_Details, "approve", "Token", Key, Wallet_Details.networkConfiguration.NativeToken)
            }

            if (Receipt.status) {
               toast.update(id1, { render: "Token Approved Successfully", type: "success", isLoading: false, autoClose: 2500, });
                //Newwwwww
                console.log("asdasdasdasdas asdasd",Formdata);
                var SaveInDB = await EventSave(Formdata);
                console.log("SaveInDB", SaveInDB);
                if (SaveInDB) {
                  toast.success("Created");
                  window.$("#addcampaingModal_close").click();
                  SetReloadData("Change");
                  dispatch({
                    type: Account_Connect,
                    Account_Detail: {
                      StateChange: "two",
                    },
                  });
                  //window.location.reload()
                } else {
                  toast.error("error");
                }
                setStatus1("done")
            } else {
                toast.update(id1, { render: "Transcation Failed. Tryit Again Later", type: "error", isLoading: false, autoClose: 2500, });
                setStatus1("tryagain")
            }

        }
        else {
            toast.update(id, { render: "Insufficient Token Balance", type: "error", isLoading: false, autoClose: 2500, });
            setStatus1('tryagain')

        }
}

const NFTHookFun = async (Arg, Wallet_Details) => {
  var ContractNFTBal = await useNFTBalCheckToken(Arg, Wallet_Details)
  return ContractNFTBal
}

const LMBalanceCheck = async (Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key, tokenOwner) => {
  console.log("LMBalanceCheck", Arg, Type, Wallet_Details, IntractContact, NameUse, Contract, method, data, Key);
  var Arg = {
      Method: method,
      Data: data,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Contract,
      Type: String(Type),
      Chain: String(Formdata.NetworkName),
      IntractContract: IntractContact,
      ContactType: NameUse,
      value: 0,
      Key: Key,
      tokenOwner: tokenOwner
  }
  var Balance = await LMBalanceOfFraction(Arg)
  console.log("Balance", Balance);
  return Balance.data.receipt
}


const ContactHookTokenApp = async (Arg, Provider, Method, TokenAddress) => {
  var Receipt = await useTokenFractionApprove(Arg, Provider, Method, TokenAddress)
  return Receipt
}


const LMWalletCall = async (Arg, Wallet_Details, method, NameUse, Key, TokenAddress) => {
  var receipt = null
  console.log("Comming Inside the LmWallet", Arg, Wallet_Details, method, Key);
  var Arg = {
      Method: method,
      Data: Arg,
      Mail: localStorage.loggedinaddress,
      LoginAddress: localStorage.userAddress,
      ContactAddress: Wallet_Details.networkConfiguration.TradeContract,
      Chain: String(Formdata.NetworkName),
      ContactType: NameUse,
      IntractContract: Wallet_Details.networkConfiguration.TradeContract,
      Key: Key,
      Status: false,
      TokenAddress: TokenAddress
  }
  var Receiptfun = await ContactMintFraction(Arg)

  console.log("ArgReceipt", Receiptfun.data.receipt);
  return Receiptfun.data.receipt
}

const ResetclearAll = async () => {
  setFormdata({
    domainname: "",
    curraddress: Wallet_Details.UserAccountAddr,
    signemail: localStorage.loggedinaddress,
    campaignname: "",
    campaigncode: Date.now(),
    redirect: "",
    template: "",
    categoryTittle: "",
    companylogo: "",
    country: "",
    bannerTittle: "",
    bannerdiscription: "",
    bannerImage: "",
    middlebannertittle: "",
    middlebannerdescription: "",
    middlebannerimage: "",
    middlebannerdetails: "",
    EventDeatils: "",
    backgroundcolor: "#ff0000",
    middlebannerdescriptionTittle: "",
    NoOfNfts: [],
    ChoosenMysterynft: [],
    DupilcateFilter: [],
    Choosennft: [],
    mysteryplace: 0,
    NoOfTreasure: 0,
    mysteryNft: 0,
    Choosennft: [],
    Publish: "false",
    CreateStatus: "Create",
    TopWinnerTittle: "",
    NameField: "Name",
    NameFieldStatus: false,
    DobField: "Dob",
    DobFieldStatus: false,
    Weight: "Weight",
    WeightStatus: false,
    Height: "Height",
    HeightStatus: false,
    VaccineField: "Vaccine",
    VaccineStatus: false,
    Mail: "Mail",
    MailStatus: false,
    Discription: "Discription",
    DiscriptionStatus: false,
    GenderDropDown: "Gender",
    GenderStatus: false,
    BreadDropDown: "Bread",
    BreadStatus: false,
    Chip: "Chip",
    ChipStatus: false,
    ContactNum: "Contact",
    ContactNumStatus: false,
    FileField: "File",
    FileStatus: false,
    FileDiscriptionField: "",
    FileDiscriptionStatus: false,
    CheckBox1: "",
    CheckBox1Status: false,
    CheckBox2: "",
    CheckBox2Status: false,
    Dropdown1Category: [],
    NetworkName:"Wow",
    Dropdown2Category: [],
    Dropdown3Category: [],
    PDUPoints: ""
  })
  setInputListLevels([
    {
      Points: 0,
      Level: "",
    },
  ])
  setInputList3([{ value: "" }])
  setInputList([{ value: "", label: "" }])
  setInputList2([{ value: "" }])
  SetArray([])
  SetArray1([])
  setInputListJourney([
    {
      Journey: "Journey",
      Name: "Journey",
      Description: "Description",
      NofNFT: "0",
      // NftPoint: "0",
      MysteryNFT: "",
      TreasureNft: [],
      MystryNftSelect: [],
      ArrangedNft: [],
      ImageName: "",
      ImageBuffer: {}
    },
  ])
  dispatch({
    type: Account_Connect,
    Account_Detail: {
      FinalNfts: [],
      DupilcateFilter: [],
      DupilcateFilterMystry:[],
      DetailEvent: {},
      Update: "Create",
      Reset: "Create",
      SelectedFinal: "true",
      TemplateName: "Collectible NFTs Template",
      PreviewContentShow: "false",
      TabsChoose: "Treasure",
      count: 0,
      StateChange: "one",
      MyItemNFTs: [],
      MyItemNFTsMystry: [],
      Treasure: [],
      Mystry: [],
      SelectMystry: [],
      SelectedPosition: [],
    },
  });
  setoptionsmodal([
    { value: "Collectible NFTs Template", label: "Collectible NFTs Template" },
    { value: "Design Template", label: "Design Template" },
    { value: "Fractional/Loyalty Page", label: "Fractional/Loyalty Page" },
  ])
}

const editorRef = useRef(null);

useEffect(() => {
  if (editorRef.current) {
    // Log to confirm the editor is ready
    console.log("Editor instance: ", editorRef.current);
  }
}, [editorRef]);

const modules = {
  toolbar: {
    container: [
      [{ 'font': [] }, { 'size': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'align': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      [{ 'blockquote': true }, { 'code-block': true }],
      ['link'],
      ['clean'],
    ],
    // handlers: {
    //   image: imageHandler, // Custom image upload handler
    // },
  },
};

  return (
    <>
      {Wallet_Details.PreviewContentShow == "false" ? (
        <>
          {console.log("Adjasdcjkdsaca", Wallet_Details)}
          <div className="scrolling_move_sec_align">
            <div className="topcardsecs">
              <div>
                <div className="card blackcard_style mb-2">
                  <div className="firstrow">
                    <div>
                      <i class="fa fa-circle admint_nrm_round admin_green_round mb-2" />
                    </div>
                    <div className="div_text">Total Campaign</div>
                  </div>
                  {console.log("dfsfadfafadf", TopBoxData)}
                  <div className="graph_align">
                    <div className="count_dtls">
                      <span className="count_txt">
                        {TopBoxData?.Created ? TopBoxData?.Created : 0}
                      </span>
                      <br />

                      {/* <small className='green_text'>+10.3% </small>
                     <img src={require('../assets/images/faces/small_green_arrow.png')} /> */}
                    </div>
                    {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/green_graph.png')} /> */}
                  </div>
                </div>
              </div>
              <div>
                <div className="card blackcard_style mb-2">
                  <div className="firstrow">
                    <div>
                      <i class="fa fa-circle admint_nrm_round admin_red_round mb-2"></i>
                    </div>
                    <div className="div_text">Total Participants</div>
                  </div>

                  <div className="graph_align">
                    <div className="count_dtls">
                      <span className="count_txt">
                        {TopBoxData?.Particioants
                          ? TopBoxData?.Particioants
                          : 0}
                      </span>
                      <br />

                      {/* <small className='green_text'>+3,14% </small>
                     <img src={require('../assets/images/faces/small_green_arrow.png')} /> */}
                    </div>
                    {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/blue_graph.png')} /> */}
                  </div>
                </div>
              </div>
              <div>
                <div className="card blackcard_style mb-2">
                  <div className="firstrow">
                    <div>
                      <i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i>
                    </div>
                    <div className="div_text">Total Conversions</div>
                  </div>

                  <div className="graph_align">
                    <div className="count_dtls">
                      <span className="count_txt">
                        {TopBoxData?.Conversions ? TopBoxData?.Conversions : 0}
                      </span>
                      <br />

                      {/* <small className='green_text'>+0%</small> */}
                    </div>
                    {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/red_graph.png')} /> */}
                  </div>
                </div>
              </div>
              <div>
                <div className="card blackcard_style mb-2">
                  <div className="firstrow">
                    <div>
                      <i class="fa fa-circle admint_nrm_round admin_violet_round mb-2"></i>
                    </div>
                    <div className="div_text">Total Tokens Claimed</div>
                  </div>

                  <div className="graph_align">
                    <div className="count_dtls">
                      <span className="count_txt">
                        {TopBoxData?.Claim ? TopBoxData?.Claim : 0}
                      </span>
                      <br />

                      {/* <small className='green_text'>+5%</small> */}
                    </div>
                    {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/violet_graph.png')} /> */}
                  </div>
                </div>
              </div>
              <div>
                <div className="card blackcard_style mb-2">
                  <div className="firstrow">
                    <div>
                      <i class="fa fa-circle admint_nrm_round admin_sandal_round mb-2"></i>
                    </div>
                    <div className="div_text">Campaign ROI</div>
                  </div>

                  <div className="graph_align">
                    <div className="count_dtls">
                      <span className="count_txt">
                        {TopBoxData?.ROI ? TopBoxData?.ROI : 0}
                      </span>
                      <br />

                      {/* <small className='green_text'>+0%</small> */}
                    </div>
                    {/* <img className='admin_graph_img_align' src={require('../assets/images/faces/sandal_graph.png')} /> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="whole_templates">
                  <div className="single_template">
                    <p className="template_ttl">Create Campaign Page</p>
                    <img
                      src={require("../assets/images/lazymintingnew/image13.png")}
                    />
                  </div>
                  <div className="single_template">
                    <p className="template_ttl"> Design Token Page</p>
                    <img
                      src={require("../assets/images/lazymintingnew/image14.png")}
                    />
                  </div>
                  <div className="single_template">
                    <p className="template_ttl"> Sell Event Tickets</p>
                    <img
                      src={require("../assets/images/lazymintingnew/image15.png")}
                    />
                  </div>
                  <div className="single_template">
                    <p className="template_ttl">Launch Treasure Hunt</p>
                    <img
                      src={require("../assets/images/lazymintingnew/image16.png")}
                    />
                  </div>
                  <div className="single_template">
                    <p className="template_ttl">Launch Auction Event</p>
                    <img
                      src={require("../assets/images/lazymintingnew/image9.png")}
                    />
                  </div>

                  <div className="single_template">
                    <p className="template_ttl">
                      Issue Passport / Loyalty Card{" "}
                    </p>
                    <img
                      className="campagin_inverimg"
                      src={require("../assets/images/lazymintingnew/image5.png")}
                    />
                  </div>
                  <div className="single_template">
                    <p className="template_ttl">Issue Vouchers / Coupons </p>
                    <img
                      src={require("../assets/images/lazymintingnew/image10.png")}
                    />
                  </div>
                  <div className="single_template"></div>
                  <div className="single_template"></div>
                  <div className="single_template"></div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div className="subscriber_whole_dtls_bgalign">
                  <div className="subscriber_whole_dtls_align d-flex justify-content-between w-100">
                    <p className="admin_new_subscribers">New Followers</p>
                    {/* <Link to='#' className='admin_seall_txt'>See All</Link> */}
                  </div>
                  {SubList.length > 0 ? (
                    <>
                      {SubList &&
                        SubList.map((item, index) => {
                          console.log("iooioioioi", item);
                          return (
                            <div className="admin_subscriber_dtls_align w-100">
                              {/* <img className='admin_dropdown_img_align' src={require('../assets/images/faces/marc.jpg')} /> */}
                              {item.image == null || undefined ? (
                                <img
                                  className="admin_dropdown_img_align"
                                  src={require("../assets/images/faces/marc.jpg")}
                                />
                              ) : (
                                <img
                                  className="admin_dropdown_img_align"
                                  src={`${config.Back_URL}/Users/${item.PrimaryAddr}/profileImg/${item?.image}`}
                                />
                              )}
                              <div>
                                <p className="subscribers_align_dtls">
                                  {item?.name == undefined
                                    ? item?.userAddress?.slice(0, 15)
                                    : item?.name}
                                  <span className="admin_badge_icon_align">
                                    <i class="fa-solid fa-certificate admin_certificate_icon" />
                                    <i class="fa-solid fa-check admin_tick_icon" />
                                  </span>
                                </p>

                                <span className="follwers_count_align">
                                  22k Followers
                                </span>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <h6 className="no_fol_found">No followers found</h6>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12">
                <div className="kr_srcolablediv gap-10 scrolablediv">
                  <button
                    className="graybtn btn mr-2 mb-2"
                    data-toggle="modal"
                    data-target="#addcampaingModal"
                    onClick={()=> ResetclearAll() }
                  >
                    + Add Campaign
                  </button>
                  {/* <button
                                        className="graybtn btn mr-2 mb-2"

                                    >
                                        + Update Campaign
                                    </button>

                                    <button
                                        className="graybtn btn mr-2 mb-2"

                                    >
                                        + Duplicate Campaign
                                    </button>
                                    <button
                                        className="graybtn btn mr-2 mb-2"

                                    >
                                        - Delete Campaign
                                    </button> */}
                </div>
                {console.log("Details.data.Data", EventDetails)}
                <div style={{ height: 300, width: "100%" }}>
                  <StyledDataGrid
                    // checkboxSelection
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    rows={rows}
                    columns={columns}
                    disableColumnMenu
                    components={{
                      Pagination: CustomPagination,
                    }}
                    className="campaign_muitbl"
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                <div className="cambaign_glazier">
                  <img
                    className="glazier_img_align"
                    src={require("../assets/images/faces/mask_group.png")}
                    alt="image"
                  />
                </div>
              </div>
            </div>
          </div>
          {console.log("sdcsjdkfdssdfas", Formdata)}
          {/* start of campaign modals */}
          {Wallet_Details.TemplateName == "Collectible NFTs Template" ? (
            <div
              class="modal fade"
              id="addcampaingModal"
              data-backdrop="static"
              data-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title" id="staticBackdropLabel">
                      Create / Update Campaign Page
                    </h5>
                    <button
                      type="button"
                      class="close"
                      id="addcampaingModal_close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  {Showed == "true" ? (
                    <div class="modal-body">
                      {console.log(":asdsadasda", Formdata)}
                      <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Name of Campaign
                            </span>
                            <input
                              type="text"
                              id="campaignname"
                              className="edit_inputs_align"
                              placeholder="Campaign Name*"
                              name="campaignname"
                              value={Formdata.campaignname}
                              onChange={(e) => OnChangehandle(e)}
                            />
                            <br />
                          </div>
                          {ValidationError && ValidationError.campaignname && (
                            <span className="validation_txt text-center">
                              {ValidationError && ValidationError.campaignname}
                            </span>
                          )}
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Campaign Code
                            </span>
                            <input
                              type="text"
                              id="campaigncode"
                              className="edit_inputs_align"
                              placeholder="Campaign Code"
                              name="campaigncode"
                              value={Formdata.campaigncode}
                              disabled
                            />
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Subdomain
                            </span>
                            <div className="campaignmdl_inpdbl">
                              <input
                                type="text"
                                id="domainname"
                                className="edit_inputs_align"
                                placeholder="Insertname"
                                name="domainname"
                                value={Formdata.domainname}
                                onChange={(e) => OnChangehandle(e)}
                              />
                              <p className="ml-1">.lazyminter.com</p>
                            </div>
                            <br />
                          </div>
                          {ValidationError && ValidationError.domainname && (
                            <span className="validation_txt text-center">
                              {ValidationError && ValidationError.domainname}
                            </span>
                          )}
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Redirect
                            </span>
                            <input
                              type="text"
                              id="redirect"
                              className="edit_inputs_align"
                              placeholder="www.uss.com"
                              name="redirect"
                              value={Formdata.redirect}
                              onChange={(e) => OnChangehandle(e)}
                            />
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Template
                            </span>
                            {console.log("Asdasdasdasd", Formdata.template)}
                            <Select
                              className="yes1 form-control selxet_app campaign_select"
                              onChange={(e) => selectStandard(e)}
                              options={optionsmodal}
                              // menuIsOpen={true}
                              label="Single select"
                              isSearchable={false}
                              styles={stylesgraybg}
                              classNamePrefix="react-select"
                              formControlProps={{
                                fullWidth: true,
                              }}
                            />
                            <br />
                          </div>
                          {ValidationError && ValidationError.template && (
                            <span className="validation_txt text-center">
                              {ValidationError && ValidationError.template}
                            </span>
                          )}
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Company Logo
                            </span>

                            <div className="cmpny_logo_flex">
                              <input
                                type="text"
                                id="PackName"
                                className="edit_inputs_align  cmpny_logo"
                                placeholder="Select File"
                                name="wallet address"
                                disabled
                              />
                              <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                <input
                                  type="file"
                                  id="companylogo"
                                  className="edit_inputs_align width_algd campaign_fileinp"
                                  placeholder="Plan Name*"
                                  name="companylogo"
                                  onChange={(e) => HandleCompanyLogo(e)}
                                />
                                <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                              <p className="">Image Dimension</p>
                              <p className="">(min 70px, 100px)</p>
                            </div>
                            {console.log(
                              "Asdasdasdaakjsdnas",
                              config.Back_URL + "/" + Formdata.companylogo,
                              Formdata.companylogo
                            )}
                            <img
                              src={
                                CompanyLogoPreview
                                  ? CompanyLogoPreview
                                  : Formdata.companylogo
                                    ? config.Back_URL + "/" + Formdata.companylogo
                                    : dummy
                              }
                              className="subd_logo lightimg"
                            />
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              NFT Category Title
                            </span>
                            <input
                              type="text"
                              id="categoryTittle"
                              value={Formdata.categoryTittle}
                              className="edit_inputs_align"
                              placeholder="Enter NFT Category Title"
                              name="categoryTittle"
                              onChange={(e) => OnChangehandle(e)}
                            />
                          </div>

                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Country
                            </span>
                            <input
                              type="text"
                              id="country"
                              value={Formdata.country}
                              className="edit_inputs_align"
                              placeholder="Singapore"
                              name="country"
                              onChange={(e) => OnChangehandle(e)}
                            />
                            <br />
                          </div>
                          {ValidationError && ValidationError.country && (
                            <span className="validation_txt text-center">
                              {ValidationError && ValidationError.country}
                            </span>
                          )}
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              {" "}
                              Number of Treasure{" "}
                            </span>
                            <input
                              type="number"
                              id="NoOfTreasure"
                              className="edit_inputs_align"
                              placeholder="Enter a Number"
                              name="NoOfTreasure"
                              onKeyDown={handleKeyDown}
                              onWheel={(e) => e.target.blur()}
                              value={Formdata.NoOfTreasure}
                              onChange={(e) => OnChangehandle(e)}
                            />
                            <br />
                          </div>
                          {ValidationError && ValidationError.NoOfTreasure && (
                            <span className="validation_txt text-center">
                              {ValidationError && ValidationError.NoOfTreasure}
                            </span>
                          )}
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Mystery NFT
                            </span>
                            <input
                              type="number"
                              id="mysteryNft"
                              className="edit_inputs_align"
                              placeholder="Enter a Number"
                              name="mysteryNft"
                              onKeyDown={handleKeyDown}
                              onWheel={(e) => e.target.blur()}
                              value={Formdata.mysteryNft}
                              onChange={(e) => OnChangehandle(e)}
                            />
                            <br />
                          </div>
                          {ValidationError && ValidationError.mysteryNft && (
                            <span className="validation_txt text-center">
                              {ValidationError && ValidationError.mysteryNft}
                            </span>
                          )}
                          <div>
                            <button
                              className="graybtn btn mr-2 mb-2"
                              onClick={() => NftValidateAndCheck("Treasure")}
                            >
                              Choose Treasure NFT
                            </button>
                            <button
                              className="graybtn btn mr-2 mb-2"
                              onClick={() => NftValidateAndCheck("Mystry")}
                            >
                              Choose Mystery NFT
                            </button>
                            <button
                              className="graybtn btn mr-2 mb-2"
                              onClick={() => ChoosePlaces("Mystry")}
                            >
                              Choose Mystery place
                            </button>
                            {Wallet_Details.Reset == "Update" && (
                              <button
                                className="graybtn btn mr-2 mb-2"
                                onClick={() => ReSetNft()}
                              >
                                Reset
                              </button>
                            )}
                          </div>
                          {Wallet_Details.SelectedFinal == "true" &&
                            Wallet_Details.SelectMystry.length > 0 && (
                              <>
                                <div className="campaignmodal_cards mys_show_card">
                                  {console.log(
                                    "Wallet_Details.SelectMystry",
                                    Wallet_Details.SelectMystry
                                  )}
                                  {Wallet_Details.SelectMystry.length > 0 &&
                                    Wallet_Details.SelectMystry.map(
                                      (item, index) => {
                                        return (
                                          <div class="card sub_domaincard">
                                            <img
                                              src={require("../assets/images/faces/image2.png")}
                                              class="card-img-top subdomain_cardimg blur_img"
                                              alt=""
                                            />
                                            <div class="card-body">
                                              <p className="subd_domaincardtxt">
                                                Choose{" "}
                                              </p>
                                              <button className="subd_clctnowbtn">
                                                mystery place
                                              </button>
                                              <div className="check_input">
                                                <input
                                                  class="form-check-input"
                                                  type="checkbox"
                                                  // checked={Wallet_Details?.MyItemNFTsMystry?.some(it => it == NFTDetails._id)}
                                                  //{NFTDetails?.check}
                                                  onClick={(e) =>
                                                    IndexPositionsMystry(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                                <button
                                  className="subd_clctnowbtn"
                                  onClick={() => {
                                    SelectedFinal();
                                  }}
                                >
                                  confirm
                                </button>
                              </>
                            )}

                          {Wallet_Details.FinalNfts.length > 0 && (
                            <>
                              <div className="campaignmodal_cards mys_show_card">
                                {console.log(
                                  "Wallet_Details.SelectMystry",
                                  Wallet_Details.FinalNfts
                                )}
                                {Wallet_Details.FinalNfts.length > 0 &&
                                  Wallet_Details.FinalNfts.map(
                                    (item, index) => {
                                      return (
                                        <div class="card sub_domaincard">
                                          {item.image != "" &&
                                            (item.image.split(".").pop() ==
                                              "mp4" ? (
                                              <video
                                                id="my-video"
                                                class="img-fluid"
                                                autoPlay
                                                playsInline
                                                loop
                                                muted
                                                preload="auto"
                                                controls
                                                disablePictureInPicture
                                                controlsList="nodownload"
                                              >
                                                <source
                                                  src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                                  type="video/mp4"
                                                />
                                              </video>
                                            ) : (
                                              <img
                                                src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                                alt="Collections"
                                                className="img-fluid mys_img_show"
                                              />
                                            ))}
                                          {/* <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg blur_img" alt="" /> */}
                                          {/* <div class="card-body text-center">
                                                                                <p className='subd_domaincardtxt'>Choose </p>
                                                                            </div> */}
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Hero Banner Title
                            </span>
                            <input
                              type="text"
                              id="bannerTittle"
                              value={Formdata.bannerTittle}
                              className="edit_inputs_align"
                              placeholder="Collect Limited Editions of Halloween NFTs"
                              name="bannerTittle"
                              onChange={(e) => OnChangehandle(e)}
                            />
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Hero Banner Description
                            </span>
                            {Wallet_Details.Update !== "Create" ? (
                              <>
                                {/* {Formdata.bannerdiscription && */}
                                {/* <CKEditor
                                  initData={
                                    <p>
                                      {ReactHTMLParser(
                                        Formdata.bannerdiscription
                                      )}
                                    </p>
                                  }
                                  id="bannerdiscription"
                                  onChange={(e) => EditorBannerDiscription(e)}
                                /> */}
                                <div>
            {/* <MatEditor outdata={(e) => EditorBannerDiscription(e)} sendata={Formdata.bannerdiscription}/> */}
            <ReactQuill
                              value={Formdata.bannerdiscription || BannerDiscription}
                             onChange={value => EditorBannerDiscription(value)}
                               modules={modules}
                               theme="snow"
                             /> 
         
          </div>
                                    {/* <ReactSummernote
                                        // children="<p>water</p>"
                                        children={ReactHTMLParser(Formdata.bannerdiscription)}
                                        options={{
                                            //   lang: 'ru-RU',
                                            height: 400,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ["style", ["style"]],
                                                ["font", ["bold", "underline", "clear"]],
                                                ["fontname", ["fontname"]],
                                                ["para", ["ul", "ol", "paragraph"]],
                                                ["table", ["table"]],
                                                ["insert", ["link", "picture", "video"]],
                                                ["view", ["fullscreen", "codeview"]],
                                            ],
                                        }}
                                        id="bannerdiscription"
                                        onChange={(e) => EditorBannerDiscription(e)}
                                    /> */}
                                {/* } */}
                              </>
                            ) : (
                              // <CKEditor
                              //   initData={
                              //     <p>
                              //       {ReactHTMLParser(
                              //         Formdata.bannerdiscription
                              //       )}
                              //     </p>
                              //   }
                              //   id="bannerdiscription"
                              //   onChange={(e) => EditorBannerDiscription(e)}
                              // />
                              <div>
                              {/* <MatEditor outdata={(e) => EditorBannerDiscription(e)} sendata={Formdata.bannerdiscription}/> */}
                              <ReactQuill
                              value={Formdata.bannerdiscription || BannerDiscription}
                             onChange={value => EditorBannerDiscription(value)}
                               modules={modules}
                               theme="snow"
                             /> 
                             
                            </div>
                          //     <ReactSummernote
                          //     // children="<p>water</p>"
                          //     children={ReactHTMLParser(Formdata.bannerdiscription)}
                          //     options={{
                          //         //   lang: 'ru-RU',
                          //         height: 400,
                          //         dialogsInBody: true,
                          //         toolbar: [
                          //             ["style", ["style"]],
                          //             ["font", ["bold", "underline", "clear"]],
                          //             ["fontname", ["fontname"]],
                          //             ["para", ["ul", "ol", "paragraph"]],
                          //             ["table", ["table"]],
                          //             ["insert", ["link", "picture", "video"]],
                          //             ["view", ["fullscreen", "codeview"]],
                          //         ],
                          //     }}
                          //     id="bannerdiscription"
                          //     onChange={(e) => EditorBannerDiscription(e)}
                          // />
                            )}
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Hero Banner
                            </span>

                            <div className="cmpny_logo_flex">
                              <input
                                type="text"
                                id="PackName"
                                className="edit_inputs_align  cmpny_logo"
                                placeholder="Select File"
                                name="wallet address"
                                disabled
                              />
                              <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                <input
                                  type="file"
                                  id="companylogo"
                                  className="edit_inputs_align width_algd campaign_fileinp"
                                  placeholder="Plan Name*"
                                  name="companylogo"
                                  onChange={(e) => HandleBannerImage(e)}
                                />
                                <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="d-sm-flex campaignmdl_inps">
                            <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                              <p className="">Image Dimension</p>
                              <p className="">(min 700px, 700px)</p>
                            </div>
                            {console.log("asdasdasda", Formdata)}
                            <img
                              className="campaign_bannerimg"
                              src={
                                bannerImagePreview
                                  ? bannerImagePreview
                                  : Formdata.bannerImage
                                    ? config.Back_URL + "/" + Formdata.bannerImage
                                    : dummy
                              }
                            />
                          </div>

                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Middle Banner Title
                            </span>
                            <input
                              type="text"
                              id="middlebannertittle"
                              value={Formdata.middlebannertittle}
                              className="edit_inputs_align"
                              placeholder="Join Us In The Treasure Hunt Collect You NFT Token"
                              name="middlebannertittle"
                              onChange={(e) => OnChangehandle(e)}
                            />
                          </div>

                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Middle Banner Description
                            </span>
                            {Wallet_Details.Update !== "Create" ? (
                              <>
                                {/* {Formdata.middlebannerdescription && */}
                                {/* <CKEditor
                                  className="ckedit_hei"
                                  initData={
                                    <p>
                                      {ReactHTMLParser(
                                        Formdata.middlebannerdescription
                                      )}
                                    </p>
                                  }
                                  id="middlebannerdescription"
                                  onChange={(e) =>
                                    Editormiddlebannerdescription(e)
                                  }
                                /> */}
                                {/* <MatEditor outdata={(e) => Editormiddlebannerdescription(e)} sendata={Formdata.middlebannerdescription}/> */}
                                <ReactQuill
                              value={Formdata.middlebannerdescription || middlebannerdescription}
                             onChange={value => Editormiddlebannerdescription(value)}
                               modules={modules}
                               theme="snow"
                             /> 
                                
                                    {/* <ReactSummernote
                                        // children="<p>water</p>"
                                        children={ReactHTMLParser(Formdata.middlebannerdescription)}
                                        options={{
                                            //   lang: 'ru-RU',
                                            height: 400,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ["style", ["style"]],
                                                ["font", ["bold", "underline", "clear"]],
                                                ["fontname", ["fontname"]],
                                                ["para", ["ul", "ol", "paragraph"]],
                                                ["table", ["table"]],
                                                ["insert", ["link", "picture", "video"]],
                                                ["view", ["fullscreen", "codeview"]],
                                            ],
                                        }}
                                        id="middlebannerdescription"
                                  onChange={(e) =>
                                    Editormiddlebannerdescription(e)
                                  }

                                    /> */}
                                {/* } */}
                              </>
                            ) : (
                              // <CKEditor
                              //   className="ckedit_hei"
                              //   initData={
                              //     <p>
                              //       {ReactHTMLParser(
                              //         Formdata.middlebannerdescription
                              //       )}
                              //     </p>
                              //   }
                              //   id="middlebannerdescription"
                              //   onChange={(e) =>
                              //     Editormiddlebannerdescription(e)
                              //   }
                              // />
                              // <MatEditor outdata={(e) => Editormiddlebannerdescription(e)} sendata={Formdata.middlebannerdescription}/>
                              <ReactQuill
                              value={Formdata.middlebannerdescription || middlebannerdescription}
                             onChange={value => Editormiddlebannerdescription(value)}
                               modules={modules}
                               theme="snow"
                             /> 
                          //     <ReactSummernote
                          //     // children="<p>water</p>"
                          //     children={ReactHTMLParser(Formdata.middlebannerdescription)}
                          //     options={{
                          //         //   lang: 'ru-RU',
                          //         height: 400,
                          //         dialogsInBody: true,
                          //         toolbar: [
                          //             ["style", ["style"]],
                          //             ["font", ["bold", "underline", "clear"]],
                          //             ["fontname", ["fontname"]],
                          //             ["para", ["ul", "ol", "paragraph"]],
                          //             ["table", ["table"]],
                          //             ["insert", ["link", "picture", "video"]],
                          //             ["view", ["fullscreen", "codeview"]],
                          //         ],
                          //     }}
                          //     id="middlebannerdescription"
                          //        onChange={(e) =>
                          //          Editormiddlebannerdescription(e)
                          //        }
                          // />
                            )}
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Middle Banner Title Details
                            </span>
                            <input
                              type="text"
                              id="middlebannerdescriptionTittle"
                              value={Formdata.middlebannerdescriptionTittle}
                              className="edit_inputs_align"
                              placeholder="Collectible Halloween Tokens Only At Univarsal Studios In Sentosa"
                              name="middlebannerdescriptionTittle"
                              onChange={(e) => OnChangehandle(e)}
                            />
                          </div>

                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Middle Banner Description Details
                            </span>
                            {console.log(
                              "Asdasdsa",
                              Formdata.middlebannerdetails
                            )}
                            {Wallet_Details.Update !== "Create" ? (
                              <>
                                {/* {Formdata.middlebannerdetails && */}
                                {/* <CKEditor
                                  initData={
                                    <p>
                                      {ReactHTMLParser(
                                        Formdata.middlebannerdescription
                                      )}
                                    </p>
                                  }
                                  id="middlebannerdetails"
                                  onChange={(e) => Editormiddlebannerdetails(e)}
                                /> */}
                                {/* <MatEditor outdata={(e) => Editormiddlebannerdetails(e)} sendata={Formdata.middlebannerdescription}/> */}
                                <ReactQuill
                              value={Formdata.middlebannerdescription || middlebannerdetails}
                             onChange={value => Editormiddlebannerdetails(value)}
                               modules={modules}
                               theme="snow"
                             /> 
                                    {/* <ReactSummernote
                                        // children="<p>water</p>"
                                        children={ReactHTMLParser(Formdata.middlebannerdescription)}
                                        options={{
                                            //   lang: 'ru-RU',
                                            height: 400,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ["style", ["style"]],
                                                ["font", ["bold", "underline", "clear"]],
                                                ["fontname", ["fontname"]],
                                                ["para", ["ul", "ol", "paragraph"]],
                                                ["table", ["table"]],
                                                ["insert", ["link", "picture", "video"]],
                                                ["view", ["fullscreen", "codeview"]],
                                            ],
                                        }}
                                        id="middlebannerdetails"
                                        onChange={(e) => Editormiddlebannerdetails(e)}
                                    /> */}
                                {/* } */}
                              </>
                            ) : (
                              // <CKEditor
                              //   className="ckedit_hei"
                              //   initData={
                              //     <p>
                              //       {ReactHTMLParser(
                              //         Formdata.middlebannerdescription
                              //       )}
                              //     </p>
                              //   }
                              //   id="middlebannerdescription"
                              //   onChange={(e) => Editormiddlebannerdetails(e)}
                              // />
                              // <MatEditor outdata={(e) => Editormiddlebannerdetails(e)} sendata={Formdata.middlebannerdescription}/>
                              <ReactQuill
                              value={Formdata.middlebannerdescription || middlebannerdetails}
                             onChange={value => Editormiddlebannerdetails(value)}
                               modules={modules}
                               theme="snow"
                             /> 
                      
                            )}
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Event Details
                            </span>
                            {console.log("Asdasdsa", Formdata.EventDeatils)}
                            {Wallet_Details.Update !== "Create" ? (
                              <>
                                {/* {Formdata.middlebannerdetails && */}
                                {/* <CKEditor
                                  initData={
                                    <p>
                                      {ReactHTMLParser(Formdata.EventDeatils)}
                                    </p>
                                  }
                                  id="EventDeatils"
                                  onChange={(e) => EditorEventDeatils(e)}
                                /> */}
                                 {/* <MatEditor outdata={(e) => EditorEventDeatils(e)} sendata={Formdata.EventDeatils}/> */}
                                 <ReactQuill
                              value={Formdata.EventDeatils || EventDeatils}
                             onChange={value => EditorEventDeatils(value)}
                               modules={modules}
                               theme="snow"
                             /> 
                                    {/* <ReactSummernote
                                        // children="<p>water</p>"
                                        children={ReactHTMLParser(Formdata.EventDeatils)}
                                        options={{
                                            //   lang: 'ru-RU',
                                            height: 400,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ["style", ["style"]],
                                                ["font", ["bold", "underline", "clear"]],
                                                ["fontname", ["fontname"]],
                                                ["para", ["ul", "ol", "paragraph"]],
                                                ["table", ["table"]],
                                                ["insert", ["link", "picture", "video"]],
                                                ["view", ["fullscreen", "codeview"]],
                                            ],
                                        }}
                                        id="EventDeatils"
                                  onChange={(e) => EditorEventDeatils(e)}
                                    /> */}
                                {/* } */}
                              </>
                            ) : (
                              // <CKEditor
                              //   className="ckedit_hei"
                              //   initData={
                              //     <p>
                              //       {ReactHTMLParser(
                              //         Formdata.middlebannerdescription
                              //       )}
                              //     </p>
                              //   }
                              //   id="EventDeatils"
                              //   onChange={(e) => EditorEventDeatils(e)}
                              // />
                              // <MatEditor outdata={(e) => EditorEventDeatils(e)} sendata={Formdata.middlebannerdescription}/>
                              <ReactQuill
                              value={Formdata.middlebannerdescription || EventDeatils}
                             onChange={value => EditorEventDeatils(value)}
                               modules={modules}
                               theme="snow"
                             /> 
                          //     <ReactSummernote
                          //     // children="<p>water</p>"
                          //     children={ReactHTMLParser(Formdata.middlebannerdescription)}
                          //     options={{
                          //         //   lang: 'ru-RU',
                          //         height: 400,
                          //         dialogsInBody: true,
                          //         toolbar: [
                          //             ["style", ["style"]],
                          //             ["font", ["bold", "underline", "clear"]],
                          //             ["fontname", ["fontname"]],
                          //             ["para", ["ul", "ol", "paragraph"]],
                          //             ["table", ["table"]],
                          //             ["insert", ["link", "picture", "video"]],
                          //             ["view", ["fullscreen", "codeview"]],
                          //         ],
                          //     }}
                          //     id="EventDeatils"
                          //     onChange={(e) => EditorEventDeatils(e)}
                          // />
                            )}
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Middle Banner
                            </span>
                            <div className="cmpny_logo_flex">
                              <input
                                type="text"
                                id="PackName"
                                className="edit_inputs_align  cmpny_logo"
                                placeholder="Select File"
                                name="wallet address"
                                disabled
                              />
                              <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                <input
                                  type="file"
                                  id="companylogo"
                                  className="edit_inputs_align width_algd campaign_fileinp"
                                  placeholder="Plan Name*"
                                  name="companylogo"
                                  onChange={(e) => HandleMiddleImage(e)}
                                />
                                <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                  Upload
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                              <p className="">Image Dimension</p>
                              <p className="">(min 350px, 350px)</p>
                            </div>
                            <img
                              className="subd_logomodal"
                              src={
                                middlebannerPreview
                                  ? middlebannerPreview
                                  : Formdata.middlebannerimage
                                    ? config.Back_URL +
                                    "/" +
                                    Formdata.middlebannerimage
                                    : dummy
                              }
                            />
                          </div>
                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Top Winners Title
                            </span>
                            <input
                              type="text"
                              id="TopWinnerTittle"
                              value={Formdata.TopWinnerTittle}
                              className="edit_inputs_align"
                              placeholder="Top Winners this Week"
                              name="TopWinnerTittle"
                              onChange={(e) => OnChangehandle(e)}
                            />
                          </div>

                          <div className="d-sm-flex campaignmdl_inps">
                            <span className="labelspan mb-sm-0 mb-2 mr-2">
                              Background Colours
                            </span>
                            <div>
                              <div className="campaignmdl_cltita">
                                <div>
                                  <input
                                    className="mr-3"
                                    type="color"
                                    id="backgroundcolor"
                                    name="backgroundcolor"
                                    value={Formdata.backgroundcolor}
                                    onChange={(e) => OnChangehandle(e)}
                                  />
                                  <span className="cambaignmdl_italic">
                                    Click to choose your color
                                  </span>
                                </div>
                                <span className="cambaignmdl_italic">
                                  Select the website background color
                                </span>
                              </div>
                              {/* <div className='campaignmdl_checks'>
                                                <input type="radio" id="predefined" name="fav_language" value="predefined" />
                                                <label className='m-0 mr-2' for="predefined">Predefined images</label><br />
                                                <input type="radio" id="custom" name="fav_language" value="custom" />
                                                <label className='m-0' for="custom">Custom images</label>
                                            </div> */}
                              {/* <div className='campaignmdl_xamplediv'></div> */}
                            </div>
                          </div>
                          <div className="campaignmdl_triplebtns">
                            {Wallet_Details.Update == "Create" && (
                              <button
                                className="admin_mdl_red_btn admn_img_upld_btn"
                                onClick={() => {
                                  SaveEvent();
                                }}
                              >
                                Save
                              </button>
                            )}
                            {Wallet_Details.Update == "Update" && (
                              <button
                                className="admin_mdl_red_btn admn_img_upld_btn"
                                onClick={() => {
                                  SaveEvent();
                                }}
                              >
                                Update
                              </button>
                            )}
                            {/* <Link to={{ pathname: `/Preview`, state: Formdata, location: "collection" }} target={"_blank"} > */}
                            <button
                              className="admin_mdl_red_btn admn_img_upld_btn"
                              onClick={() => PreviewContent()}
                            >
                              Preview
                            </button>
                            {/* </Link> */}
                            {Wallet_Details.Update == "Create" && (
                              <button
                                className="admin_mdl_red_btn admn_img_upld_btn"
                                onClick={() => PublishEvent()}
                              >
                                publish
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={
                        classes.pageHeader +
                        " inner_pageheader items_header my_items"
                      }
                    >
                      <div className="inner_pagh_bg_1">
                        <div className="container container_full">
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <nav className="masonry_tab_nav mt-4 mb-3 items_tab_outer">
                                <div
                                  className="nav nav-tabs masonry_tab primary_tab items_tab justify-content-center items_tab_new pb-0 pl-0"
                                  id="nav-tab"
                                  role="tablist"
                                >
                                  <a
                                    onClick={() => {
                                      setTabName("created");
                                    }}
                                    className="nav-link"
                                    id="created-tab"
                                    data-toggle="tab"
                                    href="#created"
                                    role="tab"
                                    aria-controls="created"
                                    aria-selected="false"
                                  >
                                    <div className="tab_head">Created</div>
                                  </a>
                                  <a
                                    onClick={() => {
                                      MyItemTab("Ticket");
                                    }}
                                    className="nav-link"
                                    id="Ticket-tab"
                                    data-toggle="tab"
                                    href="#Ticket"
                                    role="tab"
                                    aria-controls="Ticket"
                                    aria-selected="false"
                                  >
                                    <div className="tab_head">Ticket</div>
                                  </a>
                                </div>
                              </nav>
                              <div
                                className="tab-content explore_tab_content  myitemsdf"
                                id="nav-tabContent"
                              >
                                <div
                                  className="tab-pane fade show active"
                                  id={TabName}
                                  role="tabpanel"
                                  aria-labelledby={`${TabName}-tab`}
                                >
                                  <div className="proposal_panel_overall">
                                    {TabName === "created" ? (
                                      <>
                                        <MyItemCard />
                                        <div className="row">
                                          {/* <Card /> */}
                                        </div>
                                        {MyItemNFTs["created"] &&
                                          MyItemNFTs["created"]?.onmore && (
                                            <div className="col-12 pb-5 text-center pt-4">
                                              <button
                                                className="create_btn"
                                                onClick={() =>
                                                  MyItemTab(
                                                    "created",
                                                    Wallet_Details.UserAccountAddr,
                                                    null,
                                                    MyItemNFTs["created"].page +
                                                    1
                                                  )
                                                }
                                              >
                                                Load more items
                                              </button>
                                            </div>
                                          )}
                                      </>
                                    ) : (
                                      <>
                                        <Ticketss />
                                        <div className="row">
                                          {/* <Card /> */}
                                        </div>
                                        {MyItemNFTs["Ticket"] &&
                                          MyItemNFTs["Ticket"].onmore && (
                                            <div className="col-12 pb-5 text-center pt-4">
                                              <button
                                                className="create_btn"
                                                onClick={() =>
                                                  MyItemTab(
                                                    "Ticket",
                                                    Wallet_Details.UserAccountAddr,
                                                    null,
                                                    MyItemNFTs["Ticket"].page +
                                                    1
                                                  )
                                                }
                                              >
                                                Load more items
                                              </button>
                                            </div>
                                          )}
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </GridItem>
                          </GridContainer>
                          <button
                            className="graybtn btn mr-2 mb-2"
                            onClick={() => setshowedd("true")}
                          >
                            Back
                          </button>
                          <button
                            className="graybtn btn mr-2 mb-2"
                            onClick={() => SaveNft()}
                          >
                            Confirm
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div
              class="modal fade"
              id="addcampaingModal"
              data-backdrop="static"
              data-keyboard="false"
              tabindex="-1"
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              {Wallet_Details.TemplateName == "Design Template" ? (
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="staticBackdropLabel">
                        Create / Update Campaign Page
                      </h5>
                      <button
                        type="button"
                        class="close"
                        id="addcampaingModal_close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    {Showed == "true" ? (
                      <div class="modal-body">
                        {console.log(":asdsadasda", Formdata)}
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Name of Campaign
                              </span>
                              <input
                                type="text"
                                id="campaignname"
                                className="edit_inputs_align"
                                placeholder="Campaign Name*"
                                name="campaignname"
                                value={Formdata.campaignname}
                                onChange={(e) => OnChangehandle(e)}
                              />
                              <br />
                            </div>
                            {ValidationError &&
                              ValidationError.campaignname && (
                                <span className="validation_txt text-center">
                                  {ValidationError &&
                                    ValidationError.campaignname}
                                </span>
                              )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Campaign Code
                              </span>
                              <input
                                type="text"
                                id="campaigncode"
                                className="edit_inputs_align"
                                placeholder="Campaign Code"
                                name="campaigncode"
                                value={Formdata.campaigncode}
                                disabled
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Subdomain
                              </span>
                              <div className="campaignmdl_inpdbl">
                                <input
                                  type="text"
                                  id="domainname"
                                  className="edit_inputs_align"
                                  placeholder="Insertname"
                                  name="domainname"
                                  value={Formdata.domainname}
                                  onChange={(e) => OnChangehandle(e)}
                                />
                                <p className="ml-1">.lazyminter.com</p>
                              </div>
                              <br />
                            </div>
                            {ValidationError && ValidationError.domainname && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.domainname}
                              </span>
                            )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Redirect
                              </span>
                              <input
                                type="text"
                                id="redirect"
                                className="edit_inputs_align"
                                placeholder="www.uss.com"
                                name="redirect"
                                value={Formdata.redirect}
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Template
                              </span>
                              {console.log("Asdasdasdasd", Formdata.template)}
                              <Select
                                className="yes1 form-control selxet_app campaign_select"
                                onChange={(e) => selectStandard(e)}
                                options={optionsmodal}
                                // menuIsOpen={true}
                                label="Single select"
                                isSearchable={false}
                                styles={stylesgraybg}
                                classNamePrefix="react-select"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              <br />
                            </div>
                            {ValidationError && ValidationError.template && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.template}
                              </span>
                            )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Country
                              </span>
                              <input
                                type="text"
                                id="country"
                                value={Formdata.country}
                                className="edit_inputs_align"
                                placeholder="Singapore"
                                name="country"
                                onChange={(e) => OnChangehandle(e)}
                              />
                              <br />
                            </div>
                            {ValidationError && ValidationError.country && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.country}
                              </span>
                            )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Company Logo
                              </span>

                              <div className="cmpny_logo_flex">
                                <input
                                  type="text"
                                  id="PackName"
                                  className="edit_inputs_align  cmpny_logo"
                                  placeholder="Select File"
                                  name="wallet address"
                                  disabled
                                />
                                <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                  <input
                                    type="file"
                                    id="companylogo"
                                    className="edit_inputs_align width_algd campaign_fileinp"
                                    placeholder="Plan Name*"
                                    name="companylogo"
                                    onChange={(e) => HandleCompanyLogo(e)}
                                  />
                                  <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                                <p className="">Image Dimension</p>
                                <p className="">(min 70px, 100px)</p>
                              </div>
                              {console.log(
                                "Asdasdasdaakjsdnas",
                                config.Back_URL + "/" + Formdata.companylogo,
                                Formdata.companylogo
                              )}
                              <img
                                src={
                                  CompanyLogoPreview
                                    ? CompanyLogoPreview
                                    : Formdata.companylogo
                                      ? config.Back_URL +
                                      "/" +
                                      Formdata.companylogo
                                      : dummy
                                }
                                className="subd_logo lightimg"
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                NFT Category Title
                              </span>
                              <input
                                type="text"
                                id="categoryTittle"
                                value={Formdata.categoryTittle}
                                className="edit_inputs_align"
                                placeholder="Enter NFT Category Title"
                                name="categoryTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Name
                              </span>
                              {/* Template */}
                              {/* <div className="row">
                                                            <div className="col-md-6">
                                                                <p>sadasdasdsadsadsadasdsad</p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <p>sadasdasdsadsadsadasdsad</p>
                                                            </div>
                                                        </div> */}
                              <input
                                name="NameField"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="NameField"
                                placeholder="Name of Pet *"
                                value={Formdata.NameField}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {/* {Formdata.NameFieldStatus == true ?
                                                            <input class="form-check-input" type="checkbox" value={Formdata.NameFieldStatus} id="NameFieldStatus" onClick={(e) => { CheckBoxSelect(e) }} checked="checked" />
                                                            :
                                                            <input class="form-check-input" type="checkbox" value={Formdata.NameFieldStatus} id="NameFieldStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                        } */}
                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Date of Birth
                              </span>
                              {/* Template */}
                              <input
                                name="DobField"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="DobField"
                                placeholder="Date of Birth *"
                                value={Formdata.DobField}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.DobFieldStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.DobFieldStatus}
                                  id="DobFieldStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                  checked="checked"
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.DobFieldStatus}
                                  id="DobFieldStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}
                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                weight
                              </span>
                              {/* Template */}
                              <input
                                name="Weight"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="Weight"
                                placeholder="Weight *"
                                value={Formdata.Weight}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.WeightStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.WeightStatus}
                                  id="WeightStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                  checked="checked"
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.WeightStatus}
                                  id="WeightStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}
                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                height
                              </span>
                              {/* Template */}
                              {console.log("asdasddasdas", Formdata)}
                              <input
                                name="Height"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="Height"
                                placeholder="Height *"
                                value={Formdata.Height}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.HeightStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.HeightStatus}
                                  id="HeightStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                  checked="checked"
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.HeightStatus}
                                  id="HeightStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}

                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                vaccine
                              </span>
                              {/* Template */}
                              <input
                                name="vaccine"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="vaccine"
                                placeholder="Vaccine *"
                                value={Formdata.vaccine}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.VaccineStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.VaccineStatus}
                                  id="VaccineStatus"
                                  checked="checked"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.VaccineStatus}
                                  id="VaccineStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}
                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                mail
                              </span>
                              {/* Template */}
                              <input
                                name="Mail"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="Mail"
                                placeholder="Owner Contact Email *"
                                value={Formdata.Mail}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.MailStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.MailStatus}
                                  id="MailStatus"
                                  checked="checked"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.MailStatus}
                                  id="MailStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}
                              <br />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Discription
                              </span>
                              {/* Template */}
                              <input
                                name="Discription"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="Discription"
                                placeholder="Likes* (7 words to describe)"
                                value={Formdata.Discription}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              {/* 
                                                        {Formdata.DiscriptionStatus == true ?
                                                            <input class="form-check-input" type="checkbox" value={Formdata.DiscriptionStatus}
                                                                checked="checked" id="DiscriptionStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                            :
                                                            <input class="form-check-input" type="checkbox" value={Formdata.DiscriptionStatus} id="DiscriptionStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                        } */}
                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Gender Drop down
                              </span>
                              {/* Template */}
                              <input
                                name="GenderDropDown"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="GenderDropDown"
                                placeholder="Gender*"
                                value={Formdata.GenderDropDown}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.GenderStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.GenderStatus}
                                  checked="checked"
                                  id="GenderStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.GenderStatus}
                                  id="GenderStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}
                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Bread Dropdown
                              </span>
                              {/* Template */}
                              <input
                                name="BreadDropDown"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="BreadDropDown"
                                placeholder="Breed *"
                                value={Formdata.BreadDropDown}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.BreadStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.BreadStatus}
                                  id="BreadStatus"
                                  checked="checked"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.BreadStatus}
                                  id="BreadStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}
                              <br />
                            </div>
                            <div className="d-sm-flex checkdiv campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Chip
                              </span>
                              {/* Template */}
                              <input
                                name="Chip"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="Chip"
                                placeholder="Chip # (Optional)"
                                value={Formdata.Chip}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {Formdata.ChipStatus == true ? (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.ChipStatus}
                                  checked="checked"
                                  id="ChipStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              ) : (
                                <input
                                  class="form-check-input extrachk"
                                  type="checkbox"
                                  value={Formdata.ChipStatus}
                                  id="ChipStatus"
                                  onClick={(e) => {
                                    CheckBoxSelect(e);
                                  }}
                                />
                              )}
                              <br />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                number
                              </span>
                              {/* Template */}
                              <input
                                name="ContactNum"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="ContactNum"
                                placeholder="Mobile Number *"
                                value={Formdata.ContactNum}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              {/* 
                                                        {Formdata.ContactNumStatus == true ?
                                                            <input class="form-check-input" type="checkbox" value={Formdata.ContactNumStatus} checked="checked" id="ContactNumStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                            :
                                                            <input class="form-check-input" type="checkbox" value={Formdata.ContactNumStatus} id="ContactNumStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                        } */}
                              <br />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                File
                              </span>
                              {/* Template */}
                              <input
                                name="FileField"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="FileField"
                                placeholder="Your Pet’s Photo *"
                                value={Formdata.FileField}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {/* {Formdata.FileStatus == true ?
                                                            <input class="form-check-input" type="checkbox" value={Formdata.FileStatus} checked="checked" id="FileStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                            :
                                                            <input class="form-check-input" type="checkbox" value={Formdata.FileStatus} id="FileStatus" onClick={(e) => { CheckBoxSelect(e) }} />} */}
                              <br />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                File Discription
                              </span>
                              {/* Template */}
                              <input
                                name="FileDiscriptionField"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="FileDiscriptionField"
                                placeholder="Please Ensure a Front Facing Photo Image Dimension (jpg, jpeg, png (xx . X xx)"
                                value={Formdata.FileDiscriptionField}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {/* {Formdata.FileDiscriptionStatus == true ?
                                                            <input class="form-check-input" type="checkbox" value={Formdata.FileDiscriptionStatus} checked="checked" id="FileDiscriptionStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                            :
                                                            <input class="form-check-input" type="checkbox" value={Formdata.FileDiscriptionStatus} id="FileDiscriptionStatus" onClick={(e) => { CheckBoxSelect(e) }} />
                                                        } */}
                              <br />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                check box content 1
                              </span>
                              {/* Template */}
                              <input
                                name="vaccine"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="CheckBox1"
                                placeholder="I agree to all statements included in Terms of Conditions and Use"
                                value={Formdata?.CheckBox1}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {/* {Formdata.CheckBox1Status == true ?
                                                            <input class="form-check-input" type="checkbox" value={Formdata.CheckBox1Status} checked="checked" id="CheckBox1Status" onClick={(e) => { CheckBoxSelect(e) }} />
                                                            :
                                                            <input class="form-check-input" type="checkbox" value={Formdata.CheckBox1Status} id="CheckBox1Status" onClick={(e) => { CheckBoxSelect(e) }} />
                                                        } */}
                              <br />
                            </div>

                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                check box content 2
                              </span>
                              {/* Template */}
                              <input
                                name="CheckBox2"
                                className="edit_inputs_align"
                                onChange={(e) => OnChangehandle(e)}
                                id="CheckBox2"
                                placeholder="I want free 30 days coverage for my pet Insurance Coverage Details and Conditions"
                                value={Formdata?.CheckBox2}
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />

                              {/* {Formdata.CheckBox2Status == true ?
                                                            <input class="form-check-input" type="checkbox" value={Formdata.CheckBox2Status} checked="checked" id="CheckBox2Status" onClick={(e) => { CheckBoxSelect(e) }} />
                                                            :
                                                            <input class="form-check-input" type="checkbox" value={Formdata.CheckBox2Status} id="CheckBox2Status" onClick={(e) => { CheckBoxSelect(e) }} />
                                                        } */}
                              <br />
                            </div>
                            {console.log("qeqweasdasas", inputList)}
                            {Formdata?.GenderStatus == false && (
                              <div className="d-sm-flex dropdown1  campaignmdl_inps">
                                <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Gender
                                </span>

                                <div className="form-group unlock_sec mb-0 mt-0">
                                  {inputList?.map((x, i) => {
                                    return (
                                      <div className="box">
                                        <div className="row">
                                          <div className="col-md-7 col-7 col-sm-8">
                                            <input
                                              className="form-control primary_inp mb-0"
                                              name="value"
                                              placeholder="Enter value"
                                              value={x.value}
                                              onChange={(e) =>
                                                handleInputChange(e, i)
                                              }
                                            />
                                          </div>

                                          <div className="col-md-5 col-5 col-sm-4">
                                            <div className="btn-box d-flex mb-2">
                                              {inputList?.length !== 1 && (
                                                <button
                                                  className="mr10 mt-0 w-100 btn create_btn"
                                                  onClick={() =>
                                                    handleRemoveClick(i)
                                                  }
                                                >
                                                  -
                                                </button>
                                              )}
                                              {inputList?.length - 1 === i && (
                                                <button
                                                  className="btn create_btn mt-0 w-100"
                                                  onClick={() =>
                                                    handleAddClick()
                                                  }
                                                >
                                                  +
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            {Formdata?.BreadStatus == false && (
                              <div className="d-sm-flex dropdown1 campaignmdl_inps mb-0">
                                <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Bread
                                </span>

                                <div className="form-group unlock_sec mt-0">
                                  {inputList2?.map((x, i) => {
                                    return (
                                      <div className="box">
                                        <div className="row  align-items-center">
                                          <div className="col-md-7 col-7 col-sm-8">
                                            <input
                                              className="form-control primary_inp mb-2"
                                              name="value"
                                              placeholder="Enter value"
                                              value={x.value}
                                              onChange={(e) =>
                                                handleInputChange2(e, i)
                                              }
                                            />
                                          </div>

                                          <div className="col-md-5 col-5 col-sm-4">
                                            <div className="btn-box d-flex mb-2">
                                              {inputList2?.length !== 1 && (
                                                <button
                                                  className="mr10 mt-0 btn w-100 create_btn"
                                                  onClick={() =>
                                                    handleRemoveClick2(i)
                                                  }
                                                >
                                                  -
                                                </button>
                                              )}
                                              {inputList2.length - 1 === i && (
                                                <button
                                                  className="btn create_btn w-100 mt-0"
                                                  onClick={() =>
                                                    handleAddClick2()
                                                  }
                                                >
                                                  +
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                            {Formdata?.VaccineStatus == false && (
                              <div className="d-sm-flex dropdown1  campaignmdl_inps mb-0">
                                <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Vaccine
                                </span>

                                <div className="form-group unlock_sec mt-0">
                                  {inputList3.map((x, i) => {
                                    return (
                                      <div className="box">
                                        <div className="row">
                                          <div className="col-md-7 col-7 col-sm-8">
                                            <input
                                              className="form-control primary_inp mb-2"
                                              name="value"
                                              placeholder="Enter value"
                                              value={x.value}
                                              onChange={(e) =>
                                                handleInputChange3(e, i)
                                              }
                                            />
                                          </div>

                                          <div className="col-md-5 col-5 col-sm-4">
                                            <div className="btn-box d-flex mb-2">
                                              {inputList3.length !== 1 && (
                                                <button
                                                  className="mr10 mt-0 btn w-100 create_btn"
                                                  onClick={() =>
                                                    handleRemoveClick3(i)
                                                  }
                                                >
                                                  -
                                                </button>
                                              )}
                                              {inputList3.length - 1 === i && (
                                                <button
                                                  className="btn create_btn w-100 mt-0"
                                                  onClick={() =>
                                                    handleAddClick3()
                                                  }
                                                >
                                                  +
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            )}

                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Number of Treasure
                              </span>
                              <input
                                type="number"
                                id="NoOfTreasure"
                                className="edit_inputs_align"
                                placeholder="Enter a Number"
                                name="NoOfTreasure"
                                onKeyDown={handleKeyDown}
                                onWheel={(e) => e.target.blur()}
                                value={Formdata.NoOfTreasure}
                                onChange={(e) => OnChangehandle(e)}
                              />
                              <br />
                            </div>
                            {ValidationError &&
                              ValidationError.NoOfTreasure && (
                                <span className="validation_txt text-center">
                                  {ValidationError &&
                                    ValidationError.NoOfTreasure}
                                </span>
                              )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Mystery NFT
                              </span>
                              <input
                                type="number"
                                id="mysteryNft"
                                className="edit_inputs_align"
                                placeholder="Enter a Number"
                                name="mysteryNft"
                                onKeyDown={handleKeyDown}
                                onWheel={(e) => e.target.blur()}
                                value={Formdata.mysteryNft}
                                onChange={(e) => OnChangehandle(e)}
                              />
                              <br />
                            </div>
                            {ValidationError && ValidationError.mysteryNft && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.mysteryNft}
                              </span>
                            )}
                            <div>
                              <button
                                className="graybtn btn mr-2 mb-2"
                                onClick={() => NftValidateAndCheck("Treasure")}
                              >
                                Choose Treasure NFT
                              </button>
                              <button
                                className="graybtn btn mr-2 mb-2"
                                onClick={() => NftValidateAndCheck("Mystry")}
                              >
                                Choose Mystery NFT
                              </button>
                              <button
                                className="graybtn btn mr-2 mb-2"
                                onClick={() => ChoosePlaces("Mystry")}
                              >
                                Choose Mystery place
                              </button>
                              {Wallet_Details.Reset == "Update" && (
                                <button
                                  className="graybtn btn mr-2 mb-2"
                                  onClick={() => ReSetNft()}
                                >
                                  Reset
                                </button>
                              )}
                            </div>
                            {Wallet_Details.SelectedFinal == "true" &&
                              Wallet_Details.SelectMystry.length > 0 && (
                                <>
                                  <div className="campaignmodal_cards mys_show_card">
                                    {console.log(
                                      "Wallet_Details.SelectMystry",
                                      Wallet_Details.SelectMystry
                                    )}
                                    {Wallet_Details.SelectMystry.length > 0 &&
                                      Wallet_Details.SelectMystry.map(
                                        (item, index) => {
                                          return (
                                            <div class="card sub_domaincard">
                                              <img
                                                src={require("../assets/images/faces/image2.png")}
                                                class="card-img-top subdomain_cardimg blur_img"
                                                alt=""
                                              />
                                              <div class="card-body">
                                                <p className="subd_domaincardtxt">
                                                  Choose{" "}
                                                </p>
                                                <button className="subd_clctnowbtn">
                                                  mystery place
                                                </button>
                                                <div className="check_input">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    // checked={Wallet_Details?.MyItemNFTsMystry?.some(it => it == NFTDetails._id)}
                                                    //{NFTDetails?.check}
                                                    onClick={(e) =>
                                                      IndexPositionsMystry(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                  <button
                                    className="subd_clctnowbtn"
                                    onClick={() => {
                                      SelectedFinal();
                                    }}
                                  >
                                    confirm
                                  </button>
                                </>
                              )}

                            {Wallet_Details.FinalNfts.length > 0 && (
                              <>
                                <div className="campaignmodal_cards mys_show_card">
                                  {console.log(
                                    "Wallet_Details.SelectMystry",
                                    Wallet_Details.FinalNfts
                                  )}
                                  {Wallet_Details.FinalNfts.length > 0 &&
                                    Wallet_Details.FinalNfts.map(
                                      (item, index) => {
                                        return (
                                          <div class="card sub_domaincard">
                                            {item.image != "" &&
                                              (item.image.split(".").pop() ==
                                                "mp4" ? (
                                                <video
                                                  id="my-video"
                                                  class="img-fluid"
                                                  autoPlay
                                                  playsInline
                                                  loop
                                                  muted
                                                  preload="auto"
                                                  controls
                                                  disablePictureInPicture
                                                  controlsList="nodownload"
                                                >
                                                  <source
                                                    src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                              ) : (
                                                <img
                                                  src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                                  alt="Collections"
                                                  className="img-fluid mys_img_show"
                                                />
                                              ))}
                                            {/* <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg blur_img" alt="" /> */}
                                            {/* <div class="card-body text-center">
                                                                                <p className='subd_domaincardtxt'>Choose </p>
                                                                            </div> */}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </>
                            )}
                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Hero Banner Title
                              </span>
                              <input
                                type="text"
                                id="bannerTittle"
                                value={Formdata.bannerTittle}
                                className="edit_inputs_align"
                                placeholder="Collect Limited Editions of Halloween NFTs"
                                name="bannerTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Hero Banner Description
                              </span>
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  {/* <CKEditor
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.bannerdiscription
                                        )}
                                      </p>
                                    }
                                    id="bannerdiscription"
                                    onChange={(e) => EditorBannerDiscription(e)}
                                  /> */}
                                  {/* <MatEditor outdata={(e) => EditorBannerDiscription(e)} sendata={Formdata.bannerdiscription}/> */}
                                  <ReactQuill
                                value={Formdata.bannerdiscription || BannerDiscription}
                               onChange={value => EditorBannerDiscription(value)}
                                 modules={modules}
                                 theme="snow"
                               /> 
                                      {/* <ReactSummernote
                                        // children="<p>water</p>"
                                        children={ReactHTMLParser(Formdata.bannerdiscription)}
                                        options={{
                                            //   lang: 'ru-RU',
                                            height: 400,
                                            dialogsInBody: true,
                                            toolbar: [
                                                ["style", ["style"]],
                                                ["font", ["bold", "underline", "clear"]],
                                                ["fontname", ["fontname"]],
                                                ["para", ["ul", "ol", "paragraph"]],
                                                ["table", ["table"]],
                                                ["insert", ["link", "picture", "video"]],
                                                ["view", ["fullscreen", "codeview"]],
                                            ],
                                        }}
                                        id="bannerdiscription"
                                    onChange={(e) => EditorBannerDiscription(e)}
                                    /> */}
                                </>
                              ) : (
                                // <CKEditor
                                //   initData={
                                //     <p>
                                //       {ReactHTMLParser(
                                //         Formdata.bannerdiscription
                                //       )}
                                //     </p>
                                //   }
                                //   id="bannerdiscription"
                                //   onChange={(e) => EditorBannerDiscription(e)}
                                // />
                                // <MatEditor outdata={(e) => EditorBannerDiscription(e)} sendata={Formdata.bannerdiscription}/>
                                <ReactQuill
                                value={Formdata.bannerdiscription || BannerDiscription}
                               onChange={value => EditorBannerDiscription(value)}
                                 modules={modules}
                                 theme="snow"
                               />   
                                 )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Hero Banner
                              </span>

                              <div className="cmpny_logo_flex">
                                <input
                                  type="text"
                                  id="PackName"
                                  className="edit_inputs_align  cmpny_logo"
                                  placeholder="Select File"
                                  name="wallet address"
                                  disabled
                                />
                                <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                  <input
                                    type="file"
                                    id="companylogo"
                                    className="edit_inputs_align width_algd campaign_fileinp"
                                    placeholder="Plan Name*"
                                    name="companylogo"
                                    onChange={(e) => HandleBannerImage(e)}
                                  />
                                  <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="d-sm-flex campaignmdl_inps">
                              <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                                <p className="">Image Dimension</p>
                                <p className="">(min 700px, 700px)</p>
                              </div>
                              {console.log("asdasdasda", Formdata)}
                              <img
                                className="campaign_bannerimg"
                                src={
                                  bannerImagePreview
                                    ? bannerImagePreview
                                    : Formdata.bannerImage
                                      ? config.Back_URL +
                                      "/" +
                                      Formdata.bannerImage
                                      : dummy
                                }
                              />
                            </div>

                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Title
                              </span>
                              <input
                                type="text"
                                id="middlebannertittle"
                                value={Formdata.middlebannertittle}
                                className="edit_inputs_align"
                                placeholder="Join Us In The Treasure Hunt Collect You NFT Token"
                                name="middlebannertittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>

                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Description
                              </span>
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  {/* {Formdata.middlebannerdescription && */}
                                  {/* <CKEditor
                                    className="ckedit_hei"
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.middlebannerdescription
                                        )}
                                      </p>
                                    }
                                    id="middlebannerdescription"
                                    onChange={(e) =>
                                      Editormiddlebannerdescription(e)
                                    }
                                  /> */}
                                    {/* <MatEditor outdata={(e) => Editormiddlebannerdescription(e)} sendata={Formdata.middlebannerdescription}/> */}
                                    <ReactQuill
                                value={Formdata.middlebannerdescription || middlebannerdescription}
                               onChange={value => Editormiddlebannerdescription(value)}
                                 modules={modules}
                                 theme="snow"
                               />                                     
                                </>
                              ) : (
                                // <CKEditor
                                //   className="ckedit_hei"
                                //   initData={
                                //     <p>
                                //       {ReactHTMLParser(
                                //         Formdata.middlebannerdescription
                                //       )}
                                //     </p>
                                //   }
                                //   id="middlebannerdescription"
                                //   onChange={(e) =>
                                //     Editormiddlebannerdescription(e)
                                //   }
                                // />
                                // <MatEditor outdata={(e) => Editormiddlebannerdescription(e)} sendata={Formdata.middlebannerdescription}/>
                                <ReactQuill
                                value={Formdata.middlebannerdescription || middlebannerdescription}
                               onChange={value => Editormiddlebannerdescription(value)}
                                 modules={modules}
                                 theme="snow"
                               /> 
                              )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Title Details
                              </span>
                              <input
                                type="text"
                                id="middlebannerdescriptionTittle"
                                value={Formdata.middlebannerdescriptionTittle}
                                className="edit_inputs_align"
                                placeholder="Collectible Halloween Tokens Only At Univarsal Studios In Sentosa"
                                name="middlebannerdescriptionTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>

                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Description Details
                              </span>
                              {console.log(
                                "Asdasdsa",
                                Formdata.middlebannerdetails
                              )}
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  {/* {Formdata.middlebannerdetails && */}
                                  {/* <CKEditor
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.middlebannerdescription
                                        )}
                                      </p>
                                    }
                                    id="middlebannerdetails"
                                    onChange={(e) =>
                                      Editormiddlebannerdetails(e)
                                    }
                                  /> */}
                                  {/* <MatEditor outdata={(e) => Editormiddlebannerdetails(e)} sendata={Formdata.middlebannerdescription}/> */}
                                  <ReactQuill
                                value={Formdata.middlebannerdescription || middlebannerdetails}
                               onChange={value => Editormiddlebannerdetails(value)}
                                 modules={modules}
                                 theme="snow"
                               /> 
                                </>
                              ) : (
                                // <CKEditor
                                //   className="ckedit_hei"
                                //   initData={
                                //     <p>
                                //       {ReactHTMLParser(
                                //         Formdata.middlebannerdescription
                                //       )}
                                //     </p>
                                //   }
                                //   id="middlebannerdescription"
                                //   onChange={(e) => Editormiddlebannerdetails(e)}
                                // />
                                // <MatEditor outdata={(e) => Editormiddlebannerdetails(e)} sendata={Formdata.middlebannerdescription}/>
                                <ReactQuill
                                value={Formdata.middlebannerdescription || middlebannerdetails}
                               onChange={value => Editormiddlebannerdetails(value)}
                                 modules={modules}
                                 theme="snow"
                               />  
                              )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Event Details
                              </span>
                              {console.log("Asdasdsa", Formdata.EventDeatils)}
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  {/* {Formdata.middlebannerdetails && */}
                                  {/* <CKEditor
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.middlebannerdescription
                                        )}
                                      </p>
                                    }
                                    id="EventDeatils"
                                    onChange={(e) => EditorEventDeatils(e)}
                                  /> */}
                                   {/* <MatEditor outdata={(e) => EditorEventDeatils(e)} sendata={Formdata.middlebannerdescription}/> */}
                                   <ReactQuill
                                value={Formdata.middlebannerdescription || EventDeatils}
                               onChange={value => EditorEventDeatils(value)}
                                 modules={modules}
                                 theme="snow"
                               />    
                                </>
                              ) : (
                                // <CKEditor
                                //   className="ckedit_hei"
                                //   initData={
                                //     <p>
                                //       {ReactHTMLParser(Formdata.EventDeatils)}
                                //     </p>
                                //   }
                                //   id="EventDeatils"
                                //   onChange={(e) => EditorEventDeatils(e)}
                                // />
                                // <MatEditor outdata={(e) => EditorEventDeatils(e)} sendata={Formdata.EventDeatils}/>
                                <ReactQuill
                                value={Formdata.EventDeatils || EventDeatils}
                               onChange={value => EditorEventDeatils(value)}
                                 modules={modules}
                                 theme="snow"
                               />    
                               
                              )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner
                              </span>
                              <div className="cmpny_logo_flex">
                                <input
                                  type="text"
                                  id="PackName"
                                  className="edit_inputs_align  cmpny_logo"
                                  placeholder="Select File"
                                  name="wallet address"
                                  disabled
                                />
                                <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                  <input
                                    type="file"
                                    id="companylogo"
                                    className="edit_inputs_align width_algd campaign_fileinp"
                                    placeholder="Plan Name*"
                                    name="companylogo"
                                    onChange={(e) => HandleMiddleImage(e)}
                                  />
                                  <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                                <p className="">Image Dimension</p>
                                <p className="">(min 350px, 350px)</p>
                              </div>
                              <img
                                className="subd_logomodal"
                                src={
                                  middlebannerPreview
                                    ? middlebannerPreview
                                    : Formdata.middlebannerimage
                                      ? config.Back_URL +
                                      "/" +
                                      Formdata.middlebannerimage
                                      : dummy
                                }
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Top Winners Title
                              </span>
                              <input
                                type="text"
                                id="TopWinnerTittle"
                                value={Formdata.TopWinnerTittle}
                                className="edit_inputs_align"
                                placeholder="Top Winners this Week"
                                name="TopWinnerTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Background Colours
                              </span>
                              <div>
                                <div className="campaignmdl_cltita">
                                  <div>
                                    <input
                                      className="mr-3"
                                      type="color"
                                      id="backgroundcolor"
                                      name="backgroundcolor"
                                      value={Formdata.backgroundcolor}
                                      onChange={(e) => OnChangehandle(e)}
                                    />
                                    <span className="cambaignmdl_italic">
                                      Click to choose your color
                                    </span>
                                  </div>
                                  <span className="cambaignmdl_italic">
                                    Select the website background color
                                  </span>
                                </div>
                                {/* <div className='campaignmdl_checks'>
                                                <input type="radio" id="predefined" name="fav_language" value="predefined" />
                                                <label className='m-0 mr-2' for="predefined">Predefined images</label><br />
                                                <input type="radio" id="custom" name="fav_language" value="custom" />
                                                <label className='m-0' for="custom">Custom images</label>
                                            </div> */}
                                {/* <div className='campaignmdl_xamplediv'></div> */}
                              </div>
                            </div>
                            <div className="campaignmdl_triplebtns">
                              {Wallet_Details.Update == "Create" && (
                                <button
                                  className="admin_mdl_red_btn admn_img_upld_btn"
                                  onClick={() => {
                                    SaveEvent();
                                  }}
                                >
                                  Save
                                </button>
                              )}
                              {Wallet_Details.Update == "Update" && (
                                <button
                                  className="admin_mdl_red_btn admn_img_upld_btn"
                                  onClick={() => {
                                    SaveEvent();
                                  }}
                                >
                                  Update
                                </button>
                              )}
                              {/* <Link to={{ pathname: `/Preview`, state: Formdata, location: "collection" }} target={"_blank"} > */}
                              <button
                                className="admin_mdl_red_btn admn_img_upld_btn"
                                onClick={() => PreviewContent()}
                              >
                                Preview
                              </button>
                              {/* </Link> */}
                              {Wallet_Details.Update == "Create" && (
                                <button
                                  className="admin_mdl_red_btn admn_img_upld_btn"
                                  onClick={() => PublishEvent()}
                                >
                                  publish
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          classes.pageHeader +
                          " inner_pageheader items_header my_items"
                        }
                      >
                        <div className="inner_pagh_bg_1">
                          <div className="container container_full">
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <nav className="masonry_tab_nav mt-4 mb-3 items_tab_outer">
                                  <div
                                    className="nav nav-tabs masonry_tab primary_tab items_tab justify-content-center items_tab_new pb-0 pl-0"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <a
                                      onClick={() => {
                                        setTabName("created");
                                      }}
                                      className="nav-link"
                                      id="created-tab"
                                      data-toggle="tab"
                                      href="#created"
                                      role="tab"
                                      aria-controls="created"
                                      aria-selected="false"
                                    >
                                      <div className="tab_head">Created</div>
                                    </a>
                                    <a
                                      onClick={() => {
                                        MyItemTab("Ticket");
                                      }}
                                      className="nav-link"
                                      id="Ticket-tab"
                                      data-toggle="tab"
                                      href="#Ticket"
                                      role="tab"
                                      aria-controls="Ticket"
                                      aria-selected="false"
                                    >
                                      <div className="tab_head">Ticket</div>
                                    </a>
                                  </div>
                                </nav>
                                <div
                                  className="tab-content explore_tab_content  myitemsdf"
                                  id="nav-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id={TabName}
                                    role="tabpanel"
                                    aria-labelledby={`${TabName}-tab`}
                                  >
                                    <div className="proposal_panel_overall">
                                      {TabName === "created" ? (
                                        <>
                                          <MyItemCard />
                                          <div className="row">
                                            {/* <Card /> */}
                                          </div>
                                          {MyItemNFTs["created"] &&
                                            MyItemNFTs["created"]?.onmore && (
                                              <div className="col-12 pb-5 text-center pt-4">
                                                <button
                                                  className="create_btn"
                                                  onClick={() =>
                                                    MyItemTab(
                                                      "created",
                                                      Wallet_Details.UserAccountAddr,
                                                      null,
                                                      MyItemNFTs["created"]
                                                        .page + 1
                                                    )
                                                  }
                                                >
                                                  Load more items
                                                </button>
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          <Ticketss />
                                          <div className="row">
                                            {/* <Card /> */}
                                          </div>
                                          {MyItemNFTs["Ticket"] &&
                                            MyItemNFTs["Ticket"].onmore && (
                                              <div className="col-12 pb-5 text-center pt-4">
                                                <button
                                                  className="create_btn"
                                                  onClick={() =>
                                                    MyItemTab(
                                                      "Ticket",
                                                      Wallet_Details.UserAccountAddr,
                                                      null,
                                                      MyItemNFTs["Ticket"]
                                                        .page + 1
                                                    )
                                                  }
                                                >
                                                  Load more items
                                                </button>
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <button
                              className="graybtn btn mr-2 mb-2"
                              onClick={() => setshowedd("true")}
                            >
                              Back
                            </button>
                            <button
                              className="graybtn btn mr-2 mb-2"
                              onClick={() => SaveNft()}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div class="modal-dialog">
                  {/* sonia */}
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title" id="staticBackdropLabel">
                        Create / Update Campaign Page
                      </h5>
                      <button
                        type="button"
                        class="close"
                        id="addcampaingModal_close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    {Showed == "true" ? (
                      <div class="modal-body">
                        {console.log(":asdsadasda", Formdata)}
                        <div className="row">
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Name of Campaign
                              </span>
                              <input
                                type="text"
                                id="campaignname"
                                className="edit_inputs_align"
                                placeholder="Campaign Name*"
                                name="campaignname"
                                value={Formdata.campaignname}
                                onChange={(e) => OnChangehandle(e)}
                              />
                              <br />
                            </div>
                            {ValidationError &&
                              ValidationError.campaignname && (
                                <span className="validation_txt text-center">
                                  {ValidationError &&
                                    ValidationError.campaignname}
                                </span>
                              )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Campaign Code
                              </span>
                              <input
                                type="text"
                                id="campaigncode"
                                className="edit_inputs_align"
                                placeholder="Campaign Code"
                                name="campaigncode"
                                value={Formdata.campaigncode}
                                disabled
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Subdomain
                              </span>
                              <div className="campaignmdl_inpdbl">
                                <input
                                  type="text"
                                  id="domainname"
                                  className="edit_inputs_align"
                                  placeholder="Insertname"
                                  name="domainname"
                                  value={Formdata.domainname}
                                  onChange={(e) => OnChangehandle(e)}
                                />
                                <p className="ml-1">.lazyminter.com</p>
                              </div>
                              <br />
                            </div>
                            {ValidationError && ValidationError.domainname && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.domainname}
                              </span>
                            )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Redirect
                              </span>
                              <input
                                type="text"
                                id="redirect"
                                className="edit_inputs_align"
                                placeholder="www.uss.com"
                                name="redirect"
                                value={Formdata.redirect}
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Template
                              </span>
                              {console.log("Asdasdasdasd", Formdata.template)}
                              <Select
                                className="yes1 form-control selxet_app campaign_select"
                                onChange={(e) => selectStandard(e)}
                                options={optionsmodal}
                                // menuIsOpen={true}
                                label="Single select"
                                isSearchable={false}
                                styles={stylesgraybg}
                                classNamePrefix="react-select"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              <br />
                            </div>
                            {ValidationError && ValidationError.template && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.template}
                              </span>
                            )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Select Network
                              </span>
                              {console.log("Asdasdasdasd", Formdata.template)}
                              <Select
                                className="yes1 form-control selxet_app campaign_select"
                                onChange={(e) => selectChange(e)}
                                options={networkoption}
                                // menuIsOpen={true}
                                label="Single select"
                                isSearchable={false}
                                styles={stylesgraybg}
                                classNamePrefix="react-select"
                                formControlProps={{
                                  fullWidth: true,
                                }}
                              />
                              <br />
                            </div>
                            {ValidationError && ValidationError.template && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.template}
                              </span>
                            )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Country
                              </span>
                              <input
                                type="text"
                                id="country"
                                value={Formdata.country}
                                className="edit_inputs_align"
                                placeholder="Singapore"
                                name="country"
                                onChange={(e) => OnChangehandle(e)}
                              />
                              <br />
                            </div>
                            {ValidationError && ValidationError.country && (
                              <span className="validation_txt text-center">
                                {ValidationError && ValidationError.country}
                              </span>
                            )}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Company Logo
                              </span>

                              <div className="cmpny_logo_flex">
                                <input
                                  type="text"
                                  id="PackName"
                                  className="edit_inputs_align  cmpny_logo"
                                  placeholder="Select File"
                                  name="wallet address"
                                  disabled
                                />
                                <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                  <input
                                    type="file"
                                    id="companylogo"
                                    className="edit_inputs_align width_algd campaign_fileinp"
                                    placeholder="Plan Name*"
                                    name="companylogo"
                                    onChange={(e) => HandleCompanyLogo(e)}
                                  />
                                  <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                                <p className="">Image Dimension</p>
                                <p className="">(min 70px, 100px)</p>
                              </div>
                              {console.log(
                                "Asdasdasdaakjsdnas",
                                config.Back_URL + "/" + Formdata.companylogo,
                                Formdata.companylogo
                              )}
                              <img
                                src={
                                  CompanyLogoPreview
                                    ? CompanyLogoPreview
                                    : Formdata.companylogo
                                      ? config.Back_URL +
                                      "/" +
                                      Formdata.companylogo
                                      : dummy
                                }
                                className="subd_logo lightimg"
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                NFT Category Title
                              </span>
                              <input
                                type="text"
                                id="categoryTittle"
                                value={Formdata.categoryTittle}
                                className="edit_inputs_align"
                                placeholder="Enter NFT Category Title"
                                name="categoryTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Title
                              </span>
                              <input
                                type="text"
                                id="middlebannertittle"
                                value={Formdata.middlebannertittle}
                                className="edit_inputs_align"
                                placeholder="Join Us In The Treasure Hunt Collect You NFT Token"
                                name="middlebannertittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Points Name
                              </span>
                              <input
                                type="text"
                                id="PDUPoints"
                                value={Formdata.PDUPoints}
                                className="edit_inputs_align"
                                placeholder="Enter Points Name"
                                name="PDUPoints"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>

                            <div className="d-sm-flex dropdown1  campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Levels
                              </span>
                              {console.log("setInputListLevelsJorneeeeee", JorneyPosition)}
                              <div className="form-group unlock_sec mb-0 mt-0">
                                {inputListJourney?.map((x, i) => {
                                  return (
                                    <div className="box">
                                      <div className="row">
                                        <div className="col-md-7 col-7 col-sm-8 mb-2">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="Journey"
                                            placeholder="Enter Journey"
                                            value={x.Journey}
                                            onChange={(e) =>
                                              handleInputChangeJourney(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="col-md-7 col-7 col-sm-8 mb-2">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="Name"
                                            placeholder="Enter Name"
                                            value={x.Name}
                                            onChange={(e) =>
                                              handleInputChangeJourney(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="col-md-7 col-7 col-sm-8 mb-2">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="Description"
                                            placeholder="Enter Description"
                                            value={x.Description}
                                            onChange={(e) =>
                                              handleInputChangeJourney(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="col-md-7 col-7 col-sm-8 mb-2">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="NofNFT"
                                            placeholder="Number of NFT"
                                            value={x.NofNFT}
                                            onChange={(e) =>
                                              handleInputChangeJourney(e, i)
                                            }
                                          />
                                        </div>
                                        {/* <div className="col-md-7 col-7 col-sm-8 mb-2">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="NftPoint"
                                            placeholder="Enter Each Nft Point"
                                            value={x.NftPoint}
                                            onChange={(e) =>
                                              handleInputChangeJourney(e, i)
                                            }
                                          />
                                        </div> */}
                                        <div className="col-md-7 col-7 col-sm-8 mb-2">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="MysteryNFT"
                                            placeholder="Enter Mystery NFT"
                                            value={x.MysteryNFT}
                                            onChange={(e) =>
                                              handleInputChangeJourney(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="col-md-7 col-7 col-sm-8 mb-2">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            type="text"

                                            placeholder="Select file"

                                            disabled
                                          // style={{ display: 'none' }} 
                                          />

                                          <div className="mt-2">
                                            <input
                                              className="form-control primary_inp mb-0"
                                              type="file"
                                              id="journeyImg"
                                              name="journeyImg"
                                              placeholder="Enter Mystery NFT"
                                              onChange={(e) => JorneyImages(e, i)}
                                              ref={fileInputRef}
                                            // style={{ display: 'none' }} 
                                            />
                                            <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn" onClick={handleButtonClick}>
                                              Upload
                                            </button>
                                          </div>
                                        </div>
                                        {/* <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                  <input
                                    type="file"
                                    id="journeyImg"
                                    className="form-control primary_inp mb-0"                                    placeholder="journey Img*"
                                    name="journeyImg"
                                    onChange={(e) => JorneyImages(e,i)}
                                  />
                                  <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                    Upload
                                  </button>
                                </div> */}
                                        <div className="col-md-5 col-5 col-sm-4">
                                          <div className="btn-box d-flex mb-2">
                                            {inputListJourney?.length !== 1 && (
                                              <button
                                                className="mr10 mt-0 w-100 btn create_btn"
                                                onClick={() =>
                                                  handleRemoveClickJourney(i)
                                                }
                                              >
                                                -
                                              </button>
                                            )}
                                            {inputListJourney?.length - 1 ===
                                              i && (
                                                <button
                                                  className="btn create_btn mt-0 w-100"
                                                  onClick={() =>
                                                    handleAddClickJourney(i - 1)
                                                  }
                                                >
                                                  +
                                                </button>
                                              )}

                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>

                            </div>
                            <div className="text-center">
                              <button
                                className="btn create_btn recordlevel mt-0"
                                onClick={() => SetShowValidation()}
                              //onClick={() => NftValidateAndCheck("Treasure")}
                              >
                                Done & Reorder Levels
                              </button></div>
                            <div>
                              <button
                                className="graybtn btn mr-2 mb-2"
                                onClick={() => NftValidateAndCheck("Treasure")}
                              >
                                Choose Treasure NFT
                              </button>
                              <button
                                className="graybtn btn mr-2 mb-2"
                                onClick={() => NftValidateAndCheck("Mystry")}
                              >
                                Choose Mystery NFT
                              </button>
                              <button
                                className="graybtn btn mr-2 mb-2"
                                onClick={() => ChoosePlaces("Mystry")}
                              >
                                Choose Mystery place
                              </button>
                              {Wallet_Details.Reset == "Update" && (
                                <button
                                  className="graybtn btn mr-2 mb-2"
                                  onClick={() => ReSetNft()}
                                >
                                  Reset
                                </button>
                              )}
                              {ShowCustom && (
                                <>
                                  <button
                                    className="graybtn btn mr-2 mb-2"
                                    onClick={() => setAlltrue(true)}
                                  //onClick={() => NftValidateAndCheck("Treasure")}
                                  >
                                    Reorder Levels
                                  </button>
                                  <button
                                    className="graybtn btn mr-2 mb-2"
                                    onClick={() => ReOrderingJorney()}
                                  //onClick={() => NftValidateAndCheck("Treasure")}
                                  >
                                    Reordered
                                  </button>
                                </>
                              )}
                            </div>


                            {Wallet_Details.SelectedFinal == "true" &&
                              Wallet_Details.SelectMystry.length > 0 && (
                                <>
                                  <div className="campaignmodal_cards mys_show_card">
                                    {console.log(
                                      "Wallet_Details.SelectMystry",
                                      Wallet_Details.SelectMystry
                                    )}
                                    {Wallet_Details.SelectMystry.length > 0 &&
                                      Wallet_Details.SelectMystry.map(
                                        (item, index) => {
                                          return (
                                            <div class="card sub_domaincard">
                                              <img
                                                src={require("../assets/images/faces/image2.png")}
                                                class="card-img-top subdomain_cardimg blur_img"
                                                alt=""
                                              />
                                              <div class="card-body">
                                                <p className="subd_domaincardtxt">
                                                  Choose{" "}
                                                </p>
                                                <button className="subd_clctnowbtn">
                                                  mystery place
                                                </button>
                                                <div className="check_input">
                                                  <input
                                                    class="form-check-input"
                                                    type="checkbox"
                                                    // checked={Wallet_Details?.MyItemNFTsMystry?.some(it => it == NFTDetails._id)}
                                                    //{NFTDetails?.check}
                                                    onClick={(e) =>
                                                      IndexPositionsMystry(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                  </div>
                                  <button
                                    className="subd_clctnowbtn"
                                    onClick={() => {
                                      SelectedFinal();
                                    }}
                                  >
                                    confirm
                                  </button>
                                </>
                              )}

                            {Wallet_Details.FinalNfts.length > 0 && (
                              <>
                                <div className="campaignmodal_cards mys_show_card">
                                  {console.log(
                                    "Wallet_Details.SelectMystry",
                                    Wallet_Details.FinalNfts
                                  )}
                                  {Wallet_Details.FinalNfts.length > 0 &&
                                    Wallet_Details.FinalNfts.map(
                                      (item, index) => {
                                        return (
                                          <div class="card sub_domaincard">
                                            {item.image != "" &&
                                              (item.image.split(".").pop() ==
                                                "mp4" ? (
                                                <video
                                                  id="my-video"
                                                  class="img-fluid"
                                                  autoPlay
                                                  playsInline
                                                  loop
                                                  muted
                                                  preload="auto"
                                                  controls
                                                  disablePictureInPicture
                                                  controlsList="nodownload"
                                                >
                                                  <source
                                                    src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                                    type="video/mp4"
                                                  />
                                                </video>
                                              ) : (
                                                <img
                                                  src={`${config.Back_URL}/nftImg/${item.tokenCreator}/${item.image}`}
                                                  alt="Collections"
                                                  className="img-fluid mys_img_show"
                                                />
                                              ))}
                                            {/* <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg blur_img" alt="" /> */}
                                            {/* <div class="card-body text-center">
                                                                            <p className='subd_domaincardtxt'>Choose </p>
                                                                        </div> */}
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </>
                            )}


                            {alltrue === true &&
                              <>{console.log("vanthurururrururiwoow", inputListJourney, Wallet_Details.FinalInputVal, Wallet_Details.JorneyPosition)}
                                {inputListJourney.length > 0 && inputListJourney.map((itemm, index) => {
                                  return (
                                    <div className="d-flex align-items-center mb-3 gap-4">
                                      <p>{itemm.Journey} </p>
                                      <input
                                        class="form-check-input journey_checkbox"
                                        type="checkbox"
                                        onClick={(e) =>
                                          IndexPositionsJorney(
                                            e,
                                            index
                                          )
                                        }
                                      />
                                      {/* <p className="ms-3">Position : {index + 1}</p> */}
                                    </div>
                                  )
                                })}
                              </>
                            }

                          </div>
                          <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="d-sm-flex dropdown1  campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Points
                              </span>

                              <div className="form-group unlock_sec mb-0 mt-0">
                                {inputListLevels?.map((x, i) => {
                                  return (
                                    <div className="box">
                                      <div className="row">
                                        <div className="col-md-7 col-7 col-sm-8">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="Points"
                                            placeholder="Enter Points"
                                            value={x.Points}
                                            onChange={(e) =>
                                              handleInputChangeLevels(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="col-md-7 col-7 col-sm-8">
                                          <input
                                            className="form-control primary_inp mb-0"
                                            name="Level"
                                            placeholder="Enter Levels"
                                            value={x.Level}
                                            onChange={(e) =>
                                              handleInputChangeLevels(e, i)
                                            }
                                          />
                                        </div>
                                        <div className="col-md-5 col-5 col-sm-4">
                                          <div className="btn-box d-flex mb-2">
                                            {inputListLevels?.length !== 1 && (
                                              <button
                                                className="mr10 mt-0 w-100 btn create_btn"
                                                onClick={() =>
                                                  handleRemoveClickLevel(i)
                                                }
                                              >
                                                -
                                              </button>
                                            )}
                                            {inputListLevels?.length - 1 ===
                                              i && (
                                                <button
                                                  className="btn create_btn mt-0 w-100"
                                                  onClick={() =>
                                                    handleAddClickLevel(i - 1)
                                                  }
                                                >
                                                  +
                                                </button>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                        <div className="text-start mb-3">
                              <button
                              id="Pointsmodals"  data-target="#points_modal" data-toggle="modal"
                                className=" graybtn btn mr-2 mb-2"
                               onClick={() => { $('#Pointsmodals').trigger("click")}}
                              >
                                Choose nft points
                              </button>
</div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Hero Banner Title
                              </span>
                              <input
                                type="text"
                                id="bannerTittle"
                                value={Formdata.bannerTittle}
                                className="edit_inputs_align"
                                placeholder="Collect Limited Editions of Halloween NFTs"
                                name="bannerTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Hero Banner Description
                              </span>
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  {/* <CKEditor
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.bannerdiscription
                                        )}
                                      </p>
                                    }
                                    id="bannerdiscription"
                                    onChange={(e) => EditorBannerDiscription(e)}
                                  /> */}
                                   {/* <MatEditor outdata={(e) => EditorBannerDiscription(e)} sendata={Formdata.bannerdiscription}/> */}
                                   <ReactQuill
                               value={Formdata.bannerdiscription || BannerDiscription}
                              onChange={value => EditorBannerDiscription(value)}
                                modules={modules}
                                theme="snow"
                              />    
                                </>
                              ) : (
                                <ReactQuill
                               value={Formdata.bannerdiscription || BannerDiscription}
                              onChange={value => EditorBannerDiscription(value)}
                                modules={modules}
                                theme="snow"
                              />                     
                              )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Hero Banner
                              </span>

                              <div className="cmpny_logo_flex">
                                <input
                                  type="text"
                                  id="PackName"
                                  className="edit_inputs_align  cmpny_logo"
                                  placeholder="Select File"
                                  name="wallet address"
                                  disabled
                                />
                                <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                  <input
                                    type="file"
                                    id="companylogo"
                                    className="edit_inputs_align width_algd campaign_fileinp"
                                    placeholder="Plan Name*"
                                    name="companylogo"
                                    onChange={(e) => HandleBannerImage(e)}
                                  />
                                  <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </div>

                            <div className="d-sm-flex campaignmdl_inps">
                              <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                                <p className="">Image Dimension</p>
                                <p className="">(min 700px, 700px)</p>
                              </div>
                              {console.log("asdasdasda", Formdata)}
                              <img
                                className="campaign_bannerimg"
                                src={
                                  bannerImagePreview
                                    ? bannerImagePreview
                                    : Formdata.bannerImage
                                      ? config.Back_URL +
                                      "/" +
                                      Formdata.bannerImage
                                      : dummy
                                }
                              />
                            </div>
                            {/* 
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Title....
                              </span>
                              <input
                                type="text"
                                id="middlebannertittle"
                                value={Formdata.middlebannertittle}
                                className="edit_inputs_align"
                                placeholder="Join Us In The Treasure Hunt Collect You NFT Token"
                                name="middlebannertittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Description
                              </span>
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  <CKEditor
                                    className="ckedit_hei"
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.middlebannerdescription
                                        )}
                                      </p>
                                    }
                                    id="middlebannerdescription"
                                    onChange={(e) =>
                                      Editormiddlebannerdescription(e)
                                    }
                                  />
                                </>
                              ) : (
                                <CKEditor
                                  className="ckedit_hei"
                                  initData={
                                    <p>
                                      {ReactHTMLParser(
                                        Formdata.middlebannerdescription
                                      )}
                                    </p>
                                  }
                                  id="middlebannerdescription"
                                  onChange={(e) =>
                                    Editormiddlebannerdescription(e)
                                  }
                                />
                              )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Title Details
                              </span>
                              <input
                                type="text"
                                id="middlebannerdescriptionTittle"
                                value={Formdata.middlebannerdescriptionTittle}
                                className="edit_inputs_align"
                                placeholder="Collectible Halloween Tokens Only At Univarsal Studios In Sentosa"
                                name="middlebannerdescriptionTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner Description Details
                              </span>
                              {console.log(
                                "Asdasdsa",
                                Formdata.middlebannerdetails
                              )}
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  <CKEditor
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.middlebannerdescription
                                        )}
                                      </p>
                                    }
                                    id="middlebannerdetails"
                                    onChange={(e) =>
                                      Editormiddlebannerdetails(e)
                                    }
                                  />
                                </>
                              ) : (
                                <CKEditor
                                  className="ckedit_hei"
                                  initData={
                                    <p>
                                      {ReactHTMLParser(
                                        Formdata.middlebannerdescription
                                      )}
                                    </p>
                                  }
                                  id="middlebannerdescription"
                                  onChange={(e) => Editormiddlebannerdetails(e)}
                                />
                              )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Event Details
                              </span>
                              {console.log("Asdasdsa", Formdata.EventDeatils)}
                              {Wallet_Details.Update !== "Create" ? (
                                <>
                                  <CKEditor
                                    initData={
                                      <p>
                                        {ReactHTMLParser(
                                          Formdata.middlebannerdescription
                                        )}
                                      </p>
                                    }
                                    id="EventDeatils"
                                    onChange={(e) => EditorEventDeatils(e)}
                                  />
                                </>
                              ) : (
                                <CKEditor
                                  className="ckedit_hei"
                                  initData={
                                    <p>
                                      {ReactHTMLParser(Formdata.EventDeatils)}
                                    </p>
                                  }
                                  id="EventDeatils"
                                  onChange={(e) => EditorEventDeatils(e)}
                                />
                              )}
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Middle Banner
                              </span>
                              <div className="cmpny_logo_flex">
                                <input
                                  type="text"
                                  id="PackName"
                                  className="edit_inputs_align  cmpny_logo"
                                  placeholder="Select File"
                                  name="wallet address"
                                  disabled
                                />
                                <div className="campaignmdl_inpdblbet campaign_fileinpdiv">
                                  <input
                                    type="file"
                                    id="companylogo"
                                    className="edit_inputs_align width_algd campaign_fileinp"
                                    placeholder="Plan Name*"
                                    name="companylogo"
                                    onChange={(e) => HandleMiddleImage(e)}
                                  />
                                  <button className="admin_mdl_red_btn admn_img_upld_btn campaign_blutbtn">
                                    Upload
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <div className="campainmdl_imgdimen mb-2 mr-3 mb-sm-0">
                                <p className="">Image Dimension</p>
                                <p className="">(min 350px, 350px)</p>
                              </div>
                              <img
                                className="subd_logomodal"
                                src={
                                  middlebannerPreview
                                    ? middlebannerPreview
                                    : Formdata.middlebannerimage
                                      ? config.Back_URL +
                                      "/" +
                                      Formdata.middlebannerimage
                                      : dummy
                                }
                              />
                            </div> */}
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Top Winners Title
                              </span>
                              <input
                                type="text"
                                id="TopWinnerTittle"
                                value={Formdata.TopWinnerTittle}
                                className="edit_inputs_align"
                                placeholder="Top Winners this Week"
                                name="TopWinnerTittle"
                                onChange={(e) => OnChangehandle(e)}
                              />
                            </div>
                            <div className="d-sm-flex campaignmdl_inps">
                              <span className="labelspan mb-sm-0 mb-2 mr-2">
                                Background Colours
                              </span>
                              <div>
                                <div className="campaignmdl_cltita">
                                  <div>
                                    <input
                                      className="mr-3"
                                      type="color"
                                      id="backgroundcolor"
                                      name="backgroundcolor"
                                      value={Formdata.backgroundcolor}
                                      onChange={(e) => OnChangehandle(e)}
                                    />
                                    <span className="cambaignmdl_italic">
                                      Click to choose your color
                                    </span>
                                  </div>
                                  <span className="cambaignmdl_italic">
                                    Select the website background color
                                  </span>
                                </div>
                                {/* <div className='campaignmdl_checks'>
                                            <input type="radio" id="predefined" name="fav_language" value="predefined" />
                                            <label className='m-0 mr-2' for="predefined">Predefined images</label><br />
                                            <input type="radio" id="custom" name="fav_language" value="custom" />
                                            <label className='m-0' for="custom">Custom images</label>
                                        </div> */}
                                {/* <div className='campaignmdl_xamplediv'></div> */}
                              </div>
                            </div>
                            <div className="campaignmdl_triplebtns">
                              {Wallet_Details.Update == "Create" && (
                                <button
                                  className="admin_mdl_red_btn admn_img_upld_btn"
                                  onClick={() => {
                                    SaveEvent();
                                  }}
                                >
                                  Save
                                </button>
                              )}
                              {Wallet_Details.Update == "Update" && (
                                <button
                                  className="admin_mdl_red_btn admn_img_upld_btn"
                                  onClick={() => {
                                    SaveEvent();
                                  }}
                                >
                                  Update
                                </button>
                              )}
                              {/* <Link to={{ pathname: `/Preview`, state: Formdata, location: "collection" }} target={"_blank"} > */}
                              <button
                                className="admin_mdl_red_btn admn_img_upld_btn"
                                onClick={() => PreviewContent()}
                              >
                                Preview
                              </button>
                              {/* </Link> */}
                              {Wallet_Details.Update == "Create" && (
                                <button
                                  className="admin_mdl_red_btn admn_img_upld_btn"
                                  onClick={() => PublishEvent()}
                                >
                                  publish
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={
                          classes.pageHeader +
                          " inner_pageheader items_header my_items"
                        }
                      >
                        <div className="inner_pagh_bg_1">
                          <div className="container container_full">
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={12}>
                                <nav className="masonry_tab_nav mt-4 mb-3 items_tab_outer">
                                  <div
                                    className="nav nav-tabs masonry_tab primary_tab items_tab justify-content-center items_tab_new pb-0 pl-0"
                                    id="nav-tab"
                                    role="tablist"
                                  >
                                    <a
                                      onClick={() => {
                                        setTabName("created");
                                      }}
                                      className="nav-link"
                                      id="created-tab"
                                      data-toggle="tab"
                                      href="#created"
                                      role="tab"
                                      aria-controls="created"
                                      aria-selected="false"
                                    >
                                      <div className="tab_head">Created</div>
                                    </a>
                                    <a
                                      onClick={() => {
                                        MyItemTab("Ticket");
                                      }}
                                      className="nav-link"
                                      id="Ticket-tab"
                                      data-toggle="tab"
                                      href="#Ticket"
                                      role="tab"
                                      aria-controls="Ticket"
                                      aria-selected="false"
                                    >
                                      <div className="tab_head">Ticket</div>
                                    </a>
                                  </div>
                                </nav>
                                <div
                                  className="tab-content explore_tab_content  myitemsdf"
                                  id="nav-tabContent"
                                >
                                  <div
                                    className="tab-pane fade show active"
                                    id={TabName}
                                    role="tabpanel"
                                    aria-labelledby={`${TabName}-tab`}
                                  >
                                    <div className="proposal_panel_overall">
                                      {TabName === "created" ? (
                                        <>
                                          <MyItemCard />
                                          <div className="row">
                                            {/* <Card /> */}
                                          </div>
                                          {MyItemNFTs["created"] &&
                                            MyItemNFTs["created"]?.onmore && (
                                              <div className="col-12 pb-5 text-center pt-4">
                                                <button
                                                  className="create_btn"
                                                  onClick={() =>
                                                    MyItemTab(
                                                      "created",
                                                      Wallet_Details.UserAccountAddr,
                                                      null,
                                                      MyItemNFTs["created"]
                                                        .page + 1
                                                    )
                                                  }
                                                >
                                                  Load more items
                                                </button>
                                              </div>
                                            )}
                                        </>
                                      ) : (
                                        <>
                                          <Ticketss />
                                          <div className="row">
                                            {/* <Card /> */}
                                          </div>
                                          {MyItemNFTs["Ticket"] &&
                                            MyItemNFTs["Ticket"].onmore && (
                                              <div className="col-12 pb-5 text-center pt-4">
                                                <button
                                                  className="create_btn"
                                                  onClick={() =>
                                                    MyItemTab(
                                                      "Ticket",
                                                      Wallet_Details.UserAccountAddr,
                                                      null,
                                                      MyItemNFTs["Ticket"]
                                                        .page + 1
                                                    )
                                                  }
                                                >
                                                  Load more items
                                                </button>
                                              </div>
                                            )}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </GridItem>
                            </GridContainer>
                            <button
                              className="graybtn btn mr-2 mb-2"
                              onClick={() => setshowedd("true")}
                            >
                              Back
                            </button>
                            <button
                              className="graybtn btn mr-2 mb-2"
                              onClick={() => SaveNft()}
                            >
                              Confirm
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {/* end of campaign modals */}
        </>
      ) : (
        <>
          <Preview Item={Formdata} />
        </>
      )}
      <>
      {/* dummy modal */}
      <button id="Pointsmodals" className="d-none" data-target="#points_modal" data-toggle="modal"></button>
<div
        class="modal fade primary_modal"
        id="points_modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="points_modalCenteredLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        {console.log("inputListJourney",inputListJourney,Wallet_Details.TemplateName)}
        <div
          class="modal-dialog modal-dialog-centered modal-sm"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header text-center">
              <h5 class="modal-title" id="points_modalLabel">
                Enter Each NFT Points
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {Wallet_Details.TemplateName == "Fractional/Loyalty Page" && inputListJourney && inputListJourney?.length > 0 && inputListJourney?.map((item,index)=>{
                return(
                  <>
                  {console.log("itemitemitem",item)}
                  {item?.TreasureNft?.length > 0 && item?.TreasureNft?.map((innerData,i) =>{
                    return(
                      <div className="mb-3">
                      <h6>{innerData?.tokenName}</h6>
                      <input
                type="number"
                id={`numberofpoints-${i}`}
                className="edit_inputs_align"
                placeholder="points"
                name="numberofpoints"
                defaultValue={innerData?.Points || ''}
                onChange={(e) => { innerData.Points = e.target.value }}
              />
                    </div>
                    )
                  })}
              
                </>
                )
              })            
              }

<button
                type="button"
                class="mt-0 mb-3 btn create_btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">Done</span>
              </button>
            </div>
     
          </div>
        </div>
      </div>

      {/* dummy modal end */}
      </>
    </>
  );
};

export default Events;
