import React, { useEffect, useState } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import { Button, TextField } from "@material-ui/core";
// core components
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Bannerimga from "../assets/images/neko.png"
import Profile from "../assets/images/info_img.png"
import HeaderLinks from "components/Header/HeaderLinks.js";
import Footer from "components/Footer/Footer.js";
import styled from "../../node_modules/styled-components";
import styles from "assets/jss/material-kit-react/views/landingPage.js";
import { Link, useParams, useHistory } from "react-router-dom";
import { ListingCollection, ListingSoulBound, ListingCollection1 } from "actions/v1/token";
import { useSelector } from "react-redux";
import config from "lib/config";
import { usePrevious } from "./my-items";


const Icon = styled((props) => (
  <div {...props}>
    <div className="minus">-</div>
    <div className="plus">+</div>
  </div>
))`
  & > .plus {
    display: block;
    color: #3d2524;
    font-size: 24px;
  }
  & > .minus {
    display: none;
    color: #3d2524;
    font-size: 24px;
  }
  .Mui-expanded & > .minus {
    display: flex;
  }
  .Mui-expanded & > .plus {
    display: none;
  }
`;

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Collections(props) {
  const Wallet_Details = useSelector((state) => state.wallet_connect_context);
  const classes = useStyles();
  const { ...rest } = props;
  const [expanded, setExpanded] = React.useState("panel1");
  const { Collection, Category } = useParams();
  const prevQ = usePrevious(Collection);
  const [Page, setPage] = useState(1);
  const prevPage = usePrevious(Page)
  const [uPage, setuPage] = useState(1);
  const prevuPage = usePrevious(uPage)
  const [Load, setLoad] = useState(true);
  const [uLoad, setuLoad] = useState(true);
  const [ShowModal, setShowModal] = useState(false)
  const [ListData, SetListData] = useState({});
  const [ListDataSoul, SetListDataSoul] = useState({});
  const [CatName, setCatName] = useState("All")
  const [SubCat, setSubCat] = useState("undefined")
  const customStyles = {
    content: {
      position: 'fixed',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      // boxShadow: '0 27px 24px 0 rgb(0 0 0 / 20%), 0 40px 77px 0 rgb(0 0 0 / 22%)',
      borderRadius: '30px',
      border: 'none !important',
    },
    overlay: {
      backgroundColor: 'rgba(255,255,255,255)',
      zIndex: 2
    },
  };
  const handleChangeFaq = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  useEffect(() => {
    console.log("qweqweqweqwe", Collection);
    if (Collection == "Collection") {
      ListCollection();
    } else {
      console.log("erqerqweq");
      ListSoulBound()
    }
  }, [Collection])

  const catChange = async (Cat) => {
    if (Cat) {
      // if (!ExploreCardNFTs[Cat]) {
      //   ExploreCardNFTs[Cat] = { page: 1, list: [], onmore: true };
      //   console.log("ExploreCardNFTs",ExploreCardNFTs);
      //   setExploreCardNFTs(ExploreCardNFTs);
      // }

      console.log("qwewqrerwerwe", Cat);
      setCatName(Cat)
      // if (Cat !== "All") {
      //   console.log("adasdasdasdasdascommmm", ListData);
      //   var catee = Cat == "All" ? "" : Cat
      ListCollection1(Cat)
      ListSoulBound(Cat)
      // var filter =ListData.length > 0 && ListData.filter(fill => (fill.Category == Cat))
      // console.log("asdasdasdasdas", filter);
      // SetListData(filter)
      // }

    }
  }

  const SubcatChange = async (Cat, SubCatName) => {
    if (Cat) {
      console.log("qwewqrerwerwe", Cat, SubCatName);
      setSubCat(SubCatName)
      setCatName(Cat)
      ListCollection1(Cat, SubCatName)
    }
  }

  const SubcatChangeSBT = async (Cat, SubCatName) => {
    if (Cat) {
      console.log("qwewqrerwerwe", Cat, SubCatName);
      // console.log("ListData", ListData);
      // var fill = ListData.length > 0 && ListData.filter(fill => (fill.NFTSubCategory == SubCatName))
      // console.log("fill", fill);
      // SetListData(fill)
      // setSubCat(SubCatName)
      // setCatName(Cat)
      ListSoulBound(Cat, SubCatName)
    }
  }

  const ListCollection = async () => {

    var category = {
      Category: CatName
    }
    console.log("asdasdasdasdasd cat", category);
    var collectiongal = await ListingCollection();
    SetListDataSoul({})
    console.log("explorecomming", collectiongal.data.data.ListingCollection)
    SetListDataSoul(collectiongal.data.data.ListingCollection)
  }

  useEffect(() => {
    console.log("qweqweqweqwe", Collection, Category);
    setCatName(Category)
    if (Collection == "Collection") {
      ListCollection1(Category);
    } else {
      ListSoulBound(Category)
    }
  }, [Category])


  const ListCollection1 = async (cat, SubCatName) => {
    if (SubCatName) {
      var category = {
        Category: cat,
        NFTSubCategory: SubCatName
      }
    } else {
      var category = {
        Category: cat,
      }
    }
    console.log("asdasdasdasdasd cat", category);
    var collectiongal = await ListingCollection1(category);
    SetListDataSoul({})
    console.log("explorecoasdasdasmming", collectiongal.data.data.ListingCollection)

    SetListDataSoul(collectiongal.data.data.ListingCollection)
  }

  const ListSoulBound = async (Category, SubCat) => {
    console.log("qweqweqweqweqweqweqweqwe", Category);
    var redata = {
      Category: Category,
      SubCat: SubCat
    }
    var collectiongal = await ListingSoulBound(redata);
    SetListData({})
    console.log("exploreqwkeqwkjelqcomming", collectiongal)
    SetListData(collectiongal.data.data.ListingCollection)
  }

  const BrowseMarketPlace = () => {
    return (

      <div className="col-12">
        <div className="text-center py-5">
          <p className="not_found_text">No items found</p>
          <p className="not_found_text_sub">
            Come back soon! Or try to browse something for you on our marketplace
          </p>
          <div className="mt-3">
            <Link to="/explore/All">
              <Button className="browsematdk">Browse Marketplace</Button>
            </Link>
          </div>
        </div>
      </div>
    );
  };


  return (
    <div className="home_header">
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={
          <Link to="/home">
            <div className="header_logo_align">


            </div>
          </Link>
        }
        leftLinks={<HeaderLinks />}
        changeColorOnScroll={{
          height: 20,
          color: "white",
        }}
        {...rest}
      />
      <ScrollToTopOnMount />
      <div className={classes.pageHeader + " inner_pageheader"}>
        {Collection == "Collection" ?
          <div className="container-fluid custom-container">
            <p className="heading_big_blk">Collections</p>



            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <nav className="masonry_tab_nav pt-0 items_tab_outer">
                  <div
                    className="nav nav-tabs masonry_tab primary_tab explore_tab_full items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className={(CatName === 'All') ? "nav-link active" : "nav-link"}
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                      onClick={() => catChange('All')}
                    ><div class="tab_head">All</div>
                    </a>
                    {Wallet_Details.Category && Wallet_Details.Category.length > 0 &&
                      Wallet_Details.Category.map((item) => {
                        return (
                          (item.BulkStatus == "true" && item.name !== "Ticket" &&
                            <a className={(CatName === item.name) ? "nav-link active" : "nav-link"} onClick={() => catChange(item.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false"><div class="tab_head">{item.name}</div></a>
                          )
                        )
                      })
                    }
                  </div>
                  <div
                    className="nav nav-tabs cubstart masonry_tab primary_tab explore_tab_full items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    {/* <a
                    className={(CatName === 'All') ? "nav-link active" : "nav-link"}
                    id="all-tab"
                    data-toggle="tab"
                    href="#all"
                    role="tab"
                    aria-controls="onsale"
                    aria-selected="true"
                    onClick={() => catChange('All')}
                  ><div class="tab_head">All</div>
                  </a> */}
                    {Wallet_Details.Category && Wallet_Details.Category.length > 0 &&
                      Wallet_Details.Category.map((item) => {
                        return (
                          (item.BulkStatus == "true" && item.name !== "Ticket" && item.name == CatName &&
                            <>
                              {/* // <a className={(CatName === item.name) ? "nav-link active" : "nav-link"} onClick={() => catChange(item.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false"
                                // > */}
                              {item.name == CatName &&
                                item.BulkSubCategory.map((itemn) => {
                                  return (
                                    <a className={(SubCat === itemn.name) ? "nav-link active" : "nav-link"} onClick={() => SubcatChange(CatName, itemn.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false">
                                      <div class="tab_head">{itemn.name}</div></a>
                                  )
                                })

                              }
                            </>
                            // </a>
                          )
                        )
                      })
                    }
                  </div>
                </nav>
                <div
                  className="tab-content explore_tab_content mt-0 p-t-sec p-b-sec"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="all"
                    role="tabpanel"
                    aria-labelledby="all-tab"
                  >
                    <div className="row mb-5">
                      {console.log("weqwkejqwekw", ListDataSoul.length)}
                      {ListDataSoul.length > 0 ?
                        <>
                          {ListDataSoul.length > 0 &&
                            ListDataSoul.map((Item, index) => {
                              console.log("Bulkminting for Listing componrnt", Item);

                              return (

                                <div className="col-lg-3 col-md-6 col-12 mb-4">

                                  {CatName !== "All" ?
                                    <>


                                      <div className="collectionspaed">
                                        <Link to={`/collectioncard/${Item.Address}/${Item.CollectionName}`}>
                                          <div className="collectionspaedimg">
                                            {/* {console.log("qweqweqweqwrewr", Item)} */}
                                            {
                                              Item.CoverImg === "" || Item.ProfileImg === undefined &&
                                              <img src={Bannerimga} />
                                            }
                                            {
                                              Item.CoverImg !== "" &&
                                              <img src={`${config.Back_URL}/BulkListing/${Item.Address}/CoverImg/${Item.CoverImg}`} />
                                            }
                                          </div >
                                        </Link>
                                        <div className="collectionsimgecontent">
                                          {/* 
                        {console.log("sdadfsfwefwfrsf", `${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`)} */}
                                          {
                                            Item.ProfileImg === "" || Item.ProfileImg === undefined &&
                                            <img src={Profile} className="collectionsimgs" />
                                          }
                                          {
                                            Item.ProfileImg !== "" &&
                                            <img src={`${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`} />
                                          }
                                          <div>
                                            <h2>{Item?.CollectionName?.slice(0, 15)}</h2>
                                            {/* <h3>25 Items</h3> */}
                                          </div>
                                        </div>
                                      </div>

                                    </>
                                    :
                                    <div className="collectionspaed">
                                      <Link to={`/collectioncard/${Item.Address}/${Item.CollectionName}`}>
                                        <div className="collectionspaedimg">
                                          {/* {console.log("qweqweqweqwrewr", Item)} */}
                                          {
                                            Item.CoverImg === "" &&
                                            <img src={Bannerimga} />
                                          }
                                          {
                                            Item.CoverImg !== "" &&
                                            <img src={`${config.Back_URL}/BulkListing/${Item.Address}/CoverImg/${Item.CoverImg}`} />
                                          }
                                        </div >
                                      </Link>
                                      <div className="collectionsimgecontent">
                                        {/* 
                      {console.log("sdadfsfwefwfrsf", `${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`)} */}
                                        {
                                          Item.ProfileImg === "" &&
                                          <img src={Profile} className="collectionsimgs" />
                                        }
                                        {
                                          Item.ProfileImg !== "" &&
                                          <img src={`${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`} />
                                        }
                                        <div>
                                          <h2>{Item?.CollectionName?.slice(0, 15)}</h2>
                                          {/* <h3>25 Items</h3> */}
                                        </div>
                                      </div>
                                    </div>
                                  }

                                </div>
                              )
                            })}
                        </>
                        :
                        <BrowseMarketPlace />
                      }
                    </div>

                  </div>
                </div>
              </GridItem>
            </GridContainer>




          </div>
          :
          <div className="container-fluid custom-container">
            <p className="heading_big_blk">Non-Transferrable Collection</p>

            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <nav className="masonry_tab_nav pt-0 items_tab_outer">
                  <div
                    className="nav nav-tabs masonry_tab primary_tab explore_tab_full items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    <a
                      className={(CatName === 'All') ? "nav-link active" : "nav-link"}
                      id="all-tab"
                      data-toggle="tab"
                      href="#all"
                      role="tab"
                      aria-controls="onsale"
                      aria-selected="true"
                      onClick={() => catChange('All')}
                    ><div class="tab_head">All</div>
                    </a>
                    {Wallet_Details.CategorySBT && Wallet_Details.CategorySBT.length > 0 &&
                      Wallet_Details.CategorySBT.map((item) => {
                        return (

                          <a className={(CatName === item.name) ? "nav-link active" : "nav-link"} onClick={() => catChange(item.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false"><div class="tab_head">{item.name}</div></a>

                        )
                      })
                    }
                  </div>
                  <div
                    className="nav nav-tabs cubstart masonry_tab primary_tab explore_tab_full items_tab justify-content-center items_tab_new pb-0 pl-0"
                    id="nav-tab"
                    role="tablist"
                  >
                    {Wallet_Details.CategorySBT && Wallet_Details.CategorySBT.length > 0 &&
                      Wallet_Details.CategorySBT.map((item) => {
                        return (
                          (item.name == CatName &&
                            <>
                              {item.name == CatName &&
                                item.BulkSubCategory.map((itemn) => {
                                  return (
                                    <a className={(SubCat === itemn.name) ? "nav-link active" : "nav-link"} onClick={() => SubcatChangeSBT(CatName, itemn.name)} data-tabName={item.name} id="all-tab" data-toggle="tab" href="#all" role="tab" aria-controls="all" aria-selected="false">
                                      <div class="tab_head">{itemn.name}</div></a>
                                  )
                                })

                              }
                            </>
                            // </a>
                          )
                        )
                      })
                    }
                  </div>
                </nav>
                <div
                  className="tab-content explore_tab_content mt-0 p-t-sec p-b-sec"
                  id="nav-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="all"
                    role="tabpanel"
                    aria-labelledby="all-tab"
                  >
                    <div className="row mb-5">
                      {console.log("weqwkejqwekw", ListData.length)}
                      {ListData.length > 0 ?
                        <>
                          {ListData.length > 0 &&
                            ListData.map((Item, index) => {
                              console.log("Bulkminting for Listing componrnt", Item);

                              return (

                                <div className="col-lg-3 col-md-6 col-12 mb-4">

                                  {CatName !== "All" ?
                                    <>
                                      <div className="collectionspaed">
                                        <Link to={`/certificate/${Item.Address}/${Item.CollectionName}`}>
                                          <div className="collectionspaedimg">
                                            {/* {console.log("qweqweqweqwrewr", Item)} */}
                                            {
                                              Item?.CoverImg ?
                                              <img src={`${config.Back_URL}/Soulbound/${Item.Address}/CoverImg/${Item.CoverImg}`} /> :
                                              <img src={Bannerimga} />
                                            }
                                            {/* {
                                              Item?.CoverImg !== "" &&
                                              <img src={`${config.Back_URL}/Soulbound/${Item.Address}/CoverImg/${Item.CoverImg}`} />
                                            } */}
                                          </div >
                                        </Link>
                                        <div className="collectionsimgecontent">
                                          {/* 
                      {console.log("sdadfsfwefwfrsf", `${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`)} */}
                                          {
                                            Item.ProfileImg ?
                                            <img src={`${config.Back_URL}/Soulbound/${Item.Address}/ProfileImg/${Item.ProfileImg}`} />:
                                            
                                            <img src={Profile} className="collectionsimgs" />
                                          }
                                          {/* {
                                            Item.ProfileImg !== "" &&
                                            <img src={`${config.Back_URL}/Soulbound/${Item.Address}/ProfileImg/${Item.ProfileImg}`} />
                                          } */}
                                          <div>
                                            <h2>{Item?.CollectionName?.slice(0, 15)}</h2>
                                            {/* <h3>25 Items</h3> */}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                    :
                                    <div className="collectionspaed">
                                      <Link to={`/certificate/${Item.Address}/${Item.CollectionName}`}>
                                        <div className="collectionspaedimg">
                                          {/* {console.log("qweqweqweqwrewr", Item)} */}
                                          {
                                            Item?.CoverImg === "" &&
                                            <img src={Bannerimga} />
                                          }
                                          {
                                            Item?.CoverImg !== "" &&
                                            <img src={`${config.Back_URL}/Soulbound/${Item.Address}/CoverImg/${Item.CoverImg}`} />
                                          }
                                        </div >
                                      </Link>
                                      <div className="collectionsimgecontent">
                                        {/* 
                                {console.log("sdadfsfwefwfrsf", `${config.Back_URL}/BulkListing/${Item.Address}/ProfileImg/${Item.ProfileImg}`)} */}
                                        {
                                          Item.ProfileImg === "" &&
                                          <img src={Profile} className="collectionsimgs" />
                                        }
                                        {
                                          Item.ProfileImg !== "" &&
                                          <img src={`${config.Back_URL}/Soulbound/${Item.Address}/ProfileImg/${Item.ProfileImg}`} />
                                        }
                                        <div>
                                          <h2>{Item?.CollectionName?.slice(0, 15)}</h2>
                                          {/* <h3>25 Items</h3> */}
                                        </div>
                                      </div>
                                    </div>
                                  }

                                </div>
                              )
                            })}
                        </>
                        :
                        <BrowseMarketPlace />
                      }
                    </div>

                  </div>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        }
      </div>
      <Footer />

    </div>
  );
}
