import * as V1User from 'actions/v1/user';

export const GetProfiledata = async (payload) =>{
    var formdata = {}
    var Resp = await V1User.v1_GetProfile(payload)
    if(Resp.data.Success)
    {
        formdata['photo'] = Resp.data.UserDet.image || "";
        formdata['coverimage'] = Resp.data.UserDet.coverimage || "";
        formdata['Bio'] = Resp.data.UserDet.bio || "";
        formdata['CustomUrl'] = Resp.data.UserDet.customurl || "";
        formdata['Twitter'] = Resp.data.UserDet.twitter || "";
        formdata['Instagram'] = Resp.data.UserDet.instagram || "";
        formdata['Facebook'] = Resp.data.UserDet.facebook || "";
        formdata['Youtube'] = Resp.data.UserDet.youtube || "";
        formdata['Name'] = Resp.data.UserDet.name || "";
        formdata['Email'] = Resp.data.UserDet.email || "";
        formdata['verifiedmail'] = Resp.data.UserDet.verifiedmail || "";
        formdata['Site'] = Resp.data.UserDet.personalsite || "";
        formdata['curraddress'] = Resp.data.UserDet.curraddress || "";
        formdata['PrimaryAddr'] = Resp.data.UserDet.PrimaryAddr || "";
        formdata['LocalAddr'] = Resp.data.UserDet.LocalAddr || "";
        formdata['Follow'] = (Resp.data.Follow !== false)?Resp.data.Follow:false
        formdata['Count'] = (Resp.data.Count)?Resp.data.Count:false
        formdata['Joined'] = new Date(Resp.data.UserDet.date).toLocaleDateString('en-US',{month:"long",year:"numeric"}) || "";
    }
    // console.log("bunhybvtyghhbijjmnjimn",formdata,Resp.data.Follow)
    return formdata
}

export const EditProfiledata = async (payload) => {
var formData = new FormData();
    if(payload.File) { formData.append('Image',payload.File); }
    if(payload.photo) { formData.append('imagename',payload.photo); }
    if(payload.Bio) { formData.append('Bio', payload.Bio); }
    if(payload.Name) { formData.append('name', payload.Name); }
    if(payload.CustomUrl) { formData.append('customurl', payload.CustomUrl); }
    if((payload.Email)) { formData.append('email', payload.Email); }
    if(payload.curraddress) { formData.append('curraddress', payload.curraddress); }
    if(payload.Facebook) { formData.append('facebook', payload.Facebook); }
    if(payload.Twitter) { formData.append('twitter', payload.Twitter); }
    if(payload.Instagram) { formData.append('instagram', payload.Instagram); }
    if(payload.Youtube) { formData.append('youtube', payload.Youtube); }
    if(payload.Site) { formData.append('site', payload.Site); }

    var Resp = await V1User.v1_EditProfile(formData)
    return Resp.data
}

export const EditCoverPhoto = async (payload) => {
    var formData = new FormData();
    if(payload.File) { formData.append('Image',payload.File); }
    if(payload.curraddress) { formData.append('curraddress', payload.curraddress); }
    console.log(payload)
    var Resp = await V1User.v1_EditCover(formData)
    return Resp
}

export const MyUserData = async (payload) =>{
    var Resp = await V1User.v1_MyUSerData(payload)
    Resp = {Success:Resp.data.Resp.Success,Tab:Resp.data.Tab,records:Resp.data.Resp.records}
    return Resp
}
