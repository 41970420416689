import React, { useState, useEffect, useRef } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from "components/CustomButtons/Button.js";
import AvatarGroup from '@mui/material/AvatarGroup';
import SubDomainFooter from 'components/Footer/SubDomainFooter';
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Container,
  Row,
  Col,
  Tab,
  Nav,
  FormText,
  Dropdown
} from "react-bootstrap";
import PhoneInput from 'react-phone-number-input'
import "swiper/css";
import "swiper/css/pagination";
import DatePicker from "react-datepicker";
import { ThreeDots } from 'react-loader-spinner'
import QRCode from 'qrcode.react';
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Pagination, Autoplay, Navigation } from "swiper";
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { Account_Connect, Account_disConnect } from "actions/redux/action";
import { IoSearchSharp, IoSunnyOutline, IoMoonOutline } from "react-icons/io5";
import cred from "../assets/images/Loyalty/svg/cred.svg";
import { LuWallet2 } from "react-icons/lu";
import { IoMdClose } from "react-icons/io";
import ReactHTMLParser from "react-html-parser";
import config from 'lib/config'
import banner_img from '../assets/images/faces/imagebg.png'
import banner_logo from '../assets/images/faces/logo.png';
import headerLogo from "../assets/images/Loyalty/svg/logo.svg";
import banner_logos from '../assets/images/lazymintingnew/logodark.png';
import petimage from "../assets/images/lazymintingnew/petimage.png";
import bannerImg from "../assets/images/Loyalty/bannerimg.png";
import nftImg from "../assets/images/Loyalty/nft.png";
import star from "../assets/images/Loyalty/svg/Star.svg";
import { FaEye } from 'react-icons/fa';
import blutick from "../assets/images/lazymintingnew/blutick.png";
import logoimage from "../assets/images/lazymintingnew/logo.png";
import { AiOutlineHeart, AiFillHeart } from 'react-icons/ai';
import { GrFormClose } from 'react-icons/gr';
import { FaDownload } from 'react-icons/fa';
import { Apps, CloudDownload, WbSunny, Brightness3 } from "@material-ui/icons";
import { BsThreeDotsVertical, BsThreeDots } from "react-icons/bs";
// import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';

// import AOS from "aos";
// import "aos/dist/aos.css";

const PDUdata = [
  {
    id: 1,
    Journey: "Journey 1",
    title: "Learn-To-Earn",
    starRating: 5.0,
    tokenNumber: 2,
    totalToken: "Total Tokens Obtained  = 16 / 16",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 2,
    Journey: "Journey 2",
    title: "Play-To-Earn",
    starRating: 4.9,
    tokenNumber: 4,
    totalToken: "Total Tokens Obtained  = 18 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 3,
    Journey: "Journey 3",
    title: "Refer-To-Earn",
    starRating: 4.9,
    tokenNumber: 6,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 4,
    Journey: "Journey 4",
    title: "Pay-To-Earn",
    starRating: 4.9,
    tokenNumber: 1,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 5,
    Journey: "Journey 5",
    title: "Invest-To-Earn",
    starRating: 4.9,
    tokenNumber: 10,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 6,
    Journey: "Journey 6",
    title: "Donate-To-Earn",
    starRating: 4.9,
    tokenNumber: 11,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 7,
    Journey: "Journey 7",
    title: "Exercise-To-Earn",
    starRating: 4.9,
    tokenNumber: 20,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 8,
    Journey: "Journey 8",
    title: "Save-To-Earn",
    starRating: 4.9,
    tokenNumber: 14,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  {
    id: 9,
    Journey: "Journey 9",
    title: "Travel-To-Earn",
    starRating: 4.9,
    tokenNumber: 16,
    totalToken: "Total Tokens Obtained  = 16 / 24",
    subTitle:
      "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  },
  // {
  //   id: 10,
  //   Journey: "Journey 2",
  //   title: "Play-To-Earn",
  //   starRating: 4.9,
  //   totalToken: "Total Tokens Obtained  = 16 / 24",
  //   subTitle:
  //     "Dive into our engaging training modules and earn valuable Web3 tokens upon each module's completion. These tokens aren't just a reward; they're your key to unlocking advanced training, elevating your knowledge and expertise to new heights. Your journey culminates in the renewal of your certification, achieved by leveraging the tokens you've amassed. Don't just learn - earn and advance with every step.",
  // },
];

const topAchieverData = [
  {
    id: 1,
  },
  {
    id: 2,
  },
  {
    id: 3,
  },
  {
    id: 4,
  },
  {
    id: 5,
  },
  {
    id: 6,
  },
  {
    id: 7,
  },
  {
    id: 8,
  },
  {
    id: 9,
  },
];
function NewSubDomain(props) {
  console.log("sdnfsdkfjsdfwee", props);
  const dispatch = useDispatch();
  const Data = props.Item
  useEffect(() => {
    console.log("asdsadfsaasdas", localStorage.getItem("theme1"));
    themechange(localStorage.getItem("theme1"))

  }, []);

  const themechange = (data) => {
    console.log("localStorage.getItem", data);
    if (data == "DT" || data == undefined) {

      localStorage.setItem("theme1", "DT")
      document.getElementById("root").classList.remove("light_theme")
      document.getElementById("root").classList.add("dark_theme")

      // this.state.theme === true
      // this.setState({theme:true})
      settheme1(false)

    } else {
      localStorage.setItem("theme1", "LT")
      document.getElementById("root").classList.add("light_theme")
      document.getElementById("root").classList.remove("dark_theme");

      // this.setState({theme:false})
      // this.state.theme === false
      settheme1(true)
    }
  }
  const InitialForm = {
    Breed: "",
    name: "Patrick",
    weight: "0.5kg",
    describe: "Like Light Scratching Calms me down",
    chipno: "977200000000000",
    height: "",
    genders: "",
    vaccine: "",
  }
  const [Formdata, setFormdata] = useState(InitialForm);
  const ShowModelssss = () => {
    localStorage.setItem("StatusShow", "Show")
    dispatch({
      type: Account_Connect,
      Account_Detail: {
        PreviewContentShow: "false"
      }
    })
  }
  const [theme1, settheme1] = useState("DT");
  const [startDate, setStartDate] = useState(new Date());

  const [value, setValue] = useState()
  const [selectedImage, setSelectedImage] = useState(petimage);
  const [imagename, setImagename] = useState("Select File");
  const [viewBanner, setViewBanner] = useState(true);
  const componentRef = useRef(null);
  const fileInputRef = useRef(null);
  const [PDuDatas, setPDuDatas] = useState([]);
  console.log("PDuDatas", PDuDatas);
  const [cardTitle, setCardTitle] = useState(PDuDatas[0]?.Name);
  const [cardSubtitle, setCardSubtitle] = useState(PDuDatas[0]?.Description);
  const [activeCard, setActiveCard] = useState(1);
  const [tknNumber, setTknNumber] = useState(16);
  const [journeyNfts, setjourneyNfts] = useState([]);

  useEffect(() => {
    Initalcall()
  }, [])

  const Initalcall = async () => {

    console.log("dataaaaaaaaaaaaaaaa", Data
    );
    // activeCard
    setjourneyNfts(Data && Data?.Dropdown1Category?.length > 0 ? Data?.Dropdown1Category[0] : null)

  }

  const handleCapture = async () => {
    // if (componentRef.current) {
    //     html2canvas(componentRef.current).then(async (canvas) => {
    //         console.log("asdjadkadqweq", canvas);
    //         const dataURL = await canvas.toDataURL('image/png');
    //         var fileDataCon = await dataURLtoFile(dataURL,'name.png');
    //         console.log("fileDataCon",fileDataCon);
    //         const a = document.createElement('a');
    //         a.href = dataURL;
    //         document.body.appendChild(a);
    //         a.click();
    //         document.body.removeChild(a);
    //     });
    // }
  };

  // const handleCapture = () => {
  //   if (componentRef.current) {
  //     html2canvas(componentRef.current).then((canvas) => {
  //       const dataURL = canvas.toDataURL('image/png');

  //       // Create a download link for the captured image
  //       const a = document.createElement('a');
  //       a.href = dataURL;
  //       a.download = 'captured-image.png';
  //       document.body.appendChild(a);
  //       a.click();
  //       document.body.removeChild(a);
  //     });
  //   }
  // };
  const handleFileUpload = () => {
    // fileInputRef.current.click();
  };

  const handleFilesSelected = (event) => {
    const files = event.target.files[0];
    const filename = files.name;
    console.log('Selected files:', files);
    if (files) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(files);
      setImagename(filename);
    }
  };





  const handleSelect = (eventKey, event) => {
    const id = event.target.id;
    console.log(id, Formdata, "idcheck");
    var Form = Formdata
    if (id == "Breed") {
      Form = { ...Form, ...{ [id]: eventKey } };
      setFormdata(Form)
    }
    else if (id == "genders") {
      Form = { ...Form, ...{ [id]: eventKey } };
      setFormdata(Form)
    }
    else if (id == "vaccine") {
      Form = { ...Form, ...{ [id]: eventKey } };
      setFormdata(Form)
    }
    console.log(Form, "formdata");

  }
  let PDUpoints2 = [];

  const addPduToken = () => {
    for (let i = 1; i <= tknNumber; i++) {
      PDUpoints2.push({ id: i });
    }
  };
  addPduToken();

  const PreviewComp = () => {
    return (
      <div ref={componentRef} className="card previewcard">
        <div className="mobilefullimage">
          <img src={selectedImage} class="card-img-top" alt="..." />
          {/* {<AiOutlineHeart className="heartcolor" />
            //  <AiFillHeart className="heartcolor"/>
          } */}
        </div>
        <div class="card-body">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-6">
              <h4 className="previewname myname">{Formdata?.name} <img src={blutick} class="blutick" alt="" /></h4>
              <p className="lighttext smalltext">{Formdata?.describe}</p>
            </div>
            <div className="col-6 col-sm-6 col-md-6">
              <h4 className="previewname text-right myname1">20339822011</h4>
              {/* <p className="lighttext text-right">Passport Number<br></br>Valid for 1 Year</p> */}
            </div>
          </div>
          {/* <div className="row justify-content-center">
            <div className="col-3 mt-3"><div className="det"><h6 className="det-tit">Age</h6><p className="previewname">3</p></div></div>
            <div className="col-3 mt-3"><div className="det"><h6 className="det-tit">Gender</h6><p className="previewname">{Formdata.genders ? Formdata.genders : "Male"}</p></div></div>
            <div className="col-3 mt-3"><div className="det"><h6 className="det-tit">Weight</h6><p className="previewname">{Formdata.weight}</p></div></div>
            <div className="col-3 mt-3"><div className="det"><h6 className="det-tit">Vaccine</h6><p className="previewname">{Formdata.vaccine ? Formdata.vaccine : "1"}</p></div></div>
            <div className="col-6 mt-3"><div className="det"><h6 className="det-tit">Breed</h6><p className="previewname">{Formdata.Breed ? Formdata.Breed : "Toy Poodle"}</p></div></div>
            <div className="col-6 mt-3"><div className="det"><h6 className="det-tit">Date of Birth</h6><p className="previewname">14th Feb 2019</p></div></div>
          </div> */}

          <div class="wrapper mt-2 mb-2">
            {!Data?.DobFieldStatus &&
              <div class="item1 mt-2"><div className="det">
                <h6 className="det-tit">Age</h6>
                <p className="previewname">4</p>
              </div></div>}
            {!Data?.GenderStatus &&
              <div className="item2 mt-2">
                <div className="det">
                  {(Data?.GenderDropDown == null || Data?.GenderDropDown == undefined) ?
                    <h6 className="det-tit">Gender</h6>
                    :
                    <h6 className="det-tit">{Data?.GenderDropDown}</h6>

                  }

                  <p className="previewname">{Formdata?.genders ? Formdata?.genders : "Male"}</p>
                </div>
              </div>
            }
            {!Data?.WeightStatus &&
              <div className="item3 mt-2">
                <div className="det">
                  {(Data?.Weight == null || Data?.Weight == undefined) ?
                    <h6 className="det-tit">Weight</h6>
                    :
                    <h6 className="det-tit">{Data?.Weight}</h6>

                  }
                  <p className="previewname">{Formdata?.weight}</p>
                </div>
              </div>
            }
            {!Data?.VaccineStatus &&
              <div className="item4 mt-2">
                <div className="det">
                  {(Data?.VaccineField == null || Data?.VaccineField == undefined) ?
                    <h6 className="det-tit">Vaccine</h6>
                    :
                    <h6 className="det-tit">{Data?.VaccineField}</h6>

                  }

                  <p className="previewname">{Formdata?.vaccine ? Formdata?.vaccine : "1"}</p>
                </div>
              </div>
            }
          </div>

          <div class="wrapper mb-3">
            {!Data?.BreadStatus &&
              <div className="item1 mt-2">
                <div className="det">
                  {(Data?.BreadDropDown == null || Data?.BreadDropDown == undefined) ?
                    <h6 className="det-tit">Breed</h6>
                    :
                    <h6 className="det-tit">{Data?.BreadDropDown}</h6>

                  }


                  <p className="previewname">{Formdata?.Breed ? Formdata?.Breed : "Toy Poodle"}</p>
                </div>
              </div>
            }
            {!Data?.DobFieldStatus &&
              <div className="item2 mt-2">
                <div className="det">
                  {(Data?.DobField == null || Data?.DobField == undefined) ?
                    <h6 className="det-tit">Date of Birth</h6>
                    :
                    <h6 className="det-tit">{Data?.DobField}</h6>
                  }

                  <p className="previewname">{moment(startDate).format('D MMMM YYYY')}</p>
                </div>
              </div>
            }
          </div>

          <div className="row">
            <div className="col-7 mt-2">
              <div className="d-flex">
                <img src={logoimage} class="logoimage" alt="..." />
                <div className="ml-2">
                  <p className="previewname issue mb-1">ISSUED BY</p>
                  <p className="previewname prevaddr">Clairvoyant Lab (TH)</p>
                  <p className="previewname prevaddr">Date Of Issue :</p>
                  <p className="previewname prevaddr">{moment(new Date()).format('D MMMM YYYY')}</p>
                </div>
              </div>
              <div className="d-flex mt-3">
                {(Data?.Chip == null || Data?.Chip == undefined) ?
                  <p className="lighttext smalltext">CHIP #</p>
                  :

                  <p className="lighttext smalltext">{Data?.Chip}</p>
                }

                <p className="lighttext smalltext ml-2">{Formdata?.chipno}</p>
              </div>
            </div>
            <div className="col-5 text-center mt-2">
              <QRCode size={70} fgColor="black" bgColor="white"
                value={Formdata?.mail}
              />
              <p className="lighttext smalltext mt-2">Scan To Verify and
                Contact Owner</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const [showss, setShowss] = useState(false);
  const handleClose1 = () => setShowss(false);
  const handleShow1 = () => setShowss(true);

  useEffect(() => {
    console.log("asdsadfsaasdas", localStorage.getItem("theme1"));
    // themechange(localStorage.getItem("theme1"))
    settheme1(localStorage.getItem("theme1"))

  }, [localStorage.getItem("theme1"), theme1]);
  useEffect(() => {
    setPDuDatas(Data.Dropdown1Category);
  }, []);

  const OnChangehandle = (e) => {
    e.preventDefault();
    const { id, value } = e.target;
    let Form = Formdata;
    Form = { ...Form, ...{ [id]: value } };
    setFormdata(Form);
  };

  const handleCard = (value, index) => {
    console.log("cardId", value, index);
    setCardTitle(value?.Name);
    setCardSubtitle(value?.Description);
    setActiveCard(index);
    setjourneyNfts(Data && Data?.Dropdown1Category?.length > 0 ? Data?.Dropdown1Category[index - 1] : null)
    console.log("tokennumber", value?.tokenNumber);
    setTknNumber(value?.tokenNumber);
  };

  return (
    <>
      {Data?.template == "Collectible NFTs Template" ?
    <>
    <div className='home_header1 home header_bar_align subdomain_des prev_sub margins tempflow'   >

      <button
        className="graybtn btn mr-2 ml-2 mb-4" data-toggle="modal" data-target="#addcampaingModal"
        onClick={() => ShowModelssss()}
      >
        Back
      </button>


      <div className='subd_banner previews'>
        {console.log("Data?.companylogo", Data?.companylogo)}
        <div className='mble_bg'>
          <div className='text-center'>
            {(Data?.companylogo == null || Data?.companylogo == undefined) ?
              // <img src={banner_logo} alt='banner_img' className='subd_logo companylogo img-fluid' />
              <div class="header_logo_align mx-auto"></div>
              :
              <img className='subd_logo companylogo  img-fluid' src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)} />
            }
          </div>
          <div className='banner_img'>
            {console.log("Data?.bannerImage",Data?.bannerImage)}
            {(Data?.bannerImage == null || Data?.bannerImage == undefined) ?
              <img src={banner_img} alt='banner_img' className='sub_domain_banner img-fluid' />
              :
              <img className='sub_domain_banner img-fluid' src={typeof Data?.bannerImage == "string" ? `${config?.Back_URL}/${Data?.bannerImage}` : URL?.createObjectURL(Data?.bannerImage)} />
            }
            {Data?.bannerTittle == "" || Data?.bannerTittle == null || Data?.bannerTittle == undefined ?
              <h3 className='subd_title'>Collect Limited Editions of Halloween NFTs</h3>
              :
              <h3 className='subd_title'>{Data?.bannerTittle}</h3>
            }
          </div>
        </div>

        <div className='web_bg'>
          {(Data?.bannerImage == null || Data?.bannerImage == undefined) ?
            <img src={banner_img} alt='banner_img' className='sub_domain_banner img-fluid' />
            :
            <img className='sub_domain_banner img-fluid' src={typeof Data?.bannerImage == "string" ? `${config?.Back_URL}/${Data?.bannerImage}` : URL?.createObjectURL(Data?.bannerImage)} />
          }
          {/* <img src={banner_img} alt='banner_img' className='sub_domain_banner img-fluid' /> */}
        </div>
        <div className='inner_banner'>
          <div className='container'>
            <div className='row'>
              <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12 subd_banner_content'>

                {(Data?.companylogo == null || Data?.companylogo == undefined) ?
                  <img className='subd_logo sublogo_hide' src={require('../assets/images/faces/logo.png')} />
                  :
                  <img className='subd_logo sublogo_hide' src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)} />
                }
                {/* {Data?.bannerTittle == "" || Data?.bannerTittle == null || Data?.bannerTittle == undefined ?
              <h3 className='subd_title'>Collect Limited Editions of Halloween NFTs</h3>
              :
              <h3 className='subd_title'>{Data?.bannerTittle}</h3>
            } */}
                {Data?.bannerTittle == "" || Data?.bannerTittle == null || Data?.bannerTittle == undefined ?
                  <p className='subd_hinttxt bgblck'>Immerse yourself in the spooky world of Universal Studio's iconic Halloween events through a collection of exclusive digital collectibles. Each NFT showcases spine-chilling characters, haunted attractions, and memorable moments, meticulously crafted to capture the essence of the Halloween experience. But that's not all - these NFTs also unlock a unique treasure hunt for NFT holders, with a chance to win incredible prizes!</p>
                  :
                  <p className='subd_hinttxt'>{ReactHTMLParser(Data?.bannerdiscription)}</p>
                }

                <div className='subd_bannerbtns'>
                  <button className='subd_orangebtn mb-3'>Collect Now</button>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <div className='container previews2'>
        <div className='mb-3'>
          {(Data?.companylogo == null || Data?.companylogo == undefined) ?
            <>
              <div class="header_logo_align"></div>

            </>

            :
            <img className='subd_logo sublogo_hide' src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)} />
          }</div>
        <div className='bannerbgclr'>
          <div className='row align-items-center'>
            <div className='col-md-6 mt-3'>
              {Data?.bannerTittle == "" || Data?.bannerTittle == null || Data?.bannerTittle == undefined ?
                <h3 className='subd_title'>Collect Limited Editions of Halloween NFTs</h3>
                :
                <h3 className='subd_title'>{Data?.bannerTittle}</h3>
              }
              {Data?.bannerTittle == "" || Data?.bannerTittle == null || Data?.bannerTittle == undefined ?
                <p className='subd_hinttxt'>Immerse yourself in the spooky world of Universal Studio's iconic Halloween events through a collection of exclusive digital collectibles. Each NFT showcases spine-chilling characters, haunted attractions, and memorable moments, meticulously crafted to capture the essence of the Halloween experience. But that's not all - these NFTs also unlock a unique treasure hunt for NFT holders, with a chance to win incredible prizes!</p>
                :
                <p className='subd_hinttxt'>{ReactHTMLParser(Data?.bannerdiscription)}</p>
              }

              <div className='subd_bannerbtns'>
                <button className='subd_orangebtn mb-3'>Collect Now</button>

              </div>
            </div>
            <div className='col-md-6 mt-3'>
              <div className='web_bg'>
                {(Data?.bannerImage == null || Data?.bannerImage == undefined) ?
                  <img src={banner_img} alt='banner_img' className='sub_domain_banner img-fluid' />
                  :
                  <img className='sub_domain_banner img-fluid' src={typeof Data?.bannerImage == "string" ? `${config?.Back_URL}/${Data?.bannerImage}` : URL?.createObjectURL(Data?.bannerImage)} />
                }

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='subd_jointreasure_dtls'>
        {Data?.middlebannertittle == "" || Data?.middlebannertittle == null || Data?.middlebannertittle == undefined ?
          <h4 className='subd_jointr_ttl'>Join Us In The Treasure Hunt</h4>
          :
          <h4 className='subd_jointr_ttl'>{Data?.middlebannertittle}</h4>
        }

        {Data?.middlebannerdetails == "" || Data?.middlebannerdetails == null || Data?.middlebannerdetails == undefined ?
          <p className='subd_jointr_hint'>Exchange for a mystery prize once you had collected all 7 NFT tokens</p>
          :
          <p className='subd_jointr_hint'>{ReactHTMLParser(Data?.middlebannerdetails)}</p>
        }
        <div className='voilet_blurdark jointr_right'></div>
        <div className='voilet_blurdark jointr_left'></div>

        <div className='container subd_nointrcontbg'>
          <div className='row'>
            <div className='col-xxl-5 col-xl-5 col-lg-5 col-sm-12 col-12'>
              {Data?.middlebannerimage == "" || Data?.middlebannerimage == null || Data?.middlebannerimage == undefined ?
                <img className='img-fluid subd_jointr_contimg' src={require('../assets/images/faces/image2.png')} />
                :
                <img className='img-fluid subd_jointr_contimg' src={typeof Data?.middlebannerimage == "string" ? `${config?.Back_URL}/${Data?.middlebannerimage}` : URL?.createObjectURL(Data?.middlebannerimage)} />
              }

            </div>
            <div className='col-xxl-7 col-cl-7 col-lg-7  col-sm-12 col-12'>
              {Data?.middlebannertittle == "" || Data?.middlebannertittle == null || Data?.middlebannertittle == undefined ?
                <h5 className='subd_jointr_cntttl'>Collectible Halloween Tokens Only At Universal Studios in Sentosa</h5>
                :
                <h5 className='subd_jointr_cntttl'>{Data?.middlebannertittle}</h5>

              }
              {Data?.middlebannerdescription == "" || Data?.middlebannerdescription == null || Data?.middlebannerdescription == undefined ?
                <p className='subd_jointr_cnthint'>We are a community based collection project featuring 3D and NFT artwork. Cool 3D from me and comes in all kinds of evil shape traits and sizes with a few surprises along the way</p>
                :
                <p className='subd_jointr_cnthint'>{ReactHTMLParser(Data?.middlebannerdescription)}</p>
              }
              <div className='subd_whole_avtgrp'>


                <div className='subd_whole_avtgrp'>

                  <AvatarGroup className='subd_avtgrp' id='scrolling_avatars' max={100}>
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />
                    <Avatar alt="Remy Sharp" src={require('../assets/images/faces/pf.png')} />

                  </AvatarGroup>

                  <div className='subd_singleavttxt'>
                    <div className='subd_single_avt'>
                      {(Data?.companylogo == null || Data?.companylogo == undefined) ?
                        // <img src={require('../assets/images/faces/logo.png')} />
                        <div class="header_logo_align"></div>
                        :
                        <img src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)} />
                      }
                    </div>
                    <div className='ml-3'>

                      <p className='subd_universal'>Universal Studio Singapore</p>
                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

        </div>
      </div>

      <div className='subd_collectall'>
        {Data?.EventDeatils == "" || Data?.EventDeatils == null || Data?.EventDeatils == undefined ?
          <>
            <h4 className='subd_jointr_ttl'>Collect ALL 7 of Them </h4>
            <h4 className='subd_jointr_ttl subd_ttlminus'>and receive one mystery Prize  </h4>
          </>
          :
          <h4 className='subd_jointr_ttl'>{ReactHTMLParser(Data?.EventDeatils)}</h4>
        }


        <div className='container subd_collectallcont'>
          <div className='blue_blurdark clct_all'></div>
          <div className='d-block d-sm-none'>
            {Data?.NoOfNfts?.length > 0 ?
              <Swiper style={{ overflow: "hidden" }} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                delay: 1500,
                disableOnInteraction: false,
              }}
                slidesPerView={1}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  576: {
                    slidesPerView: 1,
                  }
                }}
                spaceBetween={20} className="mySwiper subd_cardswipe mt-5">
                {Data?.NoOfNfts?.length > 0 &&
                  Data?.NoOfNfts?.map((item, index) => {
                    { console.log("sdvdfsvmsdknjfksdnf", item); }
                    return (

                      <SwiperSlide>
                        <div class="card sub_domaincard">
                          <div className='sub_domaincard_rel'>
                            {/* <div className='sub_domaincard_absdtl'>
                        <p className='sub_domaincard_txt'>Highest Bid</p>
                        <div className='d-flex justify-content-start align-items-center'>
                          <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />

                          <p className='sub_domaincard_bold'>6.54</p>
                        </div>
                      </div> */}
                          </div>
                          {item?.image != "" ?
                            <>
                              {item?.image != "" &&
                                (item?.image.split(".").pop() == "mp4" ? (
                                  <video
                                    id="my-video"
                                    class="img-fluid"
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    preload="auto"
                                    controls disablePictureInPicture controlsList="nodownload"
                                  >
                                    <source src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} type="video/mp4" />
                                  </video>
                                ) : (
                                  <img src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} alt="Collections" className="card-img-top subdomain_cardimg img-fluid" />
                                ))}

                            </>
                            :
                            <>
                              <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />

                            </>
                          }

                          <div class="card-body">
                            <div className='subd_cardwhole'>
                              <div>

                                <h6>{item?.tokenName}</h6>
                                {/* <p>{`@ ${item?.tokenCreatorInfo?.name ? item?.tokenCreatorInfo?.name : item?.tokenCreatorInfo?.curraddress?.slice(0, 7)}`}</p> */}
                              </div>
                              <div>
                                <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                              </div>
                            </div>

                            <button className='subd_clctnowbtn'>Collect Now</button>
                          </div>
                        </div>
                      </SwiperSlide>

                    )
                  })
                }

              </Swiper>
              :
              <>
                <Swiper style={{ overflow: "hidden" }} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                  slidesPerView={1}
                  breakpoints={{
                    320: {
                      slidesPerView: 1.2,
                    },
                    576: {
                      slidesPerView: 1.2,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                    1200: {
                      slidesPerView: 4,
                    },
                  }}
                  spaceBetween={20} className="mySwiper subd_cardswipe mt-5">
                  <SwiperSlide>
                    <div class="card sub_domaincard">
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="card sub_domaincard">
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div class="card sub_domaincard">
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </SwiperSlide>


                </Swiper>
              </>
            }

          </div>
          <div className='d-none d-sm-block'>
            {Data?.NoOfNfts?.length > 0 ?
              <div className='row'>
                {Data?.NoOfNfts?.length > 0 &&
                  Data?.NoOfNfts?.map((item, index) => {
                    { console.log("sdvdfsvmsdknjfksdnf", item); }
                    return (

                      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                        <div class="card sub_domaincard">
                          <div className='sub_domaincard_rel'>
                            {/* <div className='sub_domaincard_absdtl'>
                        <p className='sub_domaincard_txt'>Highest Bid</p>
                        <div className='d-flex justify-content-start align-items-center'>
                          <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />

                          <p className='sub_domaincard_bold'>6.54</p>
                        </div>
                      </div> */}
                          </div>
                          {item?.image != "" ?
                            <>
                              {item?.image != "" &&
                                (item?.image.split(".").pop() == "mp4" ? (
                                  <video
                                    id="my-video"
                                    class="img-fluid"
                                    autoPlay
                                    playsInline
                                    loop
                                    muted
                                    preload="auto"
                                    controls disablePictureInPicture controlsList="nodownload"
                                  >
                                    <source src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} type="video/mp4" />
                                  </video>
                                ) : (
                                  <img src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} alt="Collections" className="card-img-top subdomain_cardimg img-fluid" />
                                ))}

                            </>
                            :
                            <>
                              <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />

                            </>
                          }

                          <div class="card-body">
                            <div className='subd_cardwhole'>
                              <div>

                                <h6>{item?.tokenName}</h6>
                                {/* <p>{`@ ${item?.tokenCreatorInfo?.name ? item?.tokenCreatorInfo?.name : item?.tokenCreatorInfo?.curraddress?.slice(0, 7)}`}</p> */}
                              </div>
                              <div>
                                {(Data?.companylogo == null || Data?.companylogo == undefined) ?
                                  <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                                  :
                                  <img className='subd_cardsublogo' src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)} />
                                }
                                {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                              </div>
                            </div>

                            <button className='subd_clctnowbtn'>Collect Now</button>
                          </div>
                        </div>
                      </div>

                    )
                  })
                }
              </div>
              :
              <>
                <div className='row backimges'>
                  <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <div class="card sub_domaincard">
                      <div className='sub_domaincard_rel'>
                        {/* <div className='sub_domaincard_absdtl'>
                    <p className='sub_domaincard_txt'>Highest Bid</p>
                    <div className='d-flex justify-content-start align-items-center'>
                      <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                      <p className='sub_domaincard_bold'>6.54</p>
                    </div>
                  </div> */}
                      </div>
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </div>
                  <div className='col-xxl-3 col-xl-3 col-lg-4  col-md-6 col-sm-12 col-12'>
                    <div class="card sub_domaincard">
                      <div className='sub_domaincard_rel'>
                        {/* <div className='sub_domaincard_absdtl'>
                    <p className='sub_domaincard_txt'>Highest Bid</p>
                    <div className='d-flex justify-content-start align-items-center'>
                      <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                      <p className='sub_domaincard_bold'>6.54</p>
                    </div>
                  </div> */}
                      </div>
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </div>
                  <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <div class="card sub_domaincard">
                      <div className='sub_domaincard_rel'>
                        {/* <div className='sub_domaincard_absdtl'>
                    <p className='sub_domaincard_txt'>Highest Bid</p>
                    <div className='d-flex justify-content-start align-items-center'>
                      <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                      <p className='sub_domaincard_bold'>6.54</p>
                    </div>
                  </div> */}
                      </div>
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </div>
                  <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <div class="card sub_domaincard">
                      <div className='sub_domaincard_rel'>
                        {/* <div className='sub_domaincard_absdtl'>
                    <p className='sub_domaincard_txt'>Highest Bid</p>
                    <div className='d-flex justify-content-start align-items-center'>
                      <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                      <p className='sub_domaincard_bold'>6.54</p>
                    </div>
                  </div> */}
                      </div>
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </div>



                  <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <div class="card sub_domaincard">
                      <div className='sub_domaincard_rel'>
                        {/* <div className='sub_domaincard_absdtl'>
                    <p className='sub_domaincard_txt'>Highest Bid</p>
                    <div className='d-flex justify-content-start align-items-center'>
                      <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                      <p className='sub_domaincard_bold'>6.54</p>
                    </div>
                  </div> */}
                      </div>
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </div>
                  <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                    <div class="card sub_domaincard">
                      <div className='sub_domaincard_rel'>
                        {/* <div className='sub_domaincard_absdtl'>
                    <p className='sub_domaincard_txt'>Highest Bid</p>
                    <div className='d-flex justify-content-start align-items-center'>
                      <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                      <p className='sub_domaincard_bold'>6.54</p>
                    </div>
                  </div> */}
                      </div>
                      <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                      <div class="card-body">
                        <div className='subd_cardwhole'>
                          <div>
                            <h6>Ordinary Your Self</h6>
                            {/* <p>@jefrryrihana</p> */}
                          </div>
                          {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                        </div>

                        <button className='subd_clctnowbtn'>Collect Now</button>
                      </div>
                    </div>
                  </div>

                </div>

              </>
            }
            {/* <div className='text-center mt-2'>

        <button className='subd_whiteborderbtn'>Explore Now</button>
      </div> */}

          </div>
        </div>
        <div className='container subd_topwinner'>
          {Data?.TopWinnerTittle == "" || Data?.TopWinnerTittle == null || Data?.TopWinnerTittle == undefined ?
            <h4 className='subd_jointr_ttl'>Top Winner this week </h4>
            :
            <h4 className='subd_jointr_ttl'>{Data?.TopWinnerTittle}</h4>

          }

          {/* <p className='subd_topwinner_hint'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
          <div className='d-block d-sm-none'>
            <div className='row winnercardrow'>
              <Swiper style={{ overflow: "hidden" }} loop={true} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                delay: 1500,
                disableOnInteraction: false,

              }}
                slidesPerView={1}
                breakpoints={{
                  320: {
                    slidesPerView: 1,
                  },
                  576: {
                    slidesPerView: 1,
                  }
                }}
                spaceBetween={20} className="mySwiper subd_lengthswipe my-5">
                <SwiperSlide>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                  <div class="card subd_winnercard">
                    <div class="card-body">
                      <div className='subd_winnercard_whole'>
                        <div className='d-flex justify-content-start align-items-center'>
                          {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                          <div class="header_logo_align"></div>
                          <div className='ml-3'>
                            <p className='subd_universal'>Universal Studio Singapore</p>
                            {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                          </div>
                        </div>
                        {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                      </div>

                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <div className='d-none d-sm-block'>
            <div className='row subd_winnercardrow '>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                <div class="card subd_winnercard">
                  <div class="card-body">
                    <div className='subd_winnercard_whole'>
                      <div className='d-flex justify-content-start align-items-center'>
                        {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                        <div class="header_logo_align"></div>
                        <div className='ml-3'>
                          <p className='subd_universal'>Universal Studio Singapore</p>
                          {/* <div className='subd_ethreum'>
                      <img src={require('../assets/images/faces/Frame.png')} />
                      <p className='subd_value'>340,793</p>
                    </div> */}
                        </div>
                      </div>
                      {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='voilet_blurdark'></div>
          <div className='blue_blurdark'></div>

        </div>

        {/* <div className='voilet_blur'></div>
      <div className='blue_blur'></div> */}

      </div>

      <SubDomainFooter />

    </div>
  </>
        :
        <>
          {Data?.template == "Design Template" ?
            <>
              <div className='home header_bar_align subdomain_des prev_sub margins tempflow'  >

                <button
                  className="graybtn btn mr-2 mb-4" data-toggle="modal" data-target="#addcampaingModal"
                  onClick={() => ShowModelssss()}
                >
                  Back
                </button>




                <div className="container pt-5 pb-4">

                  <div className="tempnew d-flex justify-content-between mb-4">
                  {(Data?.companylogo == null || Data?.companylogo == undefined) ?
              // <img src={banner_logo} alt='banner_img' className='subd_logo companylogo img-fluid' />
              <img className='subd_logo' src={require('../assets/images/faces/logo.png')} />
              :
              <img className='subd_logo companylogo  img-fluid' src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)} />
            }
                    

                    {/* <div className="temptheme">
                      {localStorage.getItem("theme1") == "LT" || localStorage.getItem("theme1") == undefined ?
                        <Button onClick={() => { themechange("DT") }}>
                          <p className="light svg_lft_align"><Brightness3 /></p>
                        </Button> :
                        <Button onClick={() => { themechange("LT") }}>
                          <p className="dark svg_lft_align"><WbSunny /></p>
                        </Button>}
                    </div> */}
                  </div>

                  {/* Mobile */}
                  <div className="mobilelist">
                    <div className="mobilefullimage">
                      <img src={petimage} class="subimage w-100" alt="..." />
                      {<AiOutlineHeart className="heartcolor" />
                        //  <AiFillHeart className="heartcolor"/>
                      }
                      {Data?.bannerTittle == "" || Data?.bannerTittle == null || Data?.bannerTittle == undefined ?
                        <h4 class="subd_jointr_ttl mobiledesign   text-center">Design Your Pet’s Passport Here and Collect As NFT</h4>
                        :

                        <h3 className='subd_jointr_ttl mobiledesign  text-center'>{Data?.bannerTittle}</h3>
                      }




                    </div>
                  </div>


                  <div className="row pt-3 pb-5 align-items-center justify-content-between">
                    <div className="col-md-12 col-lg-7 col-xl-7 mt-3">
                      {console.log("asdasdasdasdas", Data)}
                      {Data?.bannerTittle == "" || Data?.bannerTittle == null || Data?.bannerTittle == undefined ?
                        <h4 class="subd_jointr_ttl ftitle mb-4  text-center">Design Your Pet’s Passport Here and Collect As NFT</h4>
                        :

                        <h3 className='subd_jointr_ttl ftitle mb-4  text-center'>{Data?.bannerTittle}</h3>
                      }

                      {/* <h4 class="subd_jointr_ttl ftitle mb-4  text-center">Design Your Pet’s Passport Here and Collect As NFT</h4> */}


                      <div className="row pt-3">
                        <div className="col-sm-6 col-md-6 mt-3">
                          <div class="form-group">
                            {Data?.NameField == "" || Data?.NameField == null || Data?.NameField == undefined ?
                              <label for="exampleInputEmail1">Name of Pet *</label>
                              :
                              <label for="exampleInputEmail1">{Data?.NameField}</label>
                            }

                            <input type="text" class="form-control" placeholder='Patrick' id="name" onChange={OnChangehandle} />
                          </div>
                        </div>
                        {!Data?.DiscriptionStatus &&
                          <div className="col-sm-6 col-md-6 mt-3">
                            <div class="form-group">
                              {Data?.Discription == "" || Data?.Discription == null || Data?.Discription == undefined ?
                                <label for="exampleInputEmail1">Likes* (7 words to describe)</label>
                                :
                                <label for="exampleInputEmail1">{Data?.Discription}</label>
                              }

                              <input type="text" class="form-control" placeholder='Describe' id="describe" onChange={OnChangehandle} />
                            </div>
                          </div>
                        }
                        {!Data?.DobFieldStatus &&
                          <div className="col-sm-6 col-md-6 mt-2">
                            {Data?.DobField == "" || Data?.DobField == null || Data?.DobField == undefined ?
                              <label for="exampleInputEmail1">Date of Birth*</label>
                              :
                              <label for="exampleInputEmail1">{Data?.DobField}</label>
                            }

                            <div>  <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} /></div>

                          </div>
                        }
                        {!Data?.GenderStatus &&
                          <div className="col-sm-6 col-md-6 mt-2">
                            {Data?.GenderDropDown == "" || Data?.GenderDropDown == null || Data?.GenderDropDown == undefined ?
                              <label for="exampleInputEmail1">Gender*</label>
                              :
                              <label for="exampleInputEmail1">{Data?.GenderDropDown}</label>
                            }

                            <div>
                              {/* <select
                                            name="gender"
                                            onSelect={handleSelect}
                                        >
                                            <option id="category" value="Male">Male</option>
                                            <option id="category" value="Female">Female</option>
                                        </select> */}
                              <Dropdown className="allselectmenu" onSelect={handleSelect}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  {Formdata?.genders ? Formdata?.genders : ""}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                  {Data && Data?.Dropdown2Category?.length > 0 &&
                                    Data?.Dropdown1Category?.map((item, index) => {
                                      return (
                                        <Dropdown.Item id="genders" eventKey={item?.value} >{item?.value}</Dropdown.Item>
                                      )
                                    })

                                  }

                                </Dropdown.Menu>

                              </Dropdown>
                            </div>
                          </div>
                        }
                        {console.log("Asdasdasdadwwq", !Data?.WeightStatus, !Data?.HeightStatus)}
                        {((Data?.WeightStatus !== true || Data?.WeightStatus !== "true") && (Data?.HeightStatus !== true || Data?.HeightStatus !== "true")) &&
                          <div className="col-sm-6 col-md-6 mt-4">
                            <div className="row pt-0">
                              {!Data?.WeightStatus &&
                                <div className="col-6 col-sm-6 col-md-6 mt-0">
                                  {Data?.Weight == "" || Data?.Weight == null || Data?.Weight == undefined ?
                                    <label for="exampleInputEmail1">Weight *</label>
                                    :
                                    <label for="exampleInputEmail1">{Data?.Weight}</label>
                                  }

                                  <div class="input-group mb-0">

                                    <input type="number" id="weight" onChange={OnChangehandle} class="form-control" />
                                    <div class="input-group-append">
                                      <span class="input-group-text" id="basic-addon2">kg</span>
                                    </div>
                                  </div>
                                </div>
                              }
                              {!Data?.HeightStatus &&
                                <div className="col-6 col-sm-6 col-md-6 mt-0">
                                  {Data?.Height == "" || Data?.Height == null || Data?.Height == undefined ?
                                    <label for="exampleInputEmail1">Height *</label>
                                    :
                                    <label for="exampleInputEmail1">{Data?.Height}</label>
                                  }

                                  <div class="input-group mb-0">

                                    <input type="number" class="form-control" />
                                    <div class="input-group-append">
                                      <span class="input-group-text" id="basic-addon2">cm</span>
                                    </div>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        }
                        {!Data?.BreadStatus &&
                          <div className="col-md-6 col-sm-6 mt-4">
                            {Data?.BreadDropDown == "" || Data?.BreadDropDown == null || Data?.BreadDropDown == undefined ?
                              <label for="exampleInputEmail1">Breed *</label>
                              :
                              <label for="exampleInputEmail1">{Data?.BreadDropDown}</label>
                            }


                            {/* <div>
                                        <select
                                            name="gender"
                                            onChange={handleChange}
                                            defaultValue={gender}
                                            required
                                        >
                                            <option value="Male">Toy Poodle</option>
                                            <option value="Female">Toy Poodle11</option>
                                        </select></div> */}

                            <Dropdown className="allselectmenu" onSelect={handleSelect}>
                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                {Formdata?.Breed ? Formdata?.Breed : ""}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                {Data && Data?.Dropdown3Category?.length > 0 &&
                                  Data?.Dropdown2Category?.map((item, index) => {
                                    return (
                                      <Dropdown.Item id="Breed" eventKey={item?.value} >{item?.value}</Dropdown.Item>
                                    )
                                  })

                                }

                                {/* <Dropdown.Item id="Breed" eventKey="Another action">Another action</Dropdown.Item>
                                                <Dropdown.Item id="Breed" eventKey="Something else">Something else</Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>

                          </div>
                        }
                        {console.log("adfasdsdadasd", Data)}
                        {!Data?.VaccineStatus &&
                          <div className="col-sm-6 col-md-6 mt-4">

                            {Data?.vaccine == "" || Data?.vaccine == null || Data?.vaccine == undefined ?
                              <label for="exampleInputEmail1">Vaccine *</label>
                              :
                              <label for="exampleInputEmail1">{Data?.vaccine}</label>
                            }

                            <div>
                              {/* <select
                                            placeholder="Types of Vaccines"
                                        >
                                            <option value="1" disabled selected>Types of Vaccines</option>
                                            <option value="2">Vaccines</option>
                                            <option value="2">Vaccines1</option>
                                        </select> */}
                              <Dropdown className="allselectmenu" onSelect={handleSelect}>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                  {Formdata?.vaccine ? Formdata?.vaccine : ""}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {Data && Data?.Dropdown1Category?.length > 0 &&
                                    Data?.Dropdown3Category?.map((item, index) => {
                                      return (
                                        <Dropdown.Item id="vaccine" eventKey={item?.value} >{item?.value}</Dropdown.Item>
                                      )
                                    })

                                  }
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        }
                        {!Data?.ChipStatus &&
                          <div className="col-sm-6 col-md-6 mt-4">
                            <div class="form-group">
                              {Data?.Chip == "" || Data?.Chip == null || Data?.Chip == undefined ?
                                <label for="exampleInputEmail1">Chip # (Optional)</label>
                                :
                                <label for="exampleInputEmail1">{Data?.Chip}</label>
                              }

                              <input class="form-control" placeholder="Ex: 977200000000000" id="chipno" onChange={OnChangehandle} />
                            </div>
                          </div>
                        }
                      </div>


                      <div className="row pt-0">
                        {!Data?.MailStatus &&
                          <div className="col-sm-6 col-md-6 mt-3">
                            <div class="form-group">
                              {Data?.Mail == "" || Data?.Mail == null || Data?.Mail == undefined ?
                                <label for="exampleInputEmail1">Owner Contact Email *</label>
                                :
                                <label for="exampleInputEmail1">{Data?.Mail}</label>
                              }

                              <input type="email" class="form-control"
                                id="mail" placeholder="drchinp@gmail.com" onChange={OnChangehandle} />
                            </div>
                          </div>
                        }
                        {!Data?.ContactNumStatus &&
                          <div className="col-sm-6 col-md-6 mt-3">
                            {Data?.ContactNum == "" || Data?.ContactNum == null || Data?.ContactNum == undefined ?
                              <label for="exampleInputEmail1">Mobile Number *</label>
                              :
                              <label for="exampleInputEmail1">{Data?.ContactNum}</label>
                            }

                            <div>
                              <PhoneInput
                                international
                                defaultCountry="SG"
                                value={Formdata.ContactNumber}
                                onChange={setValue} />
                            </div>
                          </div>
                        }
                        {!Data?.FileStatus &&
                          <div className="col-sm-6 col-md-6 mt-3">
                            {Data?.FileField == "" || Data?.FileField == null || Data?.FileField == undefined ?
                              <label for="exampleInputEmail1" className="mb-0">Your Pet’s Photo *</label>
                              :
                              <label for="exampleInputEmail1">{Data?.FileField}</label>
                            }

                            <div>
                              {Data?.FileDiscriptionField == "" || Data?.FileDiscriptionField == null || Data?.FileDiscriptionField == undefined ?
                                <label>Please Ensure a Front Facing Photo<br></br> Image Dimension (jpg, jpeg, png (xx . X  xx)</label>
                                :
                                <label for="exampleInputEmail1">{Data?.FileDiscriptionField}</label>
                              }

                            </div>
                          </div>
                        }
                        <div className="col-sm-6 col-md-6 mt-3">
                          <div className="row">
                            <div className="col-md-6 mt-2"> <p className="uploadname">
                              {/* {state.fileData ? state.fileData.name : null} */}
                              {imagename}
                            </p> </div>
                            <div className="col-md-6 mt-2 browsebtn">
                              {/* <input type="file" name="file" className="uploadss" onChange={handleChange1} />
                                            <button className='subd_clctnowbtn' >Upload</button> */}
                              <input type="file" name="file" className="uploadss" ref={fileInputRef} onChange={handleFilesSelected} />
                              <button className='subd_clctnowbtn' onClick={handleFileUpload}>Upload</button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="allcheck">
                            <input class="form-check-input" type="checkbox" id="Terms" onClick={(e) => CheckBoxSelect(e)} />
                            {Data?.CheckBox1 == "" || Data?.CheckBox1 == null || Data?.CheckBox1 == undefined ?
                              <label class="form-check-label" for="defaultCheck1">
                                I agree to all statements included in
                                Terms of Conditions and Use
                              </label>
                              :
                              <label class="form-check-label" for="defaultCheck1">{Data?.CheckBox1}</label>
                            }

                          </div>
                          <div className="allcheck">
                            <input class="form-check-input" id="Privacy" type="checkbox" onClick={(e) => CheckBoxSelect(e)} />
                            {Data?.CheckBox2 == "" || Data?.CheckBox2 == null || Data?.CheckBox2 == undefined ?
                              <label class="form-check-label" for="defaultCheck1">
                                I want free 30 days coverage for my pet
                                Insurance Coverage Details and Conditions
                              </label>
                              :
                              <label for="exampleInputEmail1">{Data?.CheckBox2}</label>
                            }

                          </div>

                        </div>
                        <div className="col-md-6 mt-4">
                          <div className="row">
                            <div className="col-6 prevv mb-2"><button className='subd_clctnowbtn'
                              onClick={() => handleShow1()}
                            >Preview</button></div>
                            <div className="col-6 col-sm-12 col-md-12 mb-2"><button className='subd_clctnowbtn'>Submit</button></div>
                          </div>
                          {/* <button className='subd_clctnowbtn'>Submit</button> */}
                        </div>
                      </div>

                    </div>


                    <div className="col-md-12 col-lg-5 col-xl-4 ftitle mt-3">
                      <div className="d-flex justify-content-center">
                        <h4 class="subd_jointr_ttl previewtext  text-center">Dynamic Preview <FaEye className="eyess" /></h4>
                        {/* <FaDownload className="fadownlod" onClick={handleCapture} /> */}
                      </div>
                      <PreviewComp />

                    </div>

                    {/* <FaDownload className="fadownlod" onClick={handleCapture} /> */}

                    <div className="">
                      <Modal show={showss} onHide={handleClose1} className="mobilemodal">
                        <Modal.Header>
                          <Modal.Title><GrFormClose onClick={handleClose1} /></Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="d-flex justify-content-between mt-3 px-2">
                            <h4 class="subd_jointr_ttl previewtext  text-center">Dynamic Preview <FaEye className="eyess" /></h4>
                          </div>
                          <PreviewComp />
                        </Modal.Body>

                      </Modal>
                    </div>

                  </div>

                  {Data?.middlebannertittle == "" || Data?.middlebannertittle == null || Data?.middlebannertittle == undefined ?
                    <h4 class="subd_jointr_ttl  text-center pt-4">Congratulations!!<br></br>
                    </h4>
                    :
                    <h4 className='subd_jointr_ttl  text-center'>{Data?.middlebannertittle}<br></br></h4>
                  }
                  {/* <h4 className='subd_jointr_ttl'>Collect Your NFT Tokens </h4> */}
                  {Data?.middlebannerdescription == "" || Data?.middlebannerdescription == null || Data?.middlebannerdescription == undefined ?
                    <h4 class="subd_jointr_ttl  text-center">
                      Click or Scan the QR Code to Drop Your Pet Passport token Into Your Wallet Now...</h4>
                    :
                    <h4 className='subd_jointr_ttl  text-center'>{ReactHTMLParser(Data?.middlebannerdescription)}</h4>
                  }


                  <div className="subd_nointrcontbg">
                    <div className="row align-items-center">
                      <div className="col-md-5 text-center mt-2">
                        <QRCode size={200} fgColor="black" bgColor="white" value="https://lazyminter.com/" />
                        <h4 class="subd_jointr_ttl mt-3">Click or Scan</h4>
                        <h4 class="subd_jointr_ttl mt-3r">Now</h4>
                      </div>
                      <div className="col-md-7 text-center mt-2">

                        {Data?.middlebannerdescriptionTittle == "" || Data?.middlebannerdescriptionTittle == null || Data?.middlebannerdescriptionTittle == undefined ?
                          <h5 class="subd_jointr_cntttl ">Collect Your Pet Passport Guide</h5>
                          :
                          <h5 className='subd_jointr_cntttl'>{Data?.middlebannerdescriptionTittle}</h5>

                        }

                        {Data?.middlebannerdetails == "" || Data?.middlebannerdetails == null || Data?.middlebannerdetails == undefined ?
                          <>


                            <p className="subtit mt-3">1. Register an Account at LazyMinter if You Don’t Have One
                            </p>
                            <p className="white-sub mt-3">Register an account at LazyMinter with the same email you had registered for your pet passport.
                            </p>
                            <p className="subtit mt-3">2. Create Your Wallet (Recommend LM (Lazyminter) Wallet) or Use Your Existing
                            </p>
                            <p className="white-sub mt-3">You have an option to use a custodial wallet (Recommend LM (Lazyminter) Wallet) or your own metamask wallet.
                            </p>
                            <p className="subtit mt-3">3. Check your Pet Passport Token and Click ‘Claim’

                            </p>
                            <p className="white-sub mt-3">Please be patient as we are writing the digital passport on blockchain and will airdrop into your wallet.  Check under my items and VOILA, now you can use the pet passport for other benefits
                            </p>
                          </>
                          :
                          <p className='subd_jointr_cnthint'>{ReactHTMLParser(Data?.middlebannerdetails)}</p>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div className='subd_collectall'>
                  {Data?.EventDeatils == "" || Data?.EventDeatils == null || Data?.EventDeatils == undefined ?
                    <>
                      <h4 className='subd_jointr_ttl'>Collect ALL 7 of Them </h4>
                      <h4 className='subd_jointr_ttl subd_ttlminus'>and receive one mystery Prize  </h4>
                    </>
                    :
                    <h4 className='subd_jointr_ttl'>{ReactHTMLParser(Data?.EventDeatils)}</h4>
                  }
                  {/* <h4 className='subd_jointr_ttl'>Collect ALL 7 of Them </h4>
              <h4 className='subd_jointr_ttl subd_ttlminus'>and receive one mystery Prize  </h4> */}


                  <div className='container subd_collectallcont'>
                    <div className='blue_blurdark clct_all'></div>
                    <div className='d-block d-sm-none'>
                      {Data?.NoOfNfts?.length > 0 ?
                        <Swiper style={{ overflow: "hidden" }} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,
                        }}
                          slidesPerView={1}
                          breakpoints={{
                            320: {
                              slidesPerView: 1,
                            },
                            576: {
                              slidesPerView: 1,
                            }
                          }}
                          spaceBetween={20} className="mySwiper subd_cardswipe mt-5">
                          {Data?.NoOfNfts?.length > 0 &&
                            Data?.NoOfNfts?.map((item, index) => {
                              { console.log("sdvdfsvmsdknjfksdnf", item); }
                              return (

                                <SwiperSlide>
                                  <div class="card sub_domaincard">
                                    <div className='sub_domaincard_rel'>
                                      {/* <div className='sub_domaincard_absdtl'>
                  <p className='sub_domaincard_txt'>Highest Bid</p>
                  <div className='d-flex justify-content-start align-items-center'>
                    <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />

                    <p className='sub_domaincard_bold'>6.54</p>
                  </div>
                </div> */}
                                    </div>
                                    {item?.image != "" ?
                                      <>
                                        {item?.image != "" &&
                                          (item?.image.split(".").pop() == "mp4" ? (
                                            <video
                                              id="my-video"
                                              class="img-fluid"
                                              autoPlay
                                              playsInline
                                              loop
                                              muted
                                              preload="auto"
                                              controls disablePictureInPicture controlsList="nodownload"
                                            >
                                              <source src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} type="video/mp4" />
                                            </video>
                                          ) : (
                                            <img src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} alt="Collections" className="card-img-top subdomain_cardimg img-fluid" />
                                          ))}

                                      </>
                                      :
                                      <>
                                        <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />

                                      </>
                                    }

                                    <div class="card-body">
                                      <div className='subd_cardwhole'>
                                        <div>

                                          <h6>{item?.tokenName}</h6>
                                          {/* <p>{`@ ${item?.tokenCreatorInfo?.name ? item?.tokenCreatorInfo?.name : item?.tokenCreatorInfo?.curraddress?.slice(0, 7)}`}</p> */}
                                        </div>
                                        <div>
                                          <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                                        </div>
                                      </div>

                                      <button className='subd_clctnowbtn'>Collect Now</button>
                                    </div>
                                  </div>
                                </SwiperSlide>

                              )
                            })
                          }

                        </Swiper>
                        :
                        <>
                          <Swiper style={{ overflow: "hidden" }} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                            delay: 1500,
                            disableOnInteraction: false,
                          }}
                            slidesPerView={1}
                            breakpoints={{
                              320: {
                                slidesPerView: 1.2,
                              },
                              576: {
                                slidesPerView: 1.2,
                              },
                              768: {
                                slidesPerView: 2,
                              },
                              992: {
                                slidesPerView: 3,
                              },
                              1200: {
                                slidesPerView: 4,
                              },
                            }}
                            spaceBetween={20} className="mySwiper subd_cardswipe mt-5">
                            <SwiperSlide>
                              <div class="card sub_domaincard">
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div class="card sub_domaincard">
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div class="card sub_domaincard">
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </SwiperSlide>


                          </Swiper>
                        </>
                      }

                    </div>
                    <div className='d-none d-sm-block'>
                      {Data?.NoOfNfts?.length > 0 ?
                        <div className='row'>
                          {Data?.NoOfNfts?.length > 0 &&
                            Data?.NoOfNfts?.map((item, index) => {
                              { console.log("sdvdfsvmsdknjfksdnf", item); }
                              return (

                                <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                                  <div class="card sub_domaincard">
                                    <div className='sub_domaincard_rel'>
                                      {/* <div className='sub_domaincard_absdtl'>
                  <p className='sub_domaincard_txt'>Highest Bid</p>
                  <div className='d-flex justify-content-start align-items-center'>
                    <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />

                    <p className='sub_domaincard_bold'>6.54</p>
                  </div>
                </div> */}
                                    </div>
                                    {item?.image != "" ?
                                      <>
                                        {item?.image != "" &&
                                          (item?.image?.split(".")?.pop() == "mp4" ? (
                                            <video
                                              id="my-video"
                                              class="img-fluid"
                                              autoPlay
                                              playsInline
                                              loop
                                              muted
                                              preload="auto"
                                              controls disablePictureInPicture controlsList="nodownload"
                                            >
                                              <source src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} type="video/mp4" />
                                            </video>
                                          ) : (
                                            <img src={`${config?.Back_URL}/nftImg/${item?.tokenCreator}/${item?.image}`} alt="Collections" className="card-img-top subdomain_cardimg img-fluid" />
                                          ))}

                                      </>
                                      :
                                      <>
                                        <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />

                                      </>
                                    }

                                    <div class="card-body">
                                      <div className='subd_cardwhole'>
                                        <div>

                                          <h6>{item?.tokenName}</h6>
                                          {/* <p>{`@ ${item?.tokenCreatorInfo?.name ? item?.tokenCreatorInfo?.name : item?.tokenCreatorInfo?.curraddress?.slice(0, 7)}`}</p> */}
                                        </div>
                                        <div>
                                          {(Data?.companylogo == null || Data?.companylogo == undefined) ?
                                            <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} />
                                            :
                                            <img className='subd_cardsublogo' src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)} />
                                          }
                                          {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                        </div>
                                      </div>

                                      <button className='subd_clctnowbtn'>Collect Now</button>
                                    </div>
                                  </div>
                                </div>

                              )
                            })
                          }
                        </div>
                        :
                        <>
                          <div className='row backimges'>
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                              <div class="card sub_domaincard">
                                <div className='sub_domaincard_rel'>
                                  {/* <div className='sub_domaincard_absdtl'>
              <p className='sub_domaincard_txt'>Highest Bid</p>
              <div className='d-flex justify-content-start align-items-center'>
                <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                <p className='sub_domaincard_bold'>6.54</p>
              </div>
            </div> */}
                                </div>
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='col-xxl-3 col-xl-3 col-lg-4  col-md-6 col-sm-12 col-12'>
                              <div class="card sub_domaincard">
                                <div className='sub_domaincard_rel'>
                                  {/* <div className='sub_domaincard_absdtl'>
              <p className='sub_domaincard_txt'>Highest Bid</p>
              <div className='d-flex justify-content-start align-items-center'>
                <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                <p className='sub_domaincard_bold'>6.54</p>
              </div>
            </div> */}
                                </div>
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                              <div class="card sub_domaincard">
                                <div className='sub_domaincard_rel'>
                                  {/* <div className='sub_domaincard_absdtl'>
              <p className='sub_domaincard_txt'>Highest Bid</p>
              <div className='d-flex justify-content-start align-items-center'>
                <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                <p className='sub_domaincard_bold'>6.54</p>
              </div>
            </div> */}
                                </div>
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                              <div class="card sub_domaincard">
                                <div className='sub_domaincard_rel'>
                                  {/* <div className='sub_domaincard_absdtl'>
              <p className='sub_domaincard_txt'>Highest Bid</p>
              <div className='d-flex justify-content-start align-items-center'>
                <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                <p className='sub_domaincard_bold'>6.54</p>
              </div>
            </div> */}
                                </div>
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </div>



                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                              <div class="card sub_domaincard">
                                <div className='sub_domaincard_rel'>
                                  {/* <div className='sub_domaincard_absdtl'>
              <p className='sub_domaincard_txt'>Highest Bid</p>
              <div className='d-flex justify-content-start align-items-center'>
                <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                <p className='sub_domaincard_bold'>6.54</p>
              </div>
            </div> */}
                                </div>
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </div>
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12'>
                              <div class="card sub_domaincard">
                                <div className='sub_domaincard_rel'>
                                  {/* <div className='sub_domaincard_absdtl'>
              <p className='sub_domaincard_txt'>Highest Bid</p>
              <div className='d-flex justify-content-start align-items-center'>
                <img className='mr-1' src={require('../assets/images/faces/Frame.png')} />
                <p className='sub_domaincard_bold'>6.54</p>
              </div>
            </div> */}
                                </div>
                                <img src={require('../assets/images/faces/image2.png')} class="card-img-top subdomain_cardimg" alt="" />
                                <div class="card-body">
                                  <div className='subd_cardwhole'>
                                    <div>
                                      <h6>Ordinary Your Self</h6>
                                      {/* <p>@jefrryrihana</p> */}
                                    </div>
                                    {/* <img className='subd_cardsublogo' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                  </div>

                                  <button className='subd_clctnowbtn'>Collect Now</button>
                                </div>
                              </div>
                            </div>

                          </div>

                        </>
                      }
                      {/* <div className='text-center mt-2'>

  <button className='subd_whiteborderbtn'>Explore Now</button>
</div> */}

                    </div>
                  </div>
                  <div className='container subd_topwinner'>
                    {Data?.TopWinnerTittle == "" || Data?.TopWinnerTittle == null || Data?.TopWinnerTittle == undefined ?
                      <h4 className='subd_jointr_ttl'>Top Winner this week </h4>
                      :
                      <h4 className='subd_jointr_ttl'>{Data?.TopWinnerTittle}</h4>

                    }

                    {/* <p className='subd_topwinner_hint'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt </p> */}
                    <div className='d-block d-sm-none'>
                      <div className='row winnercardrow'>
                        <Swiper style={{ overflow: "hidden" }} loop={true} pagination={true} modules={[Autoplay, Pagination, Navigation]} autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,

                        }}
                          slidesPerView={1}
                          breakpoints={{
                            320: {
                              slidesPerView: 1,
                            },
                            576: {
                              slidesPerView: 1,
                            }
                          }}
                          spaceBetween={20} className="mySwiper subd_lengthswipe my-5">
                          <SwiperSlide>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                            <div class="card subd_winnercard">
                              <div class="card-body">
                                <div className='subd_winnercard_whole'>
                                  <div className='d-flex justify-content-start align-items-center'>
                                    {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                    <div class="header_logo_align"></div>
                                    <div className='ml-3'>
                                      <p className='subd_universal'>Universal Studio Singapore</p>
                                      {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                    </div>
                                  </div>
                                  {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                                </div>

                              </div>
                            </div>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                    </div>
                    <div className='d-none d-sm-block'>
                      <div className='row subd_winnercardrow '>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12'>
                          <div class="card subd_winnercard">
                            <div class="card-body">
                              <div className='subd_winnercard_whole'>
                                <div className='d-flex justify-content-start align-items-center'>
                                  {/* <img className='subd_cardsublogoal' src={require('../assets/images/faces/logo.png')} /> */}
                                  <div class="header_logo_align"></div>
                                  <div className='ml-3'>
                                    <p className='subd_universal'>Universal Studio Singapore</p>
                                    {/* <div className='subd_ethreum'>
                <img src={require('../assets/images/faces/Frame.png')} />
                <p className='subd_value'>340,793</p>
              </div> */}
                                  </div>
                                </div>
                                {/* <i class="fa-solid fa-ellipsis text-white"></i> */}

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='voilet_blurdark'></div>
                    <div className='blue_blurdark'></div>

                  </div>

                  {/* <div className='voilet_blur'></div>
<div className='blue_blur'></div> */}

                </div>





                <SubDomainFooter />

              </div>
            </>
            :
            <>
              {/* wow */}
              {console.log("Data...........", Data)}
              {console.log("Data?.companylogo", Data?.companylogo)}
              {/* <section className="lyt_inr_page" style={{ backgroundColor: Data.backgroundcolor }}> */}
               <div className='home_header1 home header_bar_align subdomain_des prev_sub margins' >
                <button
                  className="graybtn btn mr-2 mb-4" data-toggle="modal" data-target="#addcampaingModal"
                  onClick={() => ShowModelssss()}
                >
                  Back
                </button>
                {/* <Container className="cust_container container"> */}

                  <Container className='container'>
                  {/* start of header */}

                  <div className="d-flex align-items-center justify-content-center justify-content-lg-between">
                    <div className="lyt_img_wrp">
                      {(Data?.companylogo == null || Data?.companylogo == undefined) ? (
                        <img src={headerLogo} alt="Logo" className="img-fluid" />
                      ) : (
                        <img
                          className="img-fluid"
                          src={typeof Data?.companylogo == "string" ? `${config?.Back_URL}/${Data?.companylogo}` : URL?.createObjectURL(Data?.companylogo)}
                        />
                      )}
                    </div>
                    <div className="d-none d-lg-block">
                      <h5 className="lyt_hds_tle text-center text-lg-start">{Data?.categoryTittle ? Data?.categoryTittle : "AetherOne Loyalty Program"}</h5>
                      <p className="text-center lyt_hds_subtle">
                        {Data?.middlebannertittle ? Data?.middlebannertittle : "Where Every Point is a Promise Kept"}
                      </p>
                    </div>
                    <div className="d-lg-flex align-items-lg-center lyt_hds_rgt d-none">
                      <IoSearchSharp className="lyt_hds_srch mr-4 d-none" />
                      <div className="lyt_crd_img_wrp mr-4 d-none">
                        <img src={cred} alt="credentials" className="img_fit_cvr" />
                      </div>
                      <LuWallet2 className="lyt_hds_wlt mr-4 d-none" />
{/* 
                      {localStorage.getItem("theme1") == "LT" ||
                        localStorage.getItem("theme1") == undefined ? (
                        <div
                          onClick={() => {
                            themechange("DT");
                          }}
                        >
                          <IoSunnyOutline className="lyt_hds_theme" />
                        </div>
                      ) : (
                        <div
                          onClick={() => {
                            themechange("LT");
                          }}
                        >
                          <IoSunnyOutline className="lyt_hds_theme" />
                        </div>
                      )} */}
                      {/* <Button
            onClick={() => {
              themechange("DT");
            }}
          >
            <IoSunnyOutline className="lyt_hds_theme" />
          </Button> */}
                    </div>
                  </div>

                  {/* end of header */}
                  {/* start of banner section */}

                  <div className="lyt_banner my-4  my-xl-5" style={{ backgroundColor: Data.backgroundcolor }}>
                    <Row className="d-flex flex-lg-row flex-column-reverse">
                      <Col lg={6}>
                        <div>
                          {Data?.bannerTittle == "" ||
                            Data?.bannerTittle == null ||
                            Data?.bannerTittle == undefined ? (
                            <h3
                              className="text-center lyt_h3 mt-5 mt-lg-0"
                            // data-aos="fade-up"
                            // data-aos-duration="1500"
                            >
                              Unlocking Rewards,
                              <br /> Securing Trust
                            </h3>
                          ) : (
                            <h3
                              className="text-center lyt_h3 mt-5 mt-lg-0"
                            // data-aos="fade-up"
                            // data-aos-duration="1500"
                            >
                              {Data?.bannerTittle}
                            </h3>
                          )}

                          {Data?.bannerdiscription == "" ||
                            Data?.bannerdiscription == null ||
                            Data?.bannerdiscription == undefined ? (
                            <p
                              className="lyt_bnr_p  mt-4 mt-xl-5"
                            // data-aos="fade-up"
                            // data-aos-duration="2000"
                            >
                              Join Aether One's Web3 loyalty program and dive into a
                              professional accreditation adventure with our specially
                              curated learning modules. Start by finding the "Knowledge
                              Explorer Token" in your LM wallet, which opens up a unique
                              learning experience. Participate in various journeys to earn
                              your rewards and the satisfaction of a well-earned prize at
                              various stages of the journeys.
                            </p>
                          ) : (
                            <p
                              className="lyt_bnr_p  mt-4 mt-xl-5"
                            // data-aos="fade-up"
                            // data-aos-duration="2000"
                            >
                              {ReactHTMLParser(Data.bannerdiscription)}
                            </p>
                          )}
                          <button
                            className="ylw_btn mt-4 mt-xl-5"
                          // data-aos="zoom-in"
                          // data-aos-duration="1000"
                          >
                            Collect Now
                          </button>
                        </div>
                      </Col>
                      <Col
                        lg={6}
                        className="d-flex justify-content-center align-items-center mt-5 mt-lg-0"
                      >
                        <div>
                          {viewBanner && (
                            <div className="lyt_bnr_img_wrp">
                              {(Data?.bannerImage == null ||
                                Data?.bannerImage == undefined) ? (
                                // <ThreeDots
                                //   height="70"
                                //   width="70"
                                //   radius="9"
                                //   color="#FFC700"
                                //   ariaLabel="three-dots-loading"
                                //   wrapperStyle={{}}
                                //   wrapperClassName=""
                                //   visible={true}
                                // />
                                <img
                                  src={bannerImg}
                                  alt="Loyalty"
                                  className="img-fluid img_fit_cvr"
                                />
                              ) : (
                                <img
                                  alt="Loyalty"
                                  className="img-fluid img_fit_cvr"
                                  src={typeof Data?.bannerImage == "string" ? `${config?.Back_URL}/${Data?.bannerImage}` : URL?.createObjectURL(Data?.bannerImage)}
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {/* end of banner section */}

                  {/* start of PDU Points */}

                  {console.log("sdfvsdfvsdvsdvsdf", Data)}
                  <div>
                    <h5 className="lyt_pdu_pnts_tle mt-5 mb-4">
                      1080 {Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | Level <span>{Data?.Dropdown2Category.length > 0 ? Data?.Dropdown2Category?.map((level) => level.label) :
                        "Bronze"}</span>
                    </h5>

                    <div className="">
                      <Row>
                        <Col lg={6} xl={5} className="col-xxl-4">
                          {console.log("PDUdata", PDuDatas)}
                          {Data.Dropdown1Category?.map((value, index) => (
                            <>
                              <div
                             style={{ backgroundColor: Data.backgroundcolor }}
                                className={`lyt_pdu_card  p-3 mb-4 ${activeCard == index + 1 ? "active" : ""
                                  }`}
                                onClick={() => handleCard(value, index + 1)}
                              >
                                <Row className="mx-auto">
                                  <Col
                                    xs={4}
                                    sm={4}
                                    className="px-0 d-flex align-items-start"
                                  >
                                    <div className="lyt_pdu_img_wrp">
                                    {console.log("value.ImageBuffer",value.ImageBuffer,typeof value?.ImageBuffer == "string",typeof value?.ImageBuffer)}
                                      <img
                                        src={(value.ImageBuffer == null || value.ImageBuffer == undefined) || Object.keys(value.ImageBuffer).length === 0  ? value.ImageName ? `${config?.Back_URL}/Events/${Data.curraddress}/jorney/${value?.ImageName}` : nftImg : typeof value?.ImageBuffer == "string" ? `${config?.Back_URL}/${value.ImageBuffer}` : URL?.createObjectURL(value?.ImageBuffer)}
                                        alt="title"
                                        className="img-fluid img_fit_cvr"
                                        onError={() => setImgSrc(defaultImage)}
                                      />
                                    </div>
                                  </Col>
                                  {/* start of web view */}
                                  <Col xs={7} sm={7} className="d-none d-sm-block">
                                    <div className="d-flex flex-column">
                                      <div>
                                        <p className="lyt_pdu_crd_subtle mb-0">
                                          {value.Journey}
                                        </p>
                                        <p className="lyt_pdu_crd_tle mb-0">
                                          {value.Name}
                                        </p>
                                      </div>
                                      <div>
                                        <Row className="mx-auto mt-2">
                                          {/* <Col xs={4} className="pl-0  lyt_pdu_star_wrp">
                                            <div className="d-flex align-items-center ">
                                              <img
                                                src={star}
                                                alt="start"
                                                className="img-fluid"
                                              />
                                              <p className="mb-0 ml-1 lyt_pdu_crd_rat">
                                                4.9
                                              </p>
                                            </div>
                                          </Col> */}

                                          <Col xs={12} className="pr-0 pl-0 lyt_pdu_tkn_dtls ">
                                            <p>
                                              Total Tokens Obtained = {value.tokenNumber}{" "}
                                              / 24
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col
                                    xs={1}
                                    className="px-0 d-none d-sm-flex align-items-sm-center justify-content-sm-end"
                                  >
                                    <div>
                                      <BsThreeDotsVertical className="lyt_pdu_dot_icn" />
                                    </div>
                                  </Col>
                                  {/* end of web view */}

                                  {/* start of mobile view */}
                                  <Col
                                    xs={8}
                                    className="d-flex d-sm-none align-items-center pr-0 "
                                  >
                                    <div>
                                      <Row className="d-flex align-items-center mx-auto">
                                        <Col xs={10} className="pl-0 ">
                                          <div className="d-flex flex-column">
                                            <p className="lyt_pdu_crd_subtle mb-0">
                                              {value.Journey}
                                            </p>
                                            <p className="lyt_pdu_crd_tle mb-0">
                                              {value.Name}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col
                                          xs={2}
                                          className="px-0 d-flex justify-content-end"
                                        >
                                          <div>
                                            <BsThreeDotsVertical className="lyt_pdu_dot_icn" />
                                          </div>
                                        </Col>
                                      </Row>
                                      <Row className="mx-auto mt-2">
                                        {/* <Col xs={4} className="pl-0  lyt_pdu_star_wrp">
                                          <div className="d-flex align-items-center ">
                                            <img
                                              src={star}
                                              alt="start"
                                              className="img-fluid"
                                            />
                                            <p className="mb-0 ml-1 lyt_pdu_crd_rat">
                                              4.9
                                            </p>
                                          </div>
                                        </Col> */}

                                        <Col xs={12} className="pr-0 pl-0 lyt_pdu_tkn_dtls ">
                                          <p>Total Tokens Obtained = 16 / 24</p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              {console.log(
                                "index",
                                index,
                                activeCard,
                                cardTitle,
                                cardSubtitle,
                                journeyNfts,
                                journeyNfts.Name,
                                journeyNfts.Description,
                                activeCard === index + 1
                              )}
                              {activeCard === index + 1 && (
                                <div className="pdu_content_wraper p-4 d-lg-none position-relative mb-4" style={{ backgroundColor: Data.backgroundcolor}}>
                                  <div className="position-absolute lyt_pdu_crd_cls_icn">
                                    <IoMdClose
                                      style={{
                                        color: "white",
                                        fontSize: "20px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => handlePduCardClose(activeCard)}
                                    />
                                  </div>
                                  <h2 className="lyt_h3_two">{journeyNfts.Name}</h2>
                                  <p className="lyt_pdu_rgt_subtle">{journeyNfts.Description}</p>
                                </div>
                              )}
                              {/* end of mobile view */}
                            </>
                          ))}
                        </Col>
                        <Col lg={6} xl={7} className="col-xxl-8 pl-xl-5  ">
                          <div className="pdu_content_wraper p-4 d-none d-lg-block mb-5" style={{ backgroundColor: Data.backgroundcolor}}>
                            <h2 className="lyt_h3_two">{journeyNfts?.Name}</h2>
                            <p className="lyt_pdu_rgt_subtle">{journeyNfts?.Description}</p>
                          </div>

                          {/* start of web  tabs */}
                          <div className="d-none d-lg-block lyt_cust_tabs">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="first"
                            >
                              <Row className="cust_lyt_pdu_pnts">
                                {/* <Col xs={12} className="d-flex justify-content-center">
                                  <Nav
                                    variant="pills"
                                    className="d-flex align-items-center"
                                  >
                                    <Nav.Item style={{ paddingRight: "11px" }}>
                                      Total
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="first">2023</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="second">2024</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="third">2025</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="four">2026</Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col> */}
                                {console.log("dfvsdfvsdf", journeyNfts, Data)}
                                <Col xs={12}>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                      <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                                        {journeyNfts?.TreasureNft?.length} Tokens | {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points
                                      </h5>
                                      <Row
                                        className="lyt_pnts_crds"
                                      // data-aos="fade-up"
                                      // data-aos-duration="3000"
                                      >
                                        {journeyNfts && journeyNfts?.TreasureNft
                                          ?.length > 0 ? journeyNfts?.TreasureNft
                                            .map((value) => (
                                              console.log("vsdfvsdsfvsdf", value),
                                              <Col
                                                lg={12}
                                                xl={6}
                                                className="col-xxl-4 col-xxxl-3"
                                              >
                                                <div
                                                style={{ backgroundColor: Data.backgroundcolor }}
                                                  class="card sub_domaincard height_subdomaincard"
                                                  id="1"
                                                >
                                                  <div className="lyt_pnts_crd_img_wrp">

                                                    {value?.image != "" ?
                                                      <>
                                                        {value?.image != "" &&
                                                          (value?.image.split(".").pop() == "mp4" ? (
                                                            <video
                                                              id="my-video"
                                                              class="img_fit_cvr lyt_pnts_crd_img"
                                                              autoPlay
                                                              playsInline
                                                              loop
                                                              muted
                                                              preload="auto"
                                                              controls disablePictureInPicture controlsList="nodownload"
                                                            >
                                                              <source src={`${config?.Back_URL}/nftImg/${value?.tokenCreator}/${value?.image}`} type="video/mp4" />
                                                            </video>
                                                          ) : (
                                                            <img src={`${config?.Back_URL}/nftImg/${value?.tokenCreator}/${value?.image}`} alt="Collections" className="img_fit_cvr lyt_pnts_crd_img" />
                                                          ))}

                                                      </>
                                                      :
                                                      <>
                                                        <img
                                                          src={require("../assets/images/faces/image2.png")}
                                                          className="img_fit_cvr lyt_pnts_crd_img"
                                                          alt=""
                                                        />

                                                      </>
                                                    }
                                                  </div>

                                                  <div class="card-body">
                                                    <div className="subd_cardwhole">
                                                      <div>
                                                        <h6 className="lyt_pdu_h6">
                                                          {value?.tokenName.slice(0, 15).concat("...")}
                                                        </h6>
                                                      </div>

                                                      <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                        <p className="mb-0">{journeyNfts?.NftPoint}</p>
                                                      </div>
                                                    </div>

                                                    <button className="lyt_pnts_crd_btn">
                                                      <p className="mb-0">Locked</p>
                                                    </button>
                                                  </div>
                                                </div>
                                              </Col>))
                                          :
                                          <Col
                                            lg={12}
                                            xl={6}
                                            className="col-xxl-4 col-xxxl-3"
                                          >
                                            <div
                                            style={{ backgroundColor: Data.backgroundcolor }}
                                              class="card sub_domaincard height_subdomaincard"
                                              id="1"
                                            >
                                              <div className="lyt_pnts_crd_img_wrp">
                                                <img
                                                  src={require("../assets/images/faces/image2.png")}
                                                  className="img_fit_cvr lyt_pnts_crd_img"
                                                  alt=""
                                                />
                                              </div>

                                              <div class="card-body">
                                                <div className="subd_cardwhole">
                                                  <div>
                                                    <h6 className="lyt_pdu_h6">
                                                      Task Name
                                                    </h6>
                                                  </div>

                                                  <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                    <p className="mb-0">26</p>
                                                  </div>
                                                </div>

                                                <button className="lyt_pnts_crd_btn">
                                                  <p className="mb-0">Locked</p>
                                                </button>
                                              </div>
                                            </div>
                                          </Col>
                                        }
                                      </Row>
                                    </Tab.Pane>
                                    {/* <Tab.Pane eventKey="second">
                                      <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                                        16 Tokens | 320 PDU Points
                                      </h5>
                                      <Row className="lyt_pnts_crds ">
                                        {PDUpoints2.map((value) => (
                                          <Col
                                            lg={12}
                                            xl={6}
                                            className="col-xxl-4 col-xxxl-3"
                                          >
                                            <div
                                              class="card sub_domaincard height_subdomaincard"
                                              id="1"
                                            >
                                              <div className="lyt_pnts_crd_img_wrp">
                                                <img
                                                  src={require("../assets/images/faces/image2.png")}
                                                  className="img_fit_cvr lyt_pnts_crd_img"
                                                  alt=""
                                                />
                                              </div>

                                              <div class="card-body">
                                                <div className="subd_cardwhole">
                                                  <div>
                                                    <h6 className="lyt_pdu_h6">
                                                      Task Name
                                                    </h6>
                                                  </div>

                                                  <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                    <p className="mb-0">26</p>
                                                  </div>
                                                </div>

                                                <button className="lyt_pnts_crd_btn">
                                                  <p className="mb-0">Locked</p>
                                                </button>
                                              </div>
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                      <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                                        16 Tokens | 320 PDU Points
                                      </h5>
                                      <Row className="lyt_pnts_crds ">
                                        {PDUpoints2.map((value) => (
                                          <Col
                                            lg={12}
                                            xl={6}
                                            className="col-xxl-4 col-xxxl-3"
                                          >
                                            <div
                                              class="card sub_domaincard height_subdomaincard"
                                              id="1"
                                            >
                                              <div className="lyt_pnts_crd_img_wrp">
                                                <img
                                                  src={require("../assets/images/faces/image2.png")}
                                                  className="img_fit_cvr lyt_pnts_crd_img"
                                                  alt=""
                                                />
                                              </div>

                                              <div class="card-body">
                                                <div className="subd_cardwhole">
                                                  <div>
                                                    <h6 className="lyt_pdu_h6">
                                                      Task Name
                                                    </h6>
                                                  </div>

                                                  <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                    <p className="mb-0">26</p>
                                                  </div>
                                                </div>

                                                <button className="lyt_pnts_crd_btn">
                                                  <p className="mb-0">Locked</p>
                                                </button>
                                              </div>
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="four">
                                      <h5 className="text-center mt-3 lyt_pdu_crd_hds mb-5">
                                        16 Tokens | 320 PDU Points
                                      </h5>
                                      <Row className="lyt_pnts_crds ">
                                        {PDUpoints2.map((value) => (
                                          <Col
                                            lg={12}
                                            xl={6}
                                            className="col-xxl-4 col-xxxl-3"
                                          >
                                            <div
                                              class="card sub_domaincard height_subdomaincard"
                                              id="1"
                                            >
                                              <div className="lyt_pnts_crd_img_wrp">
                                                <img
                                                  src={require("../assets/images/faces/image2.png")}
                                                  className="img_fit_cvr lyt_pnts_crd_img"
                                                  alt=""
                                                />
                                              </div>

                                              <div class="card-body">
                                                <div className="subd_cardwhole">
                                                  <div>
                                                    <h6 className="lyt_pdu_h6">
                                                      Task Name
                                                    </h6>
                                                  </div>

                                                  <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                    <p className="mb-0">26</p>
                                                  </div>
                                                </div>

                                                <button className="lyt_pnts_crd_btn">
                                                  <p className="mb-0">Locked</p>
                                                </button>
                                              </div>
                                            </div>
                                          </Col>
                                        ))}
                                      </Row>
                                    </Tab.Pane> */}
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>

                          {/* end of web */}

                          {/* start of mob */}
                          <div className="lyt_pnts_crds lyt_cust_tabs d-lg-none">
                            <Tab.Container
                              id="left-tabs-example"
                              defaultActiveKey="first"
                            >
                              {console.log("dfgbdfgbdfgbdfb", PDUpoints2)}

                              <Row>
                                {/* <Col xs={12} className="d-flex justify-content-center">
                                  <Nav
                                    variant="pills"
                                    className="d-flex align-items-center"
                                  >
                                    <Nav.Item style={{ paddingRight: "11px" }}>
                                      Total
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="first">2023</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="second">2024</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="third">2025</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                      <Nav.Link eventKey="four">2026</Nav.Link>
                                    </Nav.Item>
                                  </Nav>
                                </Col> */}
                                <Col xs={12}>
                                  <Tab.Content>
                                    <Tab.Pane eventKey="first">
                                      <h5 className="text-center lyt_pdu_crd_hds my-4">
                                        16 Tokens | 320 PDU Points
                                      </h5>
                                      <Swiper
                                        style={{ overflow: "hidden" }}
                                        id="going_toslide"
                                        pagination={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        autoplay={{
                                          delay: 1500,
                                          disableOnInteraction: false,
                                        }}
                                        slidesPerView={1}
                                        breakpoints={{
                                          320: {
                                            slidesPerView: 1.2,
                                          },
                                          576: {
                                            slidesPerView: 2,
                                          },
                                          768: {
                                            slidesPerView: 3,
                                          },
                                          992: {
                                            slidesPerView: 3,
                                          },
                                          1200: {
                                            slidesPerView: 4,
                                          },
                                        }}
                                        spaceBetween={20}
                                        className="mySwiper subd_cardswipe mt-5"
                                      >
                                        {PDUpoints2?.length > 0 ? (
                                          <>
                                            {PDUpoints2.map((value) => {
                                              return (
                                                <SwiperSlide>
                                                  <div
                                                  style={{ backgroundColor: Data.backgroundcolor }}
                                                    class="card sub_domaincard height_subdomaincard"
                                                    id="1"
                                                  >
                                                    <div className="lyt_pnts_crd_img_wrp">
                                                      <img
                                                        src={require("../assets/images/faces/image2.png")}
                                                        className="img_fit_cvr lyt_pnts_crd_img"
                                                        alt=""
                                                      />
                                                    </div>

                                                    <div class="card-body">
                                                      <div className="subd_cardwhole">
                                                        <div>
                                                          <h6>Task Name</h6>
                                                        </div>

                                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                          <p className="mb-0">26</p>
                                                        </div>
                                                      </div>

                                                      <button className="lyt_pnts_crd_btn">
                                                        <p className="mb-0">Locked</p>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </SwiperSlide>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            <ThreeDots
                                              height="70"
                                              width="70"
                                              radius="9"
                                              color="#FFC700"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          </>
                                        )}
                                      </Swiper>
                                    </Tab.Pane>

                                    {/* <Tab.Pane eventKey="second">
                                      <h5 className="text-center my-4 lyt_pdu_crd_hds ">
                                        16 Tokens | 320 PDU Points
                                      </h5>
                                      <Swiper
                                        style={{ overflow: "hidden" }}
                                        id="going_toslide"
                                        pagination={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        autoplay={{
                                          delay: 1500,
                                          disableOnInteraction: false,
                                        }}
                                        slidesPerView={1}
                                        breakpoints={{
                                          320: {
                                            slidesPerView: 1.2,
                                          },
                                          576: {
                                            slidesPerView: 2,
                                          },
                                          768: {
                                            slidesPerView: 3,
                                          },
                                          992: {
                                            slidesPerView: 3,
                                          },
                                          1200: {
                                            slidesPerView: 4,
                                          },
                                        }}
                                        spaceBetween={20}
                                        className="mySwiper subd_cardswipe mt-5"
                                      >
                                        {PDUpoints2?.length > 0 ? (
                                          <>
                                            {PDUpoints2.map((value) => {
                                              return (
                                                <SwiperSlide>
                                                  <div
                                                    class="card sub_domaincard height_subdomaincard"
                                                    id="1"
                                                  >
                                                    <div className="lyt_pnts_crd_img_wrp">
                                                      <img
                                                        src={require("../assets/images/faces/image2.png")}
                                                        className="img_fit_cvr lyt_pnts_crd_img"
                                                        alt=""
                                                      />
                                                    </div>

                                                    <div class="card-body">
                                                      <div className="subd_cardwhole">
                                                        <div>
                                                          <h6>Task Name</h6>
                                                        </div>

                                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                          <p className="mb-0">26</p>
                                                        </div>
                                                      </div>

                                                      <button className="lyt_pnts_crd_btn">
                                                        <p className="mb-0">Locked</p>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </SwiperSlide>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            <ThreeDots
                                              height="70"
                                              width="70"
                                              radius="9"
                                              color="#FFC700"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          </>
                                        )}
                                      </Swiper>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="third">
                                      <h5 className="text-center  lyt_pdu_crd_hds my-4">
                                        16 Tokens | 320 PDU Points
                                      </h5>
                                      <Swiper
                                        style={{ overflow: "hidden" }}
                                        id="going_toslide"
                                        pagination={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        autoplay={{
                                          delay: 1500,
                                          disableOnInteraction: false,
                                        }}
                                        slidesPerView={1}
                                        breakpoints={{
                                          320: {
                                            slidesPerView: 1.2,
                                          },
                                          576: {
                                            slidesPerView: 2,
                                          },
                                          768: {
                                            slidesPerView: 3,
                                          },
                                          992: {
                                            slidesPerView: 3,
                                          },
                                          1200: {
                                            slidesPerView: 4,
                                          },
                                        }}
                                        spaceBetween={20}
                                        className="mySwiper subd_cardswipe mt-5"
                                      >
                                        {PDUpoints2?.length > 0 ? (
                                          <>
                                            {PDUpoints2.map((value) => {
                                              return (
                                                <SwiperSlide>
                                                  <div
                                                    class="card sub_domaincard height_subdomaincard"
                                                    id="1"
                                                  >
                                                    <div className="lyt_pnts_crd_img_wrp">
                                                      <img
                                                        src={require("../assets/images/faces/image2.png")}
                                                        className="img_fit_cvr lyt_pnts_crd_img"
                                                        alt=""
                                                      />
                                                    </div>

                                                    <div class="card-body">
                                                      <div className="subd_cardwhole">
                                                        <div>
                                                          <h6>Task Name</h6>
                                                        </div>

                                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                          <p className="mb-0">26</p>
                                                        </div>
                                                      </div>

                                                      <button className="lyt_pnts_crd_btn">
                                                        <p className="mb-0">Locked</p>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </SwiperSlide>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            <ThreeDots
                                              height="70"
                                              width="70"
                                              radius="9"
                                              color="#FFC700"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          </>
                                        )}
                                      </Swiper>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="four">
                                      <h5 className="text-center my-4 lyt_pdu_crd_hds">
                                        16 Tokens | 320 PDU Points
                                      </h5>
                                      <Swiper
                                        style={{ overflow: "hidden" }}
                                        id="going_toslide"
                                        pagination={true}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        autoplay={{
                                          delay: 1500,
                                          disableOnInteraction: false,
                                        }}
                                        slidesPerView={1}
                                        breakpoints={{
                                          320: {
                                            slidesPerView: 1.2,
                                          },
                                          576: {
                                            slidesPerView: 2,
                                          },
                                          768: {
                                            slidesPerView: 3,
                                          },
                                          992: {
                                            slidesPerView: 3,
                                          },
                                          1200: {
                                            slidesPerView: 4,
                                          },
                                        }}
                                        spaceBetween={20}
                                        className="mySwiper subd_cardswipe mt-5"
                                      >
                                        {PDUpoints2?.length > 0 ? (
                                          <>
                                            {PDUpoints2.map((value) => {
                                              return (
                                                <SwiperSlide>
                                                  <div
                                                    class="card sub_domaincard height_subdomaincard"
                                                    id="1"
                                                  >
                                                    <div className="lyt_pnts_crd_img_wrp">
                                                      <img
                                                        src={require("../assets/images/faces/image2.png")}
                                                        className="img_fit_cvr lyt_pnts_crd_img"
                                                        alt=""
                                                      />
                                                    </div>

                                                    <div class="card-body">
                                                      <div className="subd_cardwhole">
                                                        <div>
                                                          <h6>Task Name</h6>
                                                        </div>

                                                        <div className="lyt_pnts_num_bg d-flex align-items-center justify-content-center">
                                                          <p className="mb-0">26</p>
                                                        </div>
                                                      </div>

                                                      <button className="lyt_pnts_crd_btn">
                                                        <p className="mb-0">Locked</p>
                                                      </button>
                                                    </div>
                                                  </div>
                                                </SwiperSlide>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>
                                            <ThreeDots
                                              height="70"
                                              width="70"
                                              radius="9"
                                              color="#FFC700"
                                              ariaLabel="three-dots-loading"
                                              wrapperStyle={{}}
                                              wrapperClassName=""
                                              visible={true}
                                            />
                                          </>
                                        )}
                                      </Swiper>
                                    </Tab.Pane> */}
                                  </Tab.Content>
                                </Col>
                              </Row>
                            </Tab.Container>
                          </div>

                          {/* end of mob */}
                        </Col>
                      </Row>
                    </div>
                  </div>

                  {/* end of PDU Points */}

                  {/* start of top achiever */}

                  <div className="my-4 my-lg-5">
                    {Data?.TopWinnerTittle == "" ||
                      Data?.TopWinnerTittle == null ||
                      Data?.TopWinnerTittle == undefined ? (
                      <h1 className=" text-center lyt_ach_hds">Top Achiever This Week</h1>
                    ) : (
                      <h4 className=" text-center lyt_ach_hds">
                        {Data?.TopWinnerTittle}{" "}
                      </h4>
                    )}
                    {/* start of mobile top achiever card  */}
                    <div
                      className="d-md-none"
                    // data-aos="fade-up"
                    // data-aos-duration="3000"
                    >
                      <Swiper
                        style={{ overflow: "hidden" }}
                        loop={false}
                        pagination={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        autoplay={{
                          delay: 1500,
                          disableOnInteraction: false,
                        }}
                        slidesPerView={1}
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                          },
                          576: {
                            slidesPerView: 1,
                          },
                        }}
                        spaceBetween={20}
                        className="mySwiper subd_lengthswipe my-4"
                      >
                        <>
                          <SwiperSlide>
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>{" "}
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>{" "}
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </SwiperSlide>
                          <SwiperSlide>
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>{" "}
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>{" "}
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  xl={2}
                                  className="px-0 d-flex align-items-center"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  xl={9}
                                  className="d-flex align-items-center"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                    <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  className="px-0 d-flex align-items-center justify-content-end"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </SwiperSlide>
                        </>
                      </Swiper>
                    </div>


                    {/* end of mobile top achiever card  */}

                    {/* start of web top achiever card  */}

                    <div className="d-none d-md-block my-4 my-lg-5">
                      <Row >
                        {topAchieverData.map((value) => (
                          <Col md={6} xl={6} className="col-xxl-4">
                            <div className="lyt_ach_card p-3 mb-4" style={{ backgroundColor: Data.backgroundcolor }}>
                              <Row className="mx-auto">
                                <Col
                                  xs={3}
                                  sm={2}
                                  md={3}
                                  xl={3}
                                  className="px-0 d-flex align-items-center col-xxl-2 col-xxxl-3"
                                >
                                  <div className="lyt_ach_crd_img_wrp">
                                    <img
                                      src={nftImg}
                                      alt=""
                                      className="img_fit_cvr lyt_ach_crd_img"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  xs={8}
                                  sm={8}
                                  md={8}
                                  xl={8}
                                  className="d-flex align-items-center col-xxl-9 col-xxxl-8"
                                >
                                  <div className="px-xl-2">
                                    <p className="lyt_ach_crd_p_one mb-0">@drchinp</p>
                                       <p className="lyt_ach_crd_p_two mb-0">
                                    {Data?.curraddress?.slice(0,15)}
                                    </p>
                                    <p className="lyt_ach_crd_p_three mb-0">
                                      {/* 9900 PDU Points | 560 Tokens */}
                                      {journeyNfts?.NftPoint > 0 && journeyNfts?.TreasureNft?.length > 0 ? Number(journeyNfts?.NftPoint * journeyNfts?.TreasureNft?.length) + " " : 0}
                                        {" " + Data?.PDUPoints ? Data?.PDUPoints : "PDU"} Points | {journeyNfts?.TreasureNft?.length} Tokens 
                                    </p>
                                  </div>
                                </Col>
                                <Col
                                  xs={1}
                                  sm={2}
                                  md={1}
                                  xl={1}
                                  className="px-0 d-flex align-items-center justify-content-end col-xxl-1 col-xxxl-1"
                                >
                                  <div>
                                    {/* <BsThreeDots className="lyt_ach_dot_icn" /> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>

                    {/* end of web top achiever card  */}
                  </div>
                  {/* end of top achiever */}

                  {/* start of footer */}
                  <SubDomainFooter />
                  {/* end of footer */}
                </Container>
              {/* </section> */}
              </div>
           
            </>

          }
        </>
      }
    </>
  )
}

export default NewSubDomain