import Web3 from "web3"

export const PurchaseCalculation = (data) => {
    var MidPrice = (data.Quantity * (data.Price * 1000000))

    var PriceWithoutSerfee = (Web3.utils.toWei(String(MidPrice / 1e6)))
    var SerFee = (MidPrice / 100000000) * ((data.Serfee.Buyer) * 1000000)
    var TotPrice = MidPrice + SerFee
    var PriceWithSerfee = Web3.utils.toWei(String(Number((Number(TotPrice)) / 1000000)))
    var TokenDecimal = 18 - Number(data.Decimal)
    var ApprovePriceWithSerfee = ((PriceWithSerfee) / 10 ** TokenDecimal)
    return { Youwillpay: (TotPrice / 1e6), PriceWithSerfee: PriceWithSerfee, ApprovePriceWithSerfee: ApprovePriceWithSerfee, PriceWithoutSerfee: PriceWithoutSerfee, Display: Web3.utils.fromWei(PriceWithSerfee) }
}

export const YouwillGetCalculation = (data) => {
    var MidPrice = (data.Quantity * (data.Price * 1000000))
    var PriceWithoutSerfee = (MidPrice / 1e6)
    var SerFee = (MidPrice / 100000000) * ((data.Serfee.Seller) * 1000000)
    var TotPrice = MidPrice - SerFee
    var PriceWithSerfee = (Number((Number(TotPrice)) / 1000000))
    var ApprovePriceWithSerfee = PriceWithSerfee
    return { Youwillpay: (TotPrice / 1e6), PriceWithSerfee: PriceWithSerfee, ApprovePriceWithSerfee: ApprovePriceWithSerfee, PriceWithoutSerfee: Web3.utils.toWei(String(PriceWithoutSerfee)) }
}

export const CharityAndRoyaltyCalculation = (tokDetails, NFT) => {
    var RoyaltyAndCharity = null
    
    if (NFT.Charity && NFT.SaleStatus == "Create") {
        var AmountShare = tokDetails.CharityDetails.map((item, index) => { return tokDetails.CharityDetails[index]["SplitAmount"] = (Number(NFT.tokenPrice) / 100) * Number(item.Share) })
        var CreatorSplitAmt = AmountShare.reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
        var Creatorshares = tokDetails.CharityDetails.map((item, index) => { return item.Share }).reduce((partialSum, a) => Number(partialSum) + Number(a), 0)

        var CreatorPercent = 100 - Number(Creatorshares)

        var CreatorShare = { Address: tokDetails.tokenOwner, Royality: tokDetails.tokenRoyality, SplitAmount: NFT.tokenPrice - CreatorSplitAmt, RoyaltyAmount: 0, Share: Creatorshares }
        tokDetails.CharityDetails.push(CreatorShare)
       
    } else if (NFT.Charity && NFT.SaleStatus == "Resale") {
        var Royalitiessplit = tokDetails.CharityDetails.map((item, index) => { return tokDetails.CharityDetails[index]["RoyaltyAmount"] = (Number(NFT.tokenPrice) / 100) * Number(item.Royality) })
        var NFTCreatorRayaltyShare = (Number(NFT.tokenPrice) / 100) * Number(tokDetails.tokenRoyality)
        var CreatorSplitAmt = Royalitiessplit.reduce((partialSum, a) => Number(partialSum) + Number(a), 0)
        var FinalSplit = Number(NFT.tokenPrice) - Number(CreatorSplitAmt) + NFTCreatorRayaltyShare

        var CreatorShare = { Address: tokDetails.tokenOwner, Royality: tokDetails.tokenRoyality, RoyaltyAmount: NFTCreatorRayaltyShare, SplitAmount: FinalSplit }
    
        tokDetails.CharityDetails.push(CreatorShare)
        tokDetails["RoyaltyAndCharity"] = tokDetails.CharityDetails
    } else if (!NFT.Charity && NFT.SaleStatus == "Resale") {
        var Array = []
        var NFTCreatorRayaltyShare = (Number(NFT.tokenPrice) / 100) * Number(tokDetails.tokenRoyality)
        var CreatorSplitAmt = NFT.tokenPrice - NFTCreatorRayaltyShare
        
        var CreatorShare = { Address: tokDetails.tokenOwner, Royality: tokDetails.tokenRoyality, RoyaltyAmount: NFTCreatorRayaltyShare, SplitAmount: CreatorSplitAmt }
        
        Array.push(CreatorShare)
        tokDetails.CharityDetails.push(CreatorShare)
        tokDetails["RoyaltyAndCharity"] = CreatorShare

    } else if (!NFT.Charity && NFT.Fiat && NFT.SaleStatus == "Resale") {
        var Array = []
        var NFTCreatorRayaltyShare = (Number(NFT.tokenPrice) / 100) * Number(tokDetails.tokenRoyality)
       
        var CreatorSplitAmt = NFT.tokenPrice - NFTCreatorRayaltyShare
        var CreatorShare = { Address: tokDetails.tokenOwner, Royality: tokDetails.tokenRoyality, RoyaltyAmount: NFTCreatorRayaltyShare, SplitAmount: CreatorSplitAmt }
       
        Array.push(CreatorShare)
        tokDetails.CharityDetails.push(CreatorShare)
        tokDetails["RoyaltyAndCharity"] = CreatorShare
    }
    else if (!NFT.Charity && NFT.Fiat && NFT.SaleStatus !== "Resale") {
        var Array = []
        
        var CreatorSplitAmt = NFT.tokenPrice
        console.log("CreatorSplitAmt", CreatorSplitAmt);

        var CreatorShare = { Address: tokDetails.tokenCreator, Royality: "0", RoyaltyAmount: "0", SplitAmount: CreatorSplitAmt }
        console.log("CreatorShare", CreatorShare);
        Array.push(CreatorShare)
        tokDetails.CharityDetails.push(CreatorShare)
        tokDetails["RoyaltyAndCharity"] = CreatorShare
    }
    return tokDetails.CharityDetails
}